import { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'

import { EmployeeRoutes } from '../../../../routing/routes'
import { AnalyticsProvider } from '../../../analytics/providers/AnalyticsProvider'
import { useAuthentication } from '../../../authentication/hooks/useAuthentication'
import { useSplashScreen } from '../../../authentication/hooks/useSplashScreen'
import { FullPageLoader } from '../../../components/general/FullPageLoader'
import { onboardingOriginAtom } from '../../../core/recoil/atoms'
import { TokenFlow } from '../components/token-flow/TokenFlow'

export const TokenFlowScreen = () => {
  // -- Hooks --
  const { isLoading, isAuthenticated } = useAuthentication()
  const setOnboardingIsRedirected = useSetRecoilState(onboardingOriginAtom)
  const { hideSplashScreen, translationsAreLoaded } = useSplashScreen()

  // -- Effects --
  useEffect(() => {
    if (!isLoading && translationsAreLoaded) {
      hideSplashScreen()
    }
  }, [isLoading, hideSplashScreen, translationsAreLoaded])

  // -- Render --
  if (isLoading) {
    return <FullPageLoader />
  }

  if (isAuthenticated) {
    setOnboardingIsRedirected(true)
    return <Navigate to={EmployeeRoutes.Onboarding} replace />
  }

  return (
    <AnalyticsProvider>
      <TokenFlow />
    </AnalyticsProvider>
  )
}
