import axios from 'axios'
import { useTranslation } from 'react-i18next'

import { useAuthentication } from '../../../authentication/hooks/useAuthentication'
import { useMember } from '../../../member/hooks/useMember'

export const useAuthMemberFetcher = () => {
  const { currentMember } = useMember()
  const { i18n } = useTranslation()
  const { getAccessTokenSilently, logout } = useAuthentication()

  return async (url: string) => {
    let accessToken: string
    try {
      accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: import.meta.env.VITE_AUTH0_AUDIENCE,
        },
      })
    } catch (error) {
      // If the user is not authenticated, log them out
      logout()
      throw error
    }

    const response = await axios.get(url, {
      params: {
        memberId: currentMember.id,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'x-user-lang': i18n.language,
        'x-selected-company': currentMember.companyId,
        'x-hrzn-skip-warning': 'Robbe is cool',
      },
    })

    return response.data
  }
}
