import {
  addMonths,
  addWeeks,
  endOfMonth,
  endOfYear,
  format,
  startOfMonth,
  startOfYear,
  subMonths,
  subYears,
  subDays,
  startOfDay,
  endOfDay,
} from 'date-fns'
import { useTranslation } from 'react-i18next'

import { useGetDateFnsLocale } from '../../core/hooks/useGetDateFnsLocale'

export type Shortcut = {
  label: string
  key: string
  onClick: () => {
    start: Date | null
    end: Date | null
  }
}

const MONTH_SHORTCUTS = {
  THIS_YEAR: 'THIS_YEAR',
  LAST_YEAR: 'LAST_YEAR',
  ALL: 'ALL',
}

const SHORT_TERM_SHORTCUTS = {
  TODAY: 'TODAY',
  NEXT_WEEK: 'NEXT_WEEK',
  NEXT_MONTH: 'NEXT_MONTH',
}

const RELATIVE_SHORTCUTS = {
  LAST_30_DAYS: 'LAST_30_DAYS',
  LAST_90_DAYS: 'LAST_90_DAYS',
  LAST_YEAR: 'LAST_YEAR',
  ALL: 'ALL',
}

const AMOUNT_OF_MONTHS_TO_INCLUDE = 5

export function useDatepicker() {
  const { t } = useTranslation()

  const dateFnsLocale = useGetDateFnsLocale()

  const monthsForShortcut = Array.from(
    { length: AMOUNT_OF_MONTHS_TO_INCLUDE },
    (_, index) => ({
      label:
        index === 0
          ? t('components.date-input.shortcuts.this-month')
          : index === 1
          ? t('components.date-input.shortcuts.last-month')
          : format(subMonths(new Date(), index), 'MMMM yyyy', dateFnsLocale),
      key: 'MONTH_' + index,
      onClick: () => {
        const today = new Date()

        return {
          start: startOfMonth(subMonths(today, index)),
          end: endOfMonth(subMonths(today, index)),
        }
      },
    })
  )

  const monthBasedShortcuts: Shortcut[] = [
    ...monthsForShortcut,
    {
      label: t('components.date-input.shortcuts.this-year'),
      key: MONTH_SHORTCUTS.THIS_YEAR,
      onClick: () => {
        const today = new Date()

        return {
          start: startOfYear(today),
          end: endOfYear(today),
        }
      },
    },
    {
      label: t('components.date-input.shortcuts.last-year'),
      key: MONTH_SHORTCUTS.LAST_YEAR,
      onClick: () => {
        const today = new Date()

        return {
          start: startOfYear(subYears(today, 1)),
          end: endOfYear(subYears(today, 1)),
        }
      },
    },
    {
      label: t('components.date-input.shortcuts.all'),
      key: MONTH_SHORTCUTS.ALL,
      onClick: () => {
        return {
          start: null,
          end: null,
        }
      },
    },
  ]

  const shortTermShortcuts: Shortcut[] = [
    {
      label: t('components.date-input.shortcuts.today'),
      key: SHORT_TERM_SHORTCUTS.TODAY,
      onClick: () => {
        return {
          start: new Date(),
          end: null,
        }
      },
    },
    {
      label: t('components.date-input.shortcuts.next-week'),
      key: SHORT_TERM_SHORTCUTS.NEXT_WEEK,
      onClick: () => {
        return {
          start: addWeeks(new Date(), 1),
          end: null,
        }
      },
    },
    {
      label: t('components.date-input.shortcuts.next-month'),
      key: SHORT_TERM_SHORTCUTS.NEXT_MONTH,
      onClick: () => {
        return {
          start: addMonths(new Date(), 1),
          end: null,
        }
      },
    },
  ]

  const relativeShortcuts: Shortcut[] = [
    {
      label: t('components.date-input.shortcuts.past-30-days'),
      key: RELATIVE_SHORTCUTS.LAST_30_DAYS,
      onClick: () => {
        const today = new Date()
        const thirtyDaysAgo = subDays(today, 30)

        return {
          start: startOfDay(thirtyDaysAgo),
          end: endOfDay(today),
        }
      },
    },
    {
      label: t('components.date-input.shortcuts.past-90-days'),
      key: RELATIVE_SHORTCUTS.LAST_90_DAYS,
      onClick: () => {
        const today = new Date()
        const ninetyDaysAgo = subDays(today, 90)

        return {
          start: startOfDay(ninetyDaysAgo),
          end: endOfDay(today),
        }
      },
    },
    {
      label: t('components.date-input.shortcuts.past-year'),
      key: RELATIVE_SHORTCUTS.LAST_YEAR,
      onClick: () => {
        const today = new Date()
        const oneYearAgo = subYears(today, 1)

        return {
          start: startOfDay(oneYearAgo),
          end: endOfDay(today),
        }
      },
    },
    {
      label: t('components.date-input.shortcuts.all'),
      key: RELATIVE_SHORTCUTS.ALL,
      onClick: () => {
        return {
          start: null,
          end: null,
        }
      },
    },
  ]

  return {
    monthBasedShortcuts,
    shortTermShortcuts,
    relativeShortcuts,
  }
}
