import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { BodySmallRegular } from '../../../components/typography'
import { SelectedWheelSlide, Wheel } from '../../../components/Wheel'

type ChargingSpeedPickerProps = {
  value: number
  onChange: (newSpeed: number) => void
  maxSpeed?: number
}

export function ChargingSpeedPicker({
  value,
  onChange,
  maxSpeed = 32,
}: ChargingSpeedPickerProps) {
  const { t } = useTranslation()

  // Default min speed is 6A
  const minSpeed = 6
  // Ensure maxSpeed is valid
  const effectiveMaxSpeed = Math.max(minSpeed, maxSpeed)

  // Generate all possible speed values
  const speedValues = Array.from(
    { length: effectiveMaxSpeed - minSpeed + 1 },
    (_, index) => minSpeed + index
  )

  // Find the index of the current value in the speed array
  const currentIndex = speedValues.indexOf(value)

  return (
    <StContainer>
      <StSelectedWheelSlide>
        <StBodySmallRegular>
          {t('employee.profile.charger.speed.unit')}
        </StBodySmallRegular>
      </StSelectedWheelSlide>
      <StWheel
        length={speedValues.length}
        width={140}
        perspective="center"
        formatValue={(index) => `${speedValues[index]}`}
        initialIndex={currentIndex >= 0 ? currentIndex : 0}
        onChange={(index) => onChange(speedValues[index])}
      />
    </StContainer>
  )
}

const StContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`

const StSelectedWheelSlide = styled(SelectedWheelSlide)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const StWheel = styled(Wheel)`
  flex: 1;
  height: 200px;
`

const StBodySmallRegular = styled(BodySmallRegular)`
  margin-right: ${({ theme }) => theme.UI.SpacingPx.Space2};
`
