import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import { match } from 'ts-pattern'
import { useMediaQuery } from 'usehooks-ts'
import { minutesToHoursAndMinutes } from 'utils/src'

import { breakpoints } from '../../../../theme/layout/breakpoints'
import { ButtonTertiary } from '../../../components/button/ButtonTertiary'
import { PriceBreakdown } from '../../../components/charging-session/PriceBreakdown'
import {
  BodySmallRegular,
  BodySmallRegularCss,
  BodySmallSemiBold,
  H5,
} from '../../../components/typography'
import { useGetDateFnsLocale } from '../../../core/hooks/useGetDateFnsLocale'
import { formatDecimals } from '../../../core/utils/number'
import { useMember } from '../../../member/hooks/useMember'
import { valueToDate } from '../../../translations/utils/date'
import { useUser } from '../../../user/hooks/useUser'

import { getHomeChargingSessionType } from './homeChargingSession'
import { ReimbursementStatusChip } from './ReimbursementStatusChip'

import type { ChargingSessionWithRelations } from 'types'

type ChargingSessionInfoHcpProps = {
  chargingSession: ChargingSessionWithRelations
}

export const ChargingSessionInfoHcp = ({
  chargingSession,
}: ChargingSessionInfoHcpProps) => {
  const dateFnsLocale = useGetDateFnsLocale()
  const { t } = useTranslation()
  const { currentMember } = useMember()
  const { user } = useUser()
  const navigate = useNavigate()
  const isDesktop = useMediaQuery(breakpoints.desktop)

  const startDate = valueToDate(chargingSession.start)
  const startHour = format(startDate, 'HH:mm', dateFnsLocale)
  const endDate = valueToDate(chargingSession.end)
  const endHour = format(endDate, 'HH:mm', dateFnsLocale)

  const duration = minutesToHoursAndMinutes(chargingSession.duration)

  const fullStartDate = format(startDate, 'dd MMMM yyyy', dateFnsLocale)

  const userName = match(getHomeChargingSessionType(chargingSession))
    .with('Me', () => `${user.firstName} ${user.lastName}`)
    .with('Guest', () => t('employee.chargingSessions.guest'))
    .with('Other', () => t('employee.chargingSessions.other'))
    .exhaustive()

  const handleRefundsClick = useCallback(() => {
    navigate('/refunds', {
      state: {
        backTo: location.pathname,
      },
    })
  }, [navigate])

  return (
    <>
      {isDesktop ? (
        <StChargingSessionTitle>{fullStartDate}</StChargingSessionTitle>
      ) : null}
      <StChargingSessionInfo>
        <StChargingSessionRow>
          <BodySmallSemiBold>{userName}</BodySmallSemiBold>
          <ReimbursementStatusChip
            chargingSession={chargingSession}
            member={currentMember}
          />
        </StChargingSessionRow>
        <StChargingSessionRow>
          <StData>
            {startHour} - {endHour}
          </StData>
          <StDuration>
            <StIcon icon={['fasr', 'clock']} />
            {duration.hours > 0 &&
              `${t('employee.chargingSessions.detail.duration-hours', {
                hours: duration.hours,
              })} `}
            {t('employee.chargingSessions.detail.duration-minutes', {
              minutes: formatDecimals(duration.minutes, 0),
            })}
          </StDuration>
        </StChargingSessionRow>

        <PriceBreakdown chargingSession={chargingSession} />
      </StChargingSessionInfo>
      <StChargingSessionAction>
        <StToRefundsButton
          icon={['fasr', 'arrow-right']}
          onClick={handleRefundsClick}
        >
          {t('employee.chargingSessions.detail.refunds-overview')}
        </StToRefundsButton>
      </StChargingSessionAction>
    </>
  )
}

const StChargingSessionTitle = styled(H5)`
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space8};
`

const StChargingSessionInfo = styled.div`
  ${BodySmallRegularCss}
  display: flex;
  flex-direction: column;
  gap: 6px;

  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space4};

  media ${breakpoints.desktop} {
    padding-top: 0;
  }
`

const StChargingSessionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StChargingSessionAction = styled.div`
  ${BodySmallRegularCss}
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space10};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StToRefundsButton = styled(ButtonTertiary)`
  justify-content: space-between;
`

const StData = styled(BodySmallRegular)`
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`

const StDuration = styled(BodySmallRegular)`
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`

const StIcon = styled(FontAwesomeIcon)`
  font-size: ${({ theme }) => theme.UI.SpacingPx.Space3};
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
  margin-right: ${({ theme }) => theme.UI.SpacingPx.Space1};
`
