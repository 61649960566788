import { useMember } from '../../member/hooks/useMember'

import type { AdminPermission } from '@prisma/client'

export const usePermissions = () => {
  const { currentMember, isCurrentMemberSuperAdmin } = useMember()

  const hasPermission = (permission: AdminPermission): boolean => {
    // SuperAdmin has all permissions
    if (isCurrentMemberSuperAdmin) {
      return true
    }

    // For regular members, check if their permissions list includes the requested permission
    return (
      Array.isArray(currentMember.permissions) &&
      currentMember.permissions.some((permissionEntry) =>
        permissionEntry.permissions.includes(permission)
      )
    )
  }

  return {
    hasPermission,
  }
}
