import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { styled } from 'styled-components'
import { formatDateRange } from 'utils/src'

import { replaceParametersInPath } from '../../../../routing/lib/replaceIdInPath'
import { EmployerRoutes } from '../../../../routing/routes'
import { EventType, useTracking } from '../../../analytics/hooks/useTracking'
import { useAuthMemberAxios } from '../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../api/urls'
import { ButtonSecondary } from '../../../components/button/ButtonSecondary'
import { FilterTypes } from '../../../components/filters/Filters'
import { StatisticCard } from '../../../components/general/StatisticCard'
import { ToolTipWrapper } from '../../../components/general/ToolTipWrapper'
import {
  BodyExtraExtraSmallRegular,
  BodyExtraSmallRegular,
  BodySmallRegular,
  H4,
} from '../../../components/typography'
import { useGetDateFnsLocale } from '../../../core/hooks/useGetDateFnsLocale'
import { downloadBlob } from '../../../core/lib/downloadBlob'
import { ColumnType, DataTable } from '../../../dataTable/components/DataTable'
import { useDataTable } from '../../../dataTable/hooks/useDataTable'
import { Filters } from '../../../employee/onboarding/components/Filters'
import { useUser } from '../../../user/hooks/useUser'
import { AverageCostBreakdown } from '../../employees/components/employeeDetail/AverageCostBreakdown'
import { formatCurrency, formatPowerConsumption } from '../../utils/statistics'
import { type ChargingSessionStatistics } from '../types/types'

import { ChargingSpeedFractions } from './ChargingSpeedFractions'
import { CostPerTypeFractions } from './CostPerTypeFractions'
import { TotalCostBreakdown } from './TotalCostBreakdown'
import { StHeader, StStatistics } from './UsageDataTable'
import { UsageFractions } from './UsageFractions'
import { UsageStatisticsLoader } from './UsageStatisticsLoader'

import type { Filter } from '../../../components/filters/Filters'
import type { Column } from '../../../dataTable/components/DataTable'
import type { MemberForUsageReport } from 'types'

export const UsageTabContent = () => {
  const { t } = useTranslation()
  const { trackEvent } = useTracking()
  const dateFnsLocale = useGetDateFnsLocale()
  const [searchParameters] = useSearchParams()
  const { user } = useUser()

  const {
    initialLoading,
    tableData,
    navigationContainerBottom,
    statistics,
    filterValues,
    setFilterValues,
    clearFilters,
  } = useDataTable<ChargingSessionStatistics>()

  // State
  const [exportLoading, setExportLoading] = useState(false)

  // -- Vars --
  const columns = useMemo<Column<MemberForUsageReport>[]>(
    () => [
      {
        key: 'fullName',
        label: t('employer.usage.table.employee'),
        type: ColumnType.STRING,
        sortable: true,
        transformData: (member) => {
          return (
            <StEmployeeName>
              <BodySmallRegular>{member.fullName}</BodySmallRegular>
              <StEmployeeSubtitle>
                {member.hasCharger
                  ? t('employer.usage.table.homeCharger.yes')
                  : t('employer.usage.table.homeCharger.no')}
              </StEmployeeSubtitle>
            </StEmployeeName>
          )
        },
      },
      {
        key: 'totalChargingSessions',
        label: t('employer.usage.table.amountOfChargingSessions'),
        type: ColumnType.NUMBER,
        sortable: true,
      },
      {
        key: 'totalKwh',
        label: t('employer.usage.table.consumption'),
        type: ColumnType.NUMBER,
        sortable: true,
        transformData: (member) => {
          return formatPowerConsumption(member.totalKwh, 2, user.language)
        },
      },
      {
        key: 'costPerKwh',
        label: t('employer.usage.table.costPerKwh'),
        type: ColumnType.NUMBER,
        sortable: true,
        transformData: (member) => {
          return formatCurrency(member.costPerKwh, user.language)
        },
      },
      {
        key: 'totalCost',
        label: t('employer.usage.table.cost'),
        type: ColumnType.NUMBER,
        sortable: true,
        transformData: (member) => {
          return <TotalCostBreakdown member={member} />
        },
      },
    ],
    [t, user.language]
  )

  const filters = useMemo<Filter[]>(
    () => [
      {
        key: 'hasCharger',
        placeholder: t('employer.usage.table.filter.homeCharger.placeholder'),
        type: FilterTypes.Option,
        options: [
          {
            key: 'NULL',
            label: t('employer.usage.table.filter.homeCharger.all'),
          },
          {
            key: 'true',
            label: t('employer.usage.table.homeCharger.yes'),
          },
          {
            key: 'false',
            label: t('employer.usage.table.homeCharger.no'),
          },
        ],
      },
      {
        key: 'dateRange',
        type: FilterTypes.DateRange,
      },
    ],
    [t]
  )

  // -- Data --
  const [, exportSessions] = useAuthMemberAxios(
    {
      url: Urls.employerChargingSessionsExport,
      method: 'POST',
      params: {
        filters: {
          ...filterValues,
        },
      },
      responseType: 'blob',
    },
    { manual: true }
  )

  const [{ data: averages, loading: averagesLoading }, fetchAverages] =
    useAuthMemberAxios({
      url: Urls.employerChargingSessionsAverages,
      params: {
        filters: {
          ...filterValues,
        },
      },
    })

  const handleExportSessions = async () => {
    try {
      trackEvent(EventType.Click, 'export_overview')
      setExportLoading(true)
      const response = await exportSessions()

      // Save file
      downloadBlob(
        response.data,
        t('employer.chargingSessions.title').toLocaleLowerCase() +
          format(new Date(), '_dd_MM_yyyy-HH_mm'),
        '.xlsx'
      )
    } catch {
      toast.error(t('employer.chargingSessions.export.error'))
    }

    setExportLoading(false)
  }

  useEffect(() => {
    fetchAverages({
      params: {
        ...filterValues,
      },
    })
  }, [filterValues])

  return (
    <>
      <StHeader>
        <Filters
          key={'usage-data-table-filters'}
          filters={filters}
          filterValues={filterValues}
          withSearch
          searchPlaceholder={t(
            'employer.usage.table.filter.search.placeholder'
          )}
          onFiltersChange={(filterKey, value) =>
            setFilterValues((oldValues) => ({
              ...oldValues,
              [filterKey]: value,
            }))
          }
          onClearFilters={() => {
            clearFilters()
          }}
          loading={initialLoading && !tableData}
          stickyPosition={navigationContainerBottom}
        >
          <div>
            <ButtonSecondary
              icon={['fass', 'arrow-down-to-line']}
              iconAlignment="left"
              loading={exportLoading}
              onClick={handleExportSessions}
            >
              {t('employer.chargingSessions.export')}
            </ButtonSecondary>
          </div>
        </Filters>
      </StHeader>
      {statistics ? (
        <>
          {/* Statistics values */}
          <StStatisticsRow>
            <StStatistic>
              <StStatisticLabel>
                {t('employer.usage.statistics.totalCost')}
              </StStatisticLabel>
              <H4>
                {formatCurrency(statistics?.totalCost ?? 0, user.language)}
              </H4>
              <StStatisticLabel>
                {t('employer.usage.statistics.totalCost.subtitle')}
              </StStatisticLabel>
            </StStatistic>
            <ToolTipWrapper
              placement="right-start"
              offsetAmount={14}
              toolTip={
                <AverageCostBreakdown
                  loading={averagesLoading}
                  amountWithHcp={averages?.totalUsersWithHcp ?? 0}
                  amountWithoutHcp={averages?.totalUsersWithoutHcp ?? 0}
                  withHcpAverage={formatCurrency(
                    !averages?.totalUsageHomeChargers ||
                      averages.totalUsageHomeChargers === 0
                      ? 0
                      : (averages?.totalCostHomeChargers ?? 0) /
                          (averages?.totalUsageHomeChargers ?? 1),
                    user.language
                  )}
                  withoutHcpAverage={formatCurrency(
                    !averages?.totalUsageWithoutHomeChargers ||
                      averages.totalUsageWithoutHomeChargers === 0
                      ? 0
                      : (averages?.totalCostWithoutHomeChargers ?? 0) /
                          (averages?.totalUsageWithoutHomeChargers ?? 1),
                    user.language
                  )}
                />
              }
            >
              <StStatistic>
                <StStatisticLabel>
                  {t('employer.usage.statistics.averageCostPerKwh')}
                </StStatisticLabel>
                <StStatisticsValue>
                  <H4>
                    {formatCurrency(
                      (statistics?.totalCost ?? 0) /
                        (statistics?.totalUsage ?? 1),
                      user.language
                    )}
                  </H4>
                  <StInfoIcon>
                    <FontAwesomeIcon icon={['fass', 'info-circle']} />
                  </StInfoIcon>
                </StStatisticsValue>
                <StStatisticLabel>
                  {t('employer.usage.statistics.averageCostPerKwh.subtitle')}
                </StStatisticLabel>
              </StStatistic>
            </ToolTipWrapper>
          </StStatisticsRow>

          {/* Statistic fractions */}

          <StStatistics>
            <StatisticCard
              title={t('employer.usage.statistics.chargingBreakdown')}
              subTitle={
                filterValues.dateFrom && filterValues.dateTo
                  ? formatDateRange(
                      {
                        start: filterValues.dateFrom as Date,
                        end: filterValues.dateTo as Date,
                      },
                      dateFnsLocale
                    )
                  : undefined
              }
            >
              <UsageFractions statistics={statistics} />
            </StatisticCard>
            <StatisticCard
              title={t('employer.usage.statistics.chargingSpeed')}
              subTitle={
                filterValues.dateFrom && filterValues.dateTo
                  ? formatDateRange(
                      {
                        start: filterValues.dateFrom as Date,
                        end: filterValues.dateTo as Date,
                      },
                      dateFnsLocale
                    )
                  : undefined
              }
            >
              <ChargingSpeedFractions statistics={statistics} />
            </StatisticCard>
            <StatisticCard
              title={t('employer.usage.statistics.extraCosts')}
              subTitle={
                filterValues.dateFrom && filterValues.dateTo
                  ? formatDateRange(
                      {
                        start: filterValues.dateFrom as Date,
                        end: filterValues.dateTo as Date,
                      },
                      dateFnsLocale
                    )
                  : undefined
              }
            >
              <CostPerTypeFractions statistics={statistics} />
            </StatisticCard>
          </StStatistics>
        </>
      ) : initialLoading ? (
        <UsageStatisticsLoader />
      ) : undefined}
      <DataTable<MemberForUsageReport>
        columns={columns}
        hrefGenerator={(member) => {
          searchParameters.set('tab', 'usage')

          return (
            replaceParametersInPath(EmployerRoutes.EmployeeDetail, {
              blossomId: member.blossomId,
            }) +
            '?' +
            searchParameters.toString()
          )
        }}
        emptyFiltersTitle={t(
          'employer.chargingSessions.table.empty-filters-title'
        )}
        emptyTitle={t('employer.chargingSessions.table.empty-title')}
        emptyFiltersDescription={t(
          'employer.chargingSessions.table.empty-filters-description'
        )}
        emptyDescription={t(
          'employer.chargingSessions.table.empty-description'
        )}
      />
    </>
  )
}

const StStatisticsRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space10};
`

const StStatistic = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StStatisticLabel = styled(BodyExtraSmallRegular)`
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`

const StStatisticsValue = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StInfoIcon = styled.div`
  display: grid;
  place-items: center;
  margin-top: 4px;

  color: ${({ theme }) => theme.theme.colors['nonary-4']};
  background-color: transparent;
  border: none;
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space1};
  cursor: pointer;
`

const StEmployeeName = styled.div`
  display: flex;
  flex-direction: column;
`

const StEmployeeSubtitle = styled(BodyExtraExtraSmallRegular)`
  color: ${({ theme }) => theme.theme.colors['nonary-3']};
`
