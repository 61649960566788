import { Navigate, Outlet } from 'react-router-dom'

import { usePermissions } from '../../modules/employer/hooks/usePermissions'
import { EmployerRoutes } from '../routes'

import type { AdminPermission } from 'database'

type PermissionGuardProps = {
  permission: AdminPermission
}

export const PermissionGuard = ({ permission }: PermissionGuardProps) => {
  const { hasPermission } = usePermissions()

  if (!hasPermission(permission)) {
    return <Navigate to={EmployerRoutes.Root} replace />
  }

  return <Outlet />
}

PermissionGuard.displayName = 'PermissionGuard'
