import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { EmployeeRoutes } from '../../../routing/routes'
import { useSplashScreen } from '../../authentication/hooks/useSplashScreen'

import { DownTimeSvg } from './assets/DownTimeSvg'
import { FullScreenError } from './FullScreenError'

export const DownTimeScreen = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const { hideSplashScreen, translationsAreLoaded } = useSplashScreen()

  useEffect(() => {
    if (translationsAreLoaded) {
      hideSplashScreen()
    }
  }, [translationsAreLoaded, hideSplashScreen])

  // -- Handlers --
  const refresh = () => {
    location.replace(EmployeeRoutes.Root)
  }

  // -- Render --
  return (
    <FullScreenError
      withNavigation={!location.pathname.includes('embedded')}
      onClick={refresh}
      title={t('app-down-screen.title')}
      subTitle={t('app-down-screen.subtitle')}
      buttonText={t('app-down-screen.button')}
      svg={<DownTimeSvg />}
    />
  )
}
