import { Form, Formik } from 'formik'
import IBAN from 'iban'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import * as Yup from 'yup'

import { breakpoints } from '../../../../../theme/layout/breakpoints'
import { useAuthMemberAxios } from '../../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../../api/urls'
import {
  Input,
  StLabel,
  StLabelContainer,
} from '../../../../components/form/Input'
import { InfoPopup } from '../../../../components/info-popup/InfoPopup'
import {
  BodyExtraExtraSmallRegular,
  BodyExtraExtraSmallSemiBold,
} from '../../../../components/typography'
import { shallowEqual } from '../../../../core/lib/shallowEqual'
import { useIntercom } from '../../../../intercom/hooks/useIntercom'
import { FloatingContainerButton } from '../../components/FloatingContainerButton'
import { OnboardingStepTitle } from '../../components/OnboardingStepTitle'
import { StFaqButton, StTooltipText } from '../../components/SelectBoxes'
import { useOnboarding } from '../../hooks/useOnboarding'

export const BankDetailsStep = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const {
    handleNext,
    setLoading,
    values: onboardingValues,
    selfEmployedFlow,
  } = useOnboarding()
  const { showArticle } = useIntercom()

  // -- Data --
  const [, execute] = useAuthMemberAxios(
    {
      url: Urls.BankingData,
      method: 'POST',
    },
    {
      manual: true,
    }
  )

  //  -- Vars --
  const validatationSchema = Yup.object().shape({
    iban: Yup.string()
      .test({
        name: 'ibanCheck',
        message: t('onboarding.bank-details.iban.error'),
        test: (value) => (value ? IBAN.isValid(value) : true),
      })
      .required(t('onboarding.bank-details.iban.required')),
  })

  // -- Handlers --
  const handleSubmit = async (values: typeof onboardingValues.bankDetails) => {
    try {
      setLoading(true)

      if (!shallowEqual(values, onboardingValues.bankDetails)) {
        await execute({
          data: {
            iban: values.iban,
          },
        })
      }

      handleNext({
        ...onboardingValues,
        bankDetails: {
          iban: values.iban,
        },
      })
    } catch {
      setLoading(false)
      return console.error('Failed to save bank details')
    }
  }

  const handleIntercomFaq = (faqId: string) => {
    showArticle(Number.parseInt(faqId))
  }

  // -- Render --
  return (
    <StContainer>
      <OnboardingStepTitle>
        {t('onboarding.bank-details.title')}
      </OnboardingStepTitle>

      <Formik
        initialValues={onboardingValues.bankDetails}
        validationSchema={validatationSchema}
        validateOnMount
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
        }) => (
          <Form>
            <StFormContainer>
              <StLabelContainer>
                <StLabel $size="md" htmlFor="iban">
                  {t('onboarding.bank-details.iban')}
                </StLabel>
                {selfEmployedFlow && (
                  <InfoPopup
                    theme="light"
                    placement="bottom-start"
                    content={
                      <StTooltipText>
                        <BodyExtraExtraSmallSemiBold>
                          {t('onboarding.bank-details.iban.tooltip-title')}
                        </BodyExtraExtraSmallSemiBold>
                        <BodyExtraExtraSmallRegular>
                          {t('onboarding.bank-details.iban.tooltip-text')}
                        </BodyExtraExtraSmallRegular>
                        <StFaqButton
                          type="button"
                          onClick={(event) => {
                            event.stopPropagation()
                            handleIntercomFaq(
                              t(
                                'onboarding.bank-details.iban.tooltip-text-faq-id'
                              )
                            )
                          }}
                        >
                          {t('intercom.faq.title')}
                        </StFaqButton>
                      </StTooltipText>
                    }
                  />
                )}
              </StLabelContainer>
              <Input
                type="text"
                name="iban"
                value={values.iban}
                error={errors.iban}
                touched={touched.iban}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                placeholder={t('onboarding.bank-details.iban.placeholder')}
              />
            </StFormContainer>

            <FloatingContainerButton
              title={t('onboarding.bank-details.submit')}
              disabled={!values.iban}
            />
          </Form>
        )}
      </Formik>
    </StContainer>
  )
}

const StContainer = styled.div`
  padding-bottom: ${({ theme }) =>
    `calc(var(--inset-bottom, ${theme.UI.SpacingPx.Space6}) + var(--sticky-button-container-height))`};
`

const StFormContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${breakpoints.desktop} {
    margin: ${({ theme }) => theme.UI.SpacingPx.Space15} 0;
  }
`
