import { format } from 'date-fns'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import { match } from 'ts-pattern'
import {
  INVITE_REMINDER_MAX_DAYS,
  ONBOARDING_REMINDER_MAX_DAYS,
} from 'utils/src'

import { Banner, BannerType } from '../../../../components/general/Banner'
import { PersonalDataEdit } from '../../../../components/profile/PersonalDataEdit'
import { DetailQuoteApproval } from '../DetailQuoteApproval'
import { EmployeeContractCard } from '../EmployeeContractCard'
import { PairedDevicesCard } from '../PairedDevicesCard'
import { PracticalInfoCard } from '../PracticalInfoCard'
import { QuoteCard } from '../QuoteCard'

import type { PersonalDataEditValues } from '../../../../components/profile/PersonalDataEdit'
import type { AxiosPromise } from 'axios'
import type { User } from 'database'
import type { UserWithContractAndCards, UserWithMembers } from 'types'

type EmployeeDetailInformationProps = {
  employee: UserWithContractAndCards
  updateEmployee: (data: Partial<User>) => AxiosPromise<UserWithMembers>
  refetchEmployee: () => void
}

export const EmployeeDetailInformation = ({
  employee,
  updateEmployee,
  refetchEmployee,
}: EmployeeDetailInformationProps) => {
  // -- State --
  const [editable, setEditable] = useState<boolean>(false)
  const [updateLoading, setUpdateLoading] = useState(false)

  // -- Hooks --
  const { t } = useTranslation()

  // -- Vars --
  const hasQuoteFlow = [
    'QuotationPendingApproval',
    'QuotationApprovedByEmployer',
    'QuotationApprovedByEmployee',
    'QuotationApproved',
  ].includes(employee.hcpStatus?.status)

  const bannerText = match(employee.hcpStatus?.status)
    .with(
      'InstallationPlanned',
      'Configured',
      'CertificationPlanned',
      'OnboardingCompleted',
      'OnboardingRejected',
      'PreSiteVisitPlanned',
      (status) => t(`admin.employee-detail.callout.${status}`)
    )
    .with('SharedWithInstallationPartner', () =>
      t(`admin.employee-detail.callout.OnboardingCompleted`)
    )
    .with('Invited', () =>
      employee.hcpStatus?.overdue
        ? t(`admin.employee-detail.callout.Invited.overdue`, {
            days: INVITE_REMINDER_MAX_DAYS,
          })
        : t(`admin.employee-detail.callout.Invited`)
    )
    .with('Onboarding', () =>
      employee.hcpStatus?.overdue
        ? t(`admin.employee-detail.callout.Onboarding.overdue`, {
            days: ONBOARDING_REMINDER_MAX_DAYS,
          })
        : t(`admin.employee-detail.callout.Onboarding`)
    )
    .with('Registered', () =>
      t(`admin.employee-detail.callout.Registered`, {
        date: format(
          new Date(employee.members[0].activationDate!),
          'dd/MM/yyyy'
        ),
      })
    )
    .with('ExportedForMigration', () =>
      t(`admin.employee-detail.callout.ExportedForMigration`)
    )
    // Don't use exhaustiveness check here, we want to allow new hcp status types without changing the code here
    .otherwise(() => undefined)

  // -- Handlers --
  const handleUpdate = async (data: PersonalDataEditValues) => {
    setUpdateLoading(true)
    const response = await updateEmployee({ ...data, id: employee.id })
    setUpdateLoading(false)

    return response
  }

  return (
    <StContainer>
      {bannerText && (
        <Banner type={BannerType.INFORMATION} icon={['fass', 'info-square']}>
          {bannerText}
        </Banner>
      )}

      {hasQuoteFlow ? (
        <DetailQuoteApproval
          employee={employee}
          refetchEmployee={refetchEmployee}
        />
      ) : null}

      <PersonalDataEdit
        user={employee}
        disabled={updateLoading}
        updateUser={handleUpdate}
        disableEdit={!employee.auth0Id}
        title={t('profile.personal-data.title')}
        editable={editable}
        setEditable={setEditable}
      />

      <PracticalInfoCard
        employee={employee}
        refetchEmployee={refetchEmployee}
      />

      {(employee.members[0].hcpContract ||
        employee.members[0].mspContractId) && (
        <EmployeeContractCard
          refetchEmployee={refetchEmployee}
          employeeMemberId={employee.members[0].id}
          hcpContract={employee.members[0].hcpContract}
          mspContract={employee.members[0].mspContract}
          companyId={employee.members[0].companyId}
        />
      )}

      {match(employee.hcpStatus?.status)
        .with(
          'SharedWithInstallationPartner',
          'InstallationPlanned',
          'Configured',
          'CertificationPlanned',
          'Operational',
          'Inapplicable',
          () => <QuoteCard employee={employee} />
        )
        .otherwise(() => null)}

      <PairedDevicesCard
        dongleMacAddress={employee.members[0]?.scoptDongleMacAddress ?? null}
        ocppId={employee.addresses[0]?.devices[0]?.ocppId ?? null}
      />
    </StContainer>
  )
}

const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};

  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space10};
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space10};
`
