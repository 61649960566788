import { useState, useEffect, useMemo } from 'react'
import { useLocalStorage } from 'usehooks-ts'

import { LocalStorageKeys } from '../../../config/constants/local-storage-keys'
import { useUser } from '../../user/hooks/useUser'

import type { Company, MspCard } from 'database'
import type { FullMember } from 'types'

type UseMemberReturnType = {
  members: FullMember[]
  currentMember: FullMember
  currentChargeCard?: MspCard & { member: FullMember }
  setCurrentMember: (memberId: string, companyId?: string) => void
  isCurrentMemberSuperAdmin: boolean
  companies: Company[]
  isRealUserSuperAdmin: boolean
}

/** Get or change the current active member */
export const useMember = (): UseMemberReturnType => {
  const { user, companies } = useUser()

  // Helper function to find SuperAdmin member
  const findSuperAdminMember = (members: FullMember[]) => {
    return members.find((member) => member.roles.includes('SuperAdmin'))
  }

  const [currentMemberId, setCurrentMemberId] = useLocalStorage(
    LocalStorageKeys.CURRENT_MEMBER,
    findSuperAdminMember(user.members)?.id ?? user.members[0]?.id ?? ''
  )

  const [selectedCompanyId, setSelectedCompanyId] = useLocalStorage(
    LocalStorageKeys.SELECTED_COMPANY,
    findSuperAdminMember(user.members)?.companyId ??
      user.members[0]?.companyId ??
      ''
  )

  // Track the superadmin's original member ID - use try-catch for safer access
  const getSuperAdminMemberId = (): string | null => {
    try {
      return localStorage.getItem(LocalStorageKeys.SUPERADMIN_MEMBER)
    } catch (error) {
      console.warn(`Error accessing localStorage:`, error)
      return null
    }
  }

  const superAdminMemberId = getSuperAdminMemberId()

  // Cache the current member's permissions
  const [cachedPermissions, setCachedPermissions] = useState<
    (typeof user.members)[0]['permissions']
  >([])

  // Check whether the real user has a superAdmin membership
  const isRealUserSuperAdmin = useMemo(() => {
    return user.members.some((m) => m.roles.includes('SuperAdmin'))
  }, [user.members])

  // Check if we're currently impersonating
  const isImpersonating =
    isRealUserSuperAdmin &&
    superAdminMemberId &&
    currentMemberId !== superAdminMemberId

  // First try to find membership among user's own members
  let currentMember = useMemo<FullMember | null>(() => {
    return user.members.find((m) => m.id === currentMemberId) ?? null
  }, [currentMemberId, user.members])

  // If we're still impersonating and have no member, clone an existing member's structure
  if (isImpersonating && !currentMember && user.members.length > 0) {
    const templateMember = user.members[0]

    // Clone the structure but replace fields to make it a valid impersonated member
    currentMember = {
      ...templateMember,
      id: currentMemberId, // Override with impersonated ID
      user: user, // Use current user
      userId: user.id,
      company: {
        ...templateMember.company,
        id: '',
        name: 'Loading...',
        email: '',
      },
      roles: ['Employee'],
      permissions: [],
      mspCards: [],
    } as FullMember
  }

  // Update cached permissions when they're valid
  useEffect(() => {
    if (currentMember?.permissions && currentMember.permissions.length > 0) {
      setCachedPermissions(currentMember.permissions)
    }
  }, [currentMember?.permissions])

  // Use cached permissions if current permissions are empty
  if (
    currentMember &&
    (!currentMember.permissions || currentMember.permissions.length === 0)
  ) {
    currentMember = {
      ...currentMember,
      permissions: cachedPermissions,
    }
  }

  const isCurrentMemberSuperAdmin = currentMember?.roles.includes('SuperAdmin')

  // If superAdmin, check for selected company
  if (isCurrentMemberSuperAdmin && selectedCompanyId) {
    const selectedCompany = companies.find(
      (company) => company.id === selectedCompanyId
    )

    if (selectedCompany && selectedCompany.id !== currentMember?.companyId) {
      const memberData = { ...currentMember } as FullMember
      memberData.companyId = selectedCompany.id
      memberData.company = {
        ...selectedCompany,
        mspContracts: [],
        hcpContracts: [],
      }
      currentMember = memberData
    }
  }

  const setCurrentMember = (memberId: string, companyId?: string) => {
    // Store the superAdmin's member ID if they're a superAdmin
    const superAdminMember = user.members.find((m) =>
      m.roles.includes('SuperAdmin')
    )

    if (superAdminMember) {
      localStorage.setItem(
        LocalStorageKeys.SUPERADMIN_MEMBER,
        JSON.stringify(superAdminMember.id)
      )
    }

    setCurrentMemberId(memberId)
    companyId && setSelectedCompanyId(companyId)
  }

  if (!currentMember) {
    throw new Error('No current member')
  }

  const activeChargeCard = currentMember.mspCards?.find(
    (card) => card.mspCardStatus === 'Active'
  )

  const currentChargeCard =
    // If there is an active charge card, use that
    // If there is no active charge card, use the first cancelled card
    // Cancelled cards are sorted in the backend
    activeChargeCard ?? currentMember.mspCards?.find((card) => card.cancelledAt)

  // Include permissions in the member data
  const fullMembers = user.members.map((member) => ({
    ...member,
    user: user,
    permissions: member.permissions || [],
  }))

  return {
    isCurrentMemberSuperAdmin: isCurrentMemberSuperAdmin ?? false,
    companies,
    members: fullMembers,
    currentMember: {
      ...currentMember,
      user,
      permissions: currentMember?.permissions || cachedPermissions,
    },
    currentChargeCard: currentChargeCard
      ? {
          ...currentChargeCard,
          member: {
            ...currentMember,
            user,
            permissions: currentMember?.permissions || cachedPermissions,
          },
        }
      : undefined,
    /** set current member via memberId */
    setCurrentMember,
    isRealUserSuperAdmin,
  }
}
