import {
  endOfDay,
  lastDayOfMonth,
  setDate,
  startOfDay,
  subDays,
  format,
} from 'date-fns'
import { useState } from 'react'
import { styled } from 'styled-components'

import { Urls } from '../../../../api/urls'
import { Drawer, DrawerContent } from '../../../../components/general/Drawer'
import { useGetDateFnsLocale } from '../../../../core/hooks/useGetDateFnsLocale'
import { SortKeys } from '../../../../dataTable/components/DataTableHeader'
import {
  DataTableProvider,
  PaginationType,
} from '../../../../dataTable/providers/DataTableProvider'

import { EmployeeDetailAnomaliesDataTable } from './EmployeeDetailAnomaliesDataTable'
import { EmployeeDetailChargingSessionInfo } from './EmployeeDetailChargingSessionInfo'
import { EmployeeDetailUsageDataTable } from './EmployeeDetailUsageDataTable'

import type { AnomalyWithRelations } from '../../../usage/types/types'
import type {
  ChargingSessionWithDetectedAnomalies,
  UserWithContractAndCards,
} from 'types'

type EmployeeDetailUsageProps = {
  employee: UserWithContractAndCards
}

export const EmployeeDetailUsage = ({ employee }: EmployeeDetailUsageProps) => {
  // Shared drawer state
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [selectedSession, setSelectedSession] =
    useState<ChargingSessionWithDetectedAnomalies | null>(null)
  const dateFnsLocale = useGetDateFnsLocale()

  // Unified handler for both anomalies and regular sessions
  const handleSessionClick = (
    session: ChargingSessionWithDetectedAnomalies
  ) => {
    setSelectedSession(session)
    setIsDrawerOpen(true)
  }

  // Handler specifically for anomalies
  const handleAnomalyClick = (anomaly: AnomalyWithRelations) => {
    if (anomaly.chargingSession) {
      const fullSession = JSON.parse(
        JSON.stringify(anomaly.chargingSession)
      ) as ChargingSessionWithDetectedAnomalies

      // Add the anomaly information to the session object
      if (!fullSession.anomalies) {
        fullSession.anomalies = []
      }

      fullSession.anomalies.push({
        type: anomaly.type,
        id: anomaly.id,
        actualCost: anomaly.actualCost ?? undefined,
        detectedRegion: anomaly.detectedRegion ?? undefined,
      })

      handleSessionClick(fullSession)
    }
  }

  return (
    <StContainer>
      {/* Anomalies Section */}
      <DataTableProvider
        paginationType={PaginationType.Pagination}
        url={Urls.employerChargingAnomalies}
        defaultSort="timestamp"
        defaultSortDirection={SortKeys.DESCENDING}
        initialFilterValues={{
          dateFrom: subDays(new Date(), 30),
          dateTo: new Date(),
          memberId: employee.members[0].id,
        }}
        standaloneFilterKeys={['dateFrom', 'dateTo']}
        amount={5}
      >
        <EmployeeDetailAnomaliesDataTable onRowClick={handleAnomalyClick} />
      </DataTableProvider>

      {/* Usage Section */}
      <DataTableProvider
        paginationType={PaginationType.Pagination}
        url={Urls.employeeChargingSessions + `?employeeId=${employee.id}`}
        defaultSort="start"
        defaultSortDirection={SortKeys.DESCENDING}
        initialFilterValues={{
          dateFrom: startOfDay(setDate(new Date(), 1)),
          dateTo: endOfDay(lastDayOfMonth(new Date())),
        }}
        standaloneFilterKeys={['dateFrom', 'dateTo']}
        amount={5}
      >
        <EmployeeDetailUsageDataTable
          employeeId={employee.members[0].id}
          onRowClick={(session: ChargingSessionWithDetectedAnomalies) => {
            handleSessionClick(session)
          }}
        />
      </DataTableProvider>

      {selectedSession && (
        <Drawer open={isDrawerOpen}>
          <DrawerContent
            onClose={() => setIsDrawerOpen(false)}
            title={format(
              new Date(selectedSession.start),
              'dd MMMM yyyy',
              dateFnsLocale
            )}
          >
            <EmployeeDetailChargingSessionInfo
              chargingSession={selectedSession}
            />
          </DrawerContent>
        </Drawer>
      )}
    </StContainer>
  )
}

const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};

  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space10};
`
