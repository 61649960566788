import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { BodyExtraSmallRegular } from '../../../components/typography'
import { doubleBoltIcon } from '../../../core/icons/DoubleBolt'

import type { ChargingSessionWithRelations } from 'types'

type ChargingSpeedChipProps = {
  chargingSession: ChargingSessionWithRelations
}

export const ChargingSpeedChip = ({
  chargingSession,
}: ChargingSpeedChipProps) => {
  const { t } = useTranslation()

  return (
    <StContainer>
      <StIcon
        icon={
          chargingSession.powerType === 'DC' ? doubleBoltIcon : ['fass', 'bolt']
        }
      />
      <BodyExtraSmallRegular>
        {chargingSession.powerType === 'DC'
          ? t('employee.chargingSessions.detail.fast')
          : t('employee.chargingSessions.detail.standard')}
      </BodyExtraSmallRegular>
    </StContainer>
  )
}

const StContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space1};
  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space2} ${theme.UI.SpacingPx.Space3}`};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};
  background-color: ${({ theme }) => theme.theme.colors['nonary-8']};

  min-height: ${({ theme }) => theme.UI.SpacingPx.Space10};
`

const StIcon = styled(FontAwesomeIcon)`
  font-size: ${({ theme }) => theme.UI.SpacingPx.Space3};
`
