import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { Trans, useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { ButtonQuaternary } from '../../button/ButtonQuaternary'
import { Dialog } from '../../general/Dialog'
import { InfoButton } from '../../general/InfoButton'
import { Spinner } from '../../general/Spinner'
import { ZohoImage, ZohoModule } from '../../image/ZohoImage'
import {
  BodyLargeSemiBold,
  BodyMediumRegular,
  BodyMediumSemiBoldCss,
  H3,
} from '../../typography'

import { FilePreview } from './FilePreview'
import { getFileErrorMessage } from './lib/getFileErrorMessage'

type Examples = {
  title: string
  subtitle: string
  pictures: string[]
}

type FileInputProps = {
  /** Zoho file Id */
  value?: string
  /** Preview file */
  file?: File
  label?: string
  fileLabel: string
  isLoading?: boolean
  loadingText?: string
  error?: string
  touched?: boolean
  onFileChange: (file: File) => void
  onDelete: () => void
  example?: Examples
  fileType?: 'image' | 'csv' | 'pdf'
  deleteText?: string // Optional text to show instead of X icon for delete button
}

export const FileInput = ({
  value,
  file,
  onFileChange,
  onDelete,
  label,
  isLoading,
  loadingText,
  error,
  touched = true,
  example,
  fileLabel,
  fileType = 'image',
  deleteText,
}: FileInputProps) => {
  // -- Hooks --
  const { t } = useTranslation()

  // -- Vars --
  const fileTypesMap = {
    image: ['image/jpeg', 'image/png'],
    csv: ['text/csv'],
    pdf: ['application/pdf'],
  }

  const allowedFileTypes = new Set(fileTypesMap[fileType])

  const allowedDropzoneFileTypes = {
    image: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
    },
    csv: {
      'text/csv': ['.csv'],
    },
    pdf: {
      'application/pdf': ['.pdf'],
    },
  }

  // -- Handlers --
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 0) {
        return
      }

      const selectedFile = acceptedFiles[0]

      if (selectedFile && allowedFileTypes.has(selectedFile.type)) {
        onFileChange(selectedFile)
      } else {
        switch (fileType) {
          case 'image':
          default: {
            alert('Only images (JPEG/PNG) are allowed.')
            break
          }
          case 'csv': {
            alert('Only CSV files are allowed.')
            break
          }
          case 'pdf': {
            alert('Only PDF files are allowed.')
            break
          }
        }
      }
    },
    [allowedFileTypes]
  )

  // -- Drop logic --
  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      accept: allowedDropzoneFileTypes[fileType],
      disabled: (!!value || !!file) && !error,
      multiple: false,
      onDrop,
      maxSize: 10000000, // 10MB
    })

  const hasError = (fileRejections.length > 0 || !!error) && touched
  const errorMessage = hasError
    ? error || getFileErrorMessage(fileRejections[0].errors[0].code)
    : ''

  // -- Render --
  return (
    <div>
      {label ? (
        <StLabelContainer>
          <StLabel>{label}</StLabel>
          {example ? (
            <Dialog
              primaryButtonText={t('input.upload.info.close')}
              trigger={<InfoButton />}
            >
              <H3>{example.title}</H3>
              <StSubtitle>{example.subtitle}</StSubtitle>
              <BodyLargeSemiBold>
                {t('input.upload.info.examples', {
                  count: example.pictures.length,
                })}
              </BodyLargeSemiBold>
              <StExampleContainer>
                {example.pictures.map((image) => (
                  <StImage key={image} src={image} alt="" />
                ))}
              </StExampleContainer>
            </Dialog>
          ) : null}
        </StLabelContainer>
      ) : null}

      <StDropzone
        {...getRootProps()}
        $hasValue={!!value || !!file}
        $hover={isDragActive}
        $loading={isLoading}
        $error={hasError}
      >
        <input {...getInputProps()} />
        {isLoading ? (
          <StFlexContainer>
            <Spinner />
            <StText>{loadingText ?? t('input.upload.loading')}</StText>
          </StFlexContainer>
        ) : hasError ? (
          <StFlexContainer $error={hasError}>
            <FontAwesomeIcon icon={['fasr', 'triangle-exclamation']} />
            <StText>{t(errorMessage)}</StText>
            <StErrorCTA>
              <Trans
                t={t}
                i18nKey={'input.upload.placeholder.try-again'}
                components={{
                  u: <u />,
                }}
              />
            </StErrorCTA>
          </StFlexContainer>
        ) : file ? (
          <StFlexContainer>
            {fileType === 'image' ? (
              <FilePreview file={file} />
            ) : (
              <StSuccessIcon icon={['fass', 'circle-check']} />
            )}

            <StText>{fileLabel}</StText>

            <StButtonWrapper>
              <ButtonQuaternary onClick={onDelete}>
                {deleteText || <FontAwesomeIcon icon={['fasr', 'xmark']} />}
              </ButtonQuaternary>
            </StButtonWrapper>
          </StFlexContainer>
        ) : value ? (
          <StFlexContainer>
            {fileType === 'image' ? (
              <StFilePreview>
                <ZohoImage id={value} module={ZohoModule.File} />
              </StFilePreview>
            ) : (
              <StSuccessIcon icon={['fass', 'circle-check']} />
            )}

            <StText>{fileLabel}</StText>

            <StButtonWrapper>
              <ButtonQuaternary onClick={onDelete}>
                {deleteText || <FontAwesomeIcon icon={['fasr', 'xmark']} />}
              </ButtonQuaternary>
            </StButtonWrapper>
          </StFlexContainer>
        ) : (
          <BodyMediumRegular>
            <Trans
              t={t}
              i18nKey={
                fileType === 'image'
                  ? 'input.upload.placeholder.image'
                  : 'input.upload.placeholder.file'
              }
              components={{
                u: <u />,
              }}
            />
          </BodyMediumRegular>
        )}
      </StDropzone>
    </div>
  )
}

const StDropzone = styled.div<{
  $hasValue: boolean
  $hover: boolean
  $loading?: boolean
  $error?: boolean
}>`
  cursor: pointer;
  display: flex;

  flex-direction: column;
  justify-content: center;
  height: ${({ theme }) => theme.UI.SpacingPx.Space13};
  border: 1px dashed ${({ theme }) => theme.theme.colors['nonary-2']};
  border-width: ${({ $hasValue, $error, $loading }) =>
    $hasValue || $error || $loading ? 0 : 1};

  background-color: ${({ theme, $hover, $loading, $hasValue, $error }) =>
    $loading
      ? theme.theme.colors['primary-3']
      : $error
      ? theme.theme.colors['senary-4']
      : $hasValue
      ? theme.theme.colors['quaternary-6']
      : $hover
      ? theme.theme.colors.white
      : theme.theme.colors['nonary-9']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};

  padding: ${({ theme, $hasValue }) =>
    $hasValue
      ? `${theme.UI.SpacingPx.Space2}`
      : `${theme.UI.SpacingPx.Space3} ${theme.UI.SpacingPx.Space4}`};
`

const StLabel = styled.label`
  ${BodyMediumSemiBoldCss}
  display: block;
`

const StFlexContainer = styled.div<{ $error?: boolean }>`
  height: 100%;

  display: flex;
  align-items: center;

  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};

  color: ${({ $error, theme }) =>
    $error ? theme.components.input.error : theme.theme.colors.black};
`

const StText = styled(BodyMediumRegular)`
  overflow: hidden;
  white-space: nowrap;
  display: inline;
  text-overflow: ellipsis;
`

const StErrorCTA = styled(StText)`
  color: ${({ theme }) => theme.theme.colors.black};
  margin-left: auto;
`

const StFilePreview = styled.div`
  height: 100%;

  aspect-ratio: 1/1;

  object-fit: cover;
  border-radius: 3px;
  overflow: hidden;
`

const StLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};

  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StSubtitle = styled(BodyMediumRegular)`
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space5};
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space10};
`

const StImage = styled.img`
  min-width: 0;
  width: 100%;
  max-width: 300px;
  aspect-ratio: 1/1;

  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
`
const StExampleContainer = styled.div`
  display: flex;
  overflow-x: auto;

  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};

  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

const StSuccessIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.components.input.success};
  font-size: 16px;
  margin-left: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StButtonWrapper = styled.div`
  margin-left: auto;
`
