import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { useAuthMemberAxios } from '../../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../../api/urls'
import { OnboardingStepTitle } from '../../components/OnboardingStepTitle'
import { SelectBoxes } from '../../components/SelectBoxes'
import { useOnboarding } from '../../hooks/useOnboarding'

export enum UseReimbursementsKeys {
  Yes = 'Yes',
  No = 'No',
}

export const UseReimbursementsStep = () => {
  // -- Data --
  const {
    setLoading,
    handleNext,
    values,
    items: { useReimbursementsItems },
  } = useOnboarding()

  const [, execute] = useAuthMemberAxios(
    {
      url: Urls.OnboardingData,
      method: 'POST',
    },
    {
      manual: true,
    }
  )

  // -- State --
  const [selectedItem, setSelectedItem] = useState(values.useReimbursementsKey)

  // -- Hooks --
  const { t } = useTranslation()

  // -- Vars --

  // -- Handlers --
  const handleSelect = async (key: UseReimbursementsKeys) => {
    try {
      setSelectedItem(key)
      setLoading(true)

      if (key !== values.useReimbursementsKey) {
        await execute({
          data: {
            useReimbursementsKey: key,
          },
        })
      }

      handleNext(
        { ...values, useReimbursementsKey: key },
        key === UseReimbursementsKeys.No
      )
    } catch {
      setLoading(false)
      return console.error('Failed to select use reimbursements')
    }
  }

  // -- Render --
  return (
    <StContainer>
      <OnboardingStepTitle>
        {t('onboarding.useReimbursements.title')}
      </OnboardingStepTitle>

      <SelectBoxes
        items={useReimbursementsItems}
        onSelect={handleSelect}
        selectedItem={selectedItem}
      />
    </StContainer>
  )
}

const StContainer = styled.div`
  height: 100%;
`
