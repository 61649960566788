import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { EventType, useTracking } from '../../../../analytics/hooks/useTracking'
import { Select, type SelectOption } from '../../../../components/form/Select'
import { Dialog } from '../../../../components/general/Dialog'
import { BodyMediumRegular } from '../../../../components/typography'

import type { UserWithContractAndCards } from 'types'

type DeleteEmployeeDialogProps = {
  open: boolean
  setOpen: (open: boolean) => void
  handleDelete: (reason: string) => void
  employee: UserWithContractAndCards
}

export const DeleteEmployeeDialog = ({
  open,
  setOpen,
  handleDelete,
  employee,
}: DeleteEmployeeDialogProps) => {
  // -- Hooks --
  const { t } = useTranslation()
  const { trackEvent } = useTracking()

  // -- Vars --
  const blockDeletion = [
    'SharedWithInstallationPartner',
    'InstallationPlanned',
    'CertificationPlanned',
  ].includes(employee.hcpStatus?.status ?? '')

  // -- State --
  const [step, setStep] = useState<'block' | 'reason' | 'confirm'>(
    blockDeletion ? 'block' : 'reason'
  )
  const [deleteReason, setDeleteReason] = useState<string>('LeftCompany')

  const deleteReasonOptions: SelectOption[] = [
    {
      label: t('admin.employee-detail.dialog.delete-reason.LeftCompany'),
      key: 'LeftCompany',
    },
    {
      label: t('admin.employee-detail.dialog.delete-reason.Fired'),
      key: 'Fired',
    },
    {
      label: t('admin.employee-detail.dialog.delete-reason.Retired'),
      key: 'Retired',
    },
    {
      label: t('admin.employee-detail.dialog.delete-reason.Deceased'),
      key: 'Deceased',
    },
    {
      label: t('admin.employee-detail.dialog.delete-reason.Other'),
      key: 'Other',
    },
  ]

  // -- Descriptions --
  const title = useMemo(() => {
    return step === 'block'
      ? t('admin.employee-detail.dialog.delete-title', {
          fullName: employee.fullName,
        })
      : step === 'reason'
      ? t('admin.employee-detail.dialog.reason-title', {
          fullName: employee.fullName,
        })
      : t('admin.employee-detail.dialog.confirm-title', {
          fullName: employee.fullName,
        })
  }, [step, t, employee.fullName])

  const primaryButtonText = useMemo(() => {
    return step === 'block'
      ? t('admin.employee-detail.dialog.close')
      : step === 'reason'
      ? t('admin.employee-detail.dialog.next')
      : t('admin.employee-detail.dialog.confirm-delete', {
          fullName: employee.fullName,
        })
  }, [step, t, employee.fullName])

  const confirmTexts = useMemo(() => {
    const hasHcp = employee.addresses[0]?.devices.length > 0
    const hasMsp = employee.members[0]?.mspCards?.some(
      (card) => card.mspCardStatus === 'Active'
    )

    if (!hasHcp && !hasMsp) {
      return [
        t('admin.employee-detail.dialog.confirm-delete-description-full', {
          fullName: employee.fullName,
        }),
      ]
    }

    if (!hasHcp && hasMsp) {
      return [
        t('admin.employee-detail.dialog.confirm-delete-description-msp', {
          fullName: employee.fullName,
        }),
      ]
    }

    if (hasHcp && hasMsp) {
      return [
        t('admin.employee-detail.dialog.confirm-delete-description-hcp-msp', {
          fullName: employee.fullName,
        }),
        t('admin.employee-detail.dialog.confirm-delete-description-hcp-msp-2', {
          fullName: employee.fullName,
        }),
      ]
    }

    return []
  }, [employee, t])

  // -- Handlers --
  const closeDialog = () => {
    setOpen(false)
    setStep(blockDeletion ? 'block' : 'reason')
    trackEvent(EventType.Close, 'close_delete_member_modal')
  }

  const handleDeleteStep = () => {
    if (step === 'block') {
      closeDialog()
      return
    }

    if (step === 'reason') {
      setStep('confirm')
      return
    }

    handleDelete(deleteReason)
  }

  // -- Content --
  const blockStepContent = (
    <StDeleteContent>
      <BodyMediumRegular>
        {t('admin.employee-detail.dialog.block-delete-description1', {
          fullName: employee.fullName,
        })}
      </BodyMediumRegular>
    </StDeleteContent>
  )

  const reasonStepContent = (
    <StDeleteContent>
      <BodyMediumRegular>
        {t('admin.employee-detail.dialog.delete-reason.description', {
          fullName: employee.fullName,
        })}
      </BodyMediumRegular>
      <Select
        options={deleteReasonOptions}
        value={deleteReason}
        label={t('admin.employee-detail.dialog.delete-reason.label')}
        onChange={(option) => setDeleteReason(option ?? 'LeftCompany')}
      />
    </StDeleteContent>
  )

  const confirmStepContent = (
    <StDeleteContent>
      <StDescriptionGroup>
        {confirmTexts.map((text) => (
          <BodyMediumRegular key={text}>{text}</BodyMediumRegular>
        ))}
      </StDescriptionGroup>
    </StDeleteContent>
  )

  // -- Render --
  return (
    <Dialog
      open={open}
      onClickCloseButton={() => setOpen(false)}
      title={title}
      primaryButtonText={primaryButtonText}
      secondaryButtonText={
        blockDeletion ? undefined : t('admin.employee-detail.dialog.cancel')
      }
      onClickPrimaryButton={handleDeleteStep}
      onClickSecondaryButton={blockDeletion ? undefined : closeDialog}
    >
      {step === 'block' && blockStepContent}
      {step === 'reason' && reasonStepContent}
      {step === 'confirm' && confirmStepContent}
    </Dialog>
  )
}

const StDeleteContent = styled.div`
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space5};

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space10};
`

const StDescriptionGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};
`
