import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { css, styled } from 'styled-components'

import { Skeleton } from '../../components/Skeleton'
import { BodyMediumSemiBoldCss } from '../../components/typography'
import { useDataTable } from '../hooks/useDataTable'

import { type Column, type BaseRow } from './DataTable'

import type { IconName } from '@fortawesome/fontawesome-svg-core'

interface DataTableHeaderProps<T> {
  columns: Column<T>[]
  empty?: boolean
  loading?: boolean
  hasFilters?: boolean
}

export type SortInfo = {
  key: string
  direction: SortKeys
}

export enum SortKeys {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

export const DataTableHeader = <T extends BaseRow>({
  columns,
  empty = false,
  loading = false,
  hasFilters = false,
}: DataTableHeaderProps<T>) => {
  const { navigationContainerBottom, sort, setSort } = useDataTable()

  const sortIcon = (column: Column<T>, sort: SortInfo): IconName => {
    // If the column is hidden, we wan't to show what sorting direction will be applied
    if (!sort || sort.key !== column.key) {
      return sort.direction === SortKeys.ASCENDING
        ? 'arrow-down-long'
        : 'arrow-up-long'
    }

    return sort.direction === SortKeys.ASCENDING
      ? 'arrow-up-long'
      : 'arrow-down-long'
  }

  if (loading) {
    return (
      <StHeaderContainerLoading>
        <tr>
          {columns.map((column) => (
            <StTableHeaderLoading key={column.key}>
              <Skeleton width={80} height={20} />
            </StTableHeaderLoading>
          ))}
        </tr>
      </StHeaderContainerLoading>
    )
  }

  return (
    <StHeaderContainer
      $empty={empty}
      $stickyPosition={navigationContainerBottom}
      $hasFilters={hasFilters}
    >
      <tr>
        {columns.map((column) => (
          <StTableHeader
            key={column.key}
            onClick={() =>
              column.sortable &&
              !empty &&
              setSort({
                key: column.key,
                direction:
                  sort?.direction === SortKeys.ASCENDING
                    ? SortKeys.DESCENDING
                    : SortKeys.ASCENDING,
              })
            }
          >
            <StInnerHeader
              $hide={!sort || sort.key !== column.key}
              $sortable={column.sortable && !empty}
            >
              <p>{column.label ?? '-'}</p>
              {sort && !empty && column.sortable && (
                <FontAwesomeIcon icon={['fasr', sortIcon(column, sort)]} />
              )}
            </StInnerHeader>
          </StTableHeader>
        ))}
      </tr>
    </StHeaderContainer>
  )
}

const StHeaderContainer = styled.thead<{
  $empty?: boolean
  $stickyPosition?: number
  $hasFilters?: boolean
}>`
  box-shadow: inset 0 -1px 0 ${({ $empty, theme }) => ($empty ? 'transparent' : theme.theme.colors['nonary-7'])};

  background-color: white;
  border-collapse: separate;

  position: relative;
  z-index: 10;

  ${({ $stickyPosition, $hasFilters }) =>
    $stickyPosition &&
    css`
      position: sticky;
      /* We can assume the filter row is 52px high + 12px padding on top */
      top: ${$stickyPosition + ($hasFilters ? 64 : 0)}px;
      padding-top: ${({ theme }) => theme.UI.SpacingPx.Space1};
    `}
`

const StHeaderContainerLoading = styled(StHeaderContainer)`
  box-shadow: none;
`

const StTableHeader = styled.th`
  ${BodyMediumSemiBoldCss}
  text-align: left;

  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space6} ${theme.UI.SpacingPx.Space3}`};

  &:first-child {
    padding-left: 10px;
  }

  &:last-child {
    padding-right: 10px;
  }
`

const StTableHeaderLoading = styled(StTableHeader)`
  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space4};

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`

const StInnerHeader = styled.div<{ $hide: boolean; $sortable?: boolean }>`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};

  z-index: 1000;

  user-select: none;

  cursor: ${({ $sortable }) => ($sortable ? 'pointer' : 'default')};

  > svg {
    opacity: ${({ $hide }) => ($hide ? '0' : '1')};
    height: ${({ theme }) => theme.UI.SpacingPx.Space4};
  }

  ${({ $sortable }) =>
    $sortable &&
    css`
      &:hover {
        color: ${({ theme }) => theme.theme.text.body['gray-mid']};

        > svg {
          opacity: 1;
        }
      }
    `}
`
