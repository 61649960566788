import { useParams } from 'react-router-dom'

import { FullPageLoader } from '../../../components/general/FullPageLoader'
import { EmployeeDetail } from '../components/employeeDetail/EmployeeDetail'
import { useEmployeeDetail } from '../hooks/useEmployeeDetail'

export const EmployeeDetailScreen = () => {
  // -- Hooks --
  const { blossomId } = useParams()

  // -- Data --
  const { employee, loading, error, updateEmployee } =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useEmployeeDetail(blossomId!)

  if (loading) {
    return <FullPageLoader withNavigation={false} />
  }

  if (error || !employee) {
    throw new Error('Failed to fetch employee')
  }

  // -- Render --
  return <EmployeeDetail employee={employee} updateEmployee={updateEmployee} />
}
