import { useContext } from 'react'

import { documentContext } from '../providers/DocumentProvider'

export const useDocuments = () => {
  const context = useContext(documentContext)

  if (!context) {
    throw new Error('useDocuments needs to be used inside a DocumentProvider')
  }

  return context
}
