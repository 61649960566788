import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Portal } from '@radix-ui/react-portal'
import * as RadixSelect from '@radix-ui/react-select'
import { useField } from 'formik'
import { styled, css } from 'styled-components'

import {
  BodyLargeMediumCss,
  BodyMediumRegular,
  BodyMediumRegularCss,
  BodyMediumSemiBoldCss,
  BodySmallRegularCss,
} from '../typography'

import type { IconProp } from '@fortawesome/fontawesome-svg-core'

export type SelectOption = {
  key: string
  label: string
}

type SelectProps = {
  value?: string
  options: SelectOption[]
  onChange: (value?: string) => void
  onBlur?: () => void
  label?: string
  placeholder?: string
  disabled?: boolean
  readOnly?: boolean
  touched?: boolean
  hasError?: boolean
  size?: 'sm' | 'md' | 'lg'
  icon?: IconProp
}

export const Select = ({
  options,
  value,
  onChange,
  label,
  placeholder,
  onBlur,
  disabled,
  readOnly,
  touched,
  hasError,
  size = 'lg',
  icon,
}: SelectProps) => {
  const hasEmptyValue = options.some((option) => option.key === 'NULL')

  const selectedOption = options.find((option) => option.key === value)

  // -- Render --
  return (
    <RadixSelect.Root
      value={value ?? (hasEmptyValue ? 'NULL' : undefined)}
      onValueChange={(selectedValue) => {
        if (selectedValue === 'NULL') {
          onChange(undefined)

          return
        }

        onChange(selectedValue)
      }}
      onOpenChange={onBlur}
      disabled={disabled || readOnly}
    >
      <div>
        {label ? <StLabel $readOnly={readOnly}>{label}</StLabel> : null}
        <StInput
          $hasValue={!!value && value !== 'NULL'}
          $readOnly={readOnly}
          $error={touched && !!hasError}
          $size={size}
        >
          <StValue>
            {icon && <StIcon icon={icon} />}
            <RadixSelect.Value asChild placeholder={placeholder}>
              <BodyMediumRegular>
                {selectedOption?.label && selectedOption?.label !== 'NULL'
                  ? selectedOption?.label
                  : placeholder}
              </BodyMediumRegular>
            </RadixSelect.Value>
          </StValue>
          {options.length > 0 ? (
            <StSelectTriggerIcon>
              <FontAwesomeIcon icon={['fasr', 'chevron-down']} />
            </StSelectTriggerIcon>
          ) : null}
        </StInput>
      </div>
      <Portal>
        <StDropDownContent
          // Prevent touch event passing trough the select and selecting items behind it
          ref={(ref) => {
            if (!ref) {
              return
            }

            ref.addEventListener('touchstart', (event) => {
              event.preventDefault()
            })
          }}
          position="popper"
        >
          <RadixSelect.Viewport>
            {options.map((item) => (
              <StSelectItem
                key={item.key}
                value={item.key ?? ''}
                $isSelected={
                  (value ?? (hasEmptyValue ? 'NULL' : undefined)) === item.key
                }
              >
                <RadixSelect.ItemText asChild>
                  <BodyMediumRegular>{item.label}</BodyMediumRegular>
                </RadixSelect.ItemText>
              </StSelectItem>
            ))}
          </RadixSelect.Viewport>
          <RadixSelect.ScrollDownButton />
        </StDropDownContent>
      </Portal>
    </RadixSelect.Root>
  )
}

export function ControlledSelect({ ...props }: ControlledSelectProps) {
  const [field, meta, helpers] = useField(props)

  return (
    <Select
      {...props}
      onChange={helpers.setValue}
      value={field.value}
      hasError={Boolean(meta.error)}
    />
  )
}
type ControlledSelectProps = Omit<
  SelectProps,
  'onChange' | 'value' | 'hasError'
> & {
  name: string
}

const StLabel = styled.label<{ $readOnly?: boolean }>`
  ${({ $readOnly }) =>
    $readOnly ? BodyMediumRegularCss : BodyMediumSemiBoldCss}
  display: block;

  color: ${({ theme, $readOnly }) =>
    $readOnly
      ? theme.theme.text.body['gray-mid']
      : theme.theme.text.body.black};
  margin-bottom: ${({ theme, $readOnly }) =>
    $readOnly ? 0 : theme.UI.SpacingPx.Space2};
`

const StInput = styled(RadixSelect.Trigger)<{
  $hasValue?: boolean
  $readOnly?: boolean
  $error?: boolean
  $size: 'sm' | 'md' | 'lg'
}>`
  ${({ $size }) =>
    $size === 'lg' ? BodyMediumRegularCss : BodySmallRegularCss}

  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
  cursor: pointer;

  overflow: hidden;

  width: 100%;
  position: relative;

  border: ${({ $readOnly, theme, $error, $hasValue }) =>
    $readOnly
      ? 'none'
      : $error
      ? `1px solid ${theme.components.input.error}`
      : $hasValue
      ? `1px solid ${theme.theme.colors.black}`
      : `1px solid ${theme.components.input.border}`};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};

  padding: ${({ theme, $size, $readOnly }) =>
    $readOnly
      ? 0
      : $size === 'lg'
      ? `0 ${theme.UI.SpacingPx.Space5}`
      : $size === 'md'
      ? `0 ${theme.UI.SpacingPx.Space4}`
      : `0 14px`};

  height: ${({ theme, $size }) =>
    $size === 'lg'
      ? theme.UI.SpacingPx.Space13
      : $size === 'md'
      ? theme.UI.SpacingPx.Space11
      : theme.UI.SpacingPx.Space10};

  background-color: ${({ theme }) => theme.components.input.bg};
  color: ${({ theme }) => theme.components.input['input-text']};

  span {
    overflow: hidden;
  }

  span p {
    ${({ $readOnly }) =>
      $readOnly ? BodyLargeMediumCss : BodyMediumRegularCss}
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:focus-visible {
    box-shadow: 0px 0px 0px 2px
      ${({ theme }) => theme.theme.colors['primary-0']};
    border-color: ${({ theme }) => theme.theme.colors['primary-0']};
  }

  &:hover,
  &[data-state='open'] {
    border-color: ${({ theme }) => theme.theme.colors['primary-0']};
  }

  &[data-disabled] {
    background-color: ${({ theme, $readOnly }) =>
      $readOnly ? 'transparent' : theme.theme.colors['nonary-9']};
    color: ${({ theme, $readOnly }) =>
      $readOnly
        ? theme.components.input['input-text']
        : theme.theme.text.body['gray-mid']};
    border-color: ${({ theme }) => theme.theme.colors['nonary-9']};
    cursor: not-allowed;

    svg {
      display: ${({ $readOnly }) => ($readOnly ? 'none' : 'block')};
    }

    &:hover {
      border-color: ${({ theme }) => theme.theme.colors['nonary-9']};
    }
  }
`

const StValue = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
  white-space: nowrap;

  min-width: 0;
`

const StSelectTriggerIcon = styled(RadixSelect.Icon)`
  flex-shrink: 0;
`

export const StError = styled(BodyMediumRegular)`
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space1};
  color: ${({ theme }) => theme.components.input.error};
`

const StDropDownContent = styled(RadixSelect.Content)`
  background-color: ${({ theme }) => theme.theme.colors.white};
  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};
  width: var(--radix-select-trigger-width);
  min-width: 200px;
  max-height: var(--radix-select-content-available-height);

  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space1};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space2} 0;

  z-index: 99999999999999;
`

const StSelectItem = styled(RadixSelect.Item)<{ $isSelected?: boolean }>`
  cursor: pointer;
  outline-offset: -1px;
  outline-color: ${({ theme }) => theme.theme.colors['primary-0']};
  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space3} ${theme.UI.SpacingPx.Space4}`};

  margin: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space1} ${theme.UI.SpacingPx.Space3}`};
  border-radius: 2px;

  &:hover,
  &:focus {
    outline: none;
    background-color: ${({ theme }) => theme.theme.colors['nonary-9']};
  }

  ${({ $isSelected }) =>
    $isSelected
      ? css`
          & > p {
            ${BodyMediumSemiBoldCss}
          }
        `
      : undefined}
`

const StIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`
