import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  BodySmallRegular,
  BodySmallMediumCss,
} from '../../components/typography'
type ImpersonationBannerProps = {
  onEndSession: () => void
}

export const ImpersonationBanner: FC<ImpersonationBannerProps> = ({
  onEndSession,
}) => {
  const { t } = useTranslation()

  return (
    <StBannerContainer>
      <StBannerContent>
        <BodySmallRegular>
          <StBannerIcon icon={['fasr', 'shield-keyhole']} />
          {t('navigation.impersonation.banner.title')}
        </BodySmallRegular>
        <EndSessionButton onClick={onEndSession}>
          <StBannerIcon icon={['fasr', 'arrow-right-from-bracket']} />
          {t('navigation.impersonation.banner.end-session')}
        </EndSessionButton>
      </StBannerContent>
    </StBannerContainer>
  )
}

const StBannerContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 600;
  width: 100%;
  background-color: ${({ theme }) => theme.theme.colors['nonary-1']};
  color: #fff;

  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space7};
`

const StBannerContent = styled.div`
  max-width: ${({ theme }) => theme.UI.MaxWidthPx.navigation};
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 52px;
`

const StBannerIcon = styled(FontAwesomeIcon)`
  font-size: 15px;
  margin-right: 10px;
`

const EndSessionButton = styled.button`
  ${BodySmallMediumCss}
  background: none;
  border: none;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
`
