import { atom } from 'recoil'

import { AtomKeys } from './atomsKeys'

import type { ZohoModule } from '../../components/image/ZohoImage'
import type { AppSettings } from '../hooks/useAppSettings'

export const inviteIdAtom = atom({
  key: AtomKeys.InviteId,
  default: '',
})

export const onboardingOriginAtom = atom({
  key: AtomKeys.OnboardingRedirectedFromDesktop,
  default: false,
})

export const zohoImageAtom = atom({
  key: AtomKeys.ZohoImage,
  default: {} as Record<ZohoModule, Record<string, Blob>>,
})

export const appSettingsAtom = atom<AppSettings>({
  key: AtomKeys.AppSettings,
  default: {} as AppSettings,
})

export const appSettingsLoadingAtom = atom<boolean>({
  key: AtomKeys.AppSettingsLoading,
  default: true,
})

export const disableScrollAtom = atom<boolean>({
  key: AtomKeys.DisableScroll,
  default: false,
})

export const translationsLoadedAtom = atom<boolean>({
  key: AtomKeys.TranslationsLoaded,
  default: false,
})
