import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isValidElement, type ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { css, styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../../theme/layout/breakpoints'
import { ButtonQuaternary } from '../../../components/button/ButtonQuaternary'
import { InfoPopup } from '../../../components/info-popup/InfoPopup'
import {
  BodyExtraExtraSmallRegular,
  BodyExtraExtraSmallRegularCss,
  BodyExtraExtraSmallSemiBold,
  BodyLargeSemiBoldCss,
  BodyMediumRegularCss,
  BodyMediumSemiBold,
  BodySmallRegular,
} from '../../../components/typography'
import { onHover } from '../../../core/lib/styleHelpers'
import { useIntercom } from '../../../intercom/hooks/useIntercom'

import type { IconProp } from '@fortawesome/fontawesome-svg-core'

export type SelectBoxesItems<T> = {
  name: string
  subTitle?: string
  icon?: IconProp | ReactNode
  image?: string
  key: T
  tooltipTitle?: string
  tooltipText?: string
  faqId?: string
}

type SelectBoxesProps<T> = {
  items: SelectBoxesItems<T>[]
  selectedItem?: string | null
  onSelect: (item: T) => void
  vertical?: boolean
  small?: boolean
}

export const SelectBoxes = <T,>({
  items,
  selectedItem,
  onSelect,
  vertical,
  small = false,
}: SelectBoxesProps<T>) => {
  // -- Hooks --
  const isDesktop = useMediaQuery(breakpoints.desktop)
  const { t } = useTranslation()
  const { showArticle } = useIntercom()

  // -- Handlers --
  const handleIntercomFaq = (faqId?: string) => {
    if (!faqId) {
      return
    }

    showArticle(Number.parseInt(faqId))
  }

  // -- Render --
  return (
    <StContainer
      $vertical={vertical || (items.length === 2 && !isDesktop)}
      $small={small}
    >
      {items.map((item) => (
        <StBox
          key={item.name}
          $selected={item.key === selectedItem}
          onClick={() => onSelect(item.key)}
          $small={small}
        >
          {item.icon ? (
            <StIconContainer>
              {isValidElement(item.icon) ? (
                item.icon
              ) : (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                <FontAwesomeIcon icon={item.icon} />
              )}
            </StIconContainer>
          ) : null}
          <StTitle>{item.name}</StTitle>
          {item.subTitle ? <StSubTitle>{item.subTitle}</StSubTitle> : null}
          {item.image ? <img src={item.image} alt="" /> : null}

          {item.tooltipTitle && item.tooltipText && (
            <StInfoPopupContainer>
              <InfoPopup
                theme="light"
                content={
                  <StTooltipText>
                    <BodyExtraExtraSmallSemiBold>
                      {item.tooltipTitle}
                    </BodyExtraExtraSmallSemiBold>
                    <BodyExtraExtraSmallRegular>
                      {item.tooltipText}
                    </BodyExtraExtraSmallRegular>
                    {item.faqId && (
                      <StFaqButton
                        type="button"
                        onClick={(event) => {
                          event.stopPropagation()
                          handleIntercomFaq(item.faqId)
                        }}
                      >
                        {t('intercom.faq.title')}
                      </StFaqButton>
                    )}
                  </StTooltipText>
                }
              />
            </StInfoPopupContainer>
          )}
        </StBox>
      ))}
    </StContainer>
  )
}

const StContainer = styled.div<{ $vertical: boolean; $small: boolean }>`
  display: flex;
  flex-wrap: wrap;

  flex-direction: ${({ $vertical }) => ($vertical ? 'column' : 'row')};

  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};

  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space5};

  @media ${breakpoints.desktop} {
    flex-direction: ${({ $vertical }) => ($vertical ? 'column' : 'row')};
    padding-top: ${({ $small }) => ($small ? 0 : '10vh')};
  }
`

const StBox = styled.button<{ $selected: boolean; $small: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};

  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space8} ${theme.UI.SpacingPx.Space4}`};

  border: 1px solid;
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  border-color: ${({ theme, $selected }) =>
    $selected
      ? theme.components['card-select']['hover-border']
      : theme.components['card-select']['default-border']};

  min-width: ${({ theme }) => theme.UI.SpacingPx.Space30};

  background-color: ${({ theme, $selected }) =>
    $selected
      ? theme.components['card-select']['active-bg']
      : theme.components['card-select']['default-bg']};

  &:hover {
    cursor: pointer;
    border-color: ${({ theme }) =>
      theme.components['card-select']['hover-border']};
  }

  &:focus {
    border-color: ${({ theme }) =>
      theme.components['card-select']['hover-border']};
  }

  @media ${breakpoints.desktop} {
    padding: ${({ theme, $small }) =>
      $small
        ? undefined
        : `${theme.UI.SpacingPx.Space20} ${theme.UI.SpacingPx.Space7}`};
  }
`

const StTitle = styled(BodyMediumSemiBold)`
  color: ${({ theme }) => theme.components['card-select'].text};

  @media ${breakpoints.desktop} {
    ${BodyLargeSemiBoldCss}
  }
`

export const StIconContainer = styled.div`
  background: linear-gradient(0deg, #dcf730 0%, #dcf730 100%),
    linear-gradient(150deg, #f2ffa2 -1.99%, #dcf730 100%);
  padding: ${({ theme }) => theme.UI.SpacingPx.Space5};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};

  color: ${({ theme }) => theme.components['card-select'].icon};

  height: 100px;
  width: 100px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 30px;
`
export const StSubTitle = styled(BodySmallRegular)`
  color: ${({ theme }) => theme.components['card-select'].text};

  @media ${breakpoints.desktop} {
    ${BodyMediumRegularCss}
  }
`

export const StInfoPopupContainer = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.UI.SpacingPx.Space4};
  right: ${({ theme }) => theme.UI.SpacingPx.Space4};
`

export const StTooltipText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

export const StFaqButton = styled(ButtonQuaternary)`
  ${BodyExtraExtraSmallRegularCss}

  color: ${({ theme }) => theme.theme.colors.black};
  padding: 0;

  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space2};

  ${onHover(css`
    opacity: 0.6;
  `)}
`
