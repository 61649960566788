import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import { BodyExtraSmallRegular, BodySmallSemiBold } from '../typography'

import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import type { ReactNode } from 'react'

type PriceBreakdownItemProps = {
  label: string
  value: string | ReactNode
  subLabel?: string
  subValue?: string
  icon: IconProp
}

export const PriceBreakdownItem = ({
  label,
  value,
  subLabel,
  subValue,
  icon,
}: PriceBreakdownItemProps) => {
  return (
    <StPriceBlock>
      <StPriceWrapper>
        <BodySmallSemiBold>{label}</BodySmallSemiBold>
        {typeof value === 'string' ? (
          <BodySmallSemiBold>{value}</BodySmallSemiBold>
        ) : (
          value
        )}
      </StPriceWrapper>
      {subLabel && (
        <StSubPriceWrapper>
          <BodyExtraSmallRegular>
            <StIcon icon={icon} />
            {subLabel}
          </BodyExtraSmallRegular>
          <BodyExtraSmallRegular>{subValue}</BodyExtraSmallRegular>
        </StSubPriceWrapper>
      )}
    </StPriceBlock>
  )
}

const StPriceBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const StPriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const StSubPriceWrapper = styled(StPriceWrapper)`
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`

const StIcon = styled(FontAwesomeIcon)`
  font-size: ${({ theme }) => theme.UI.SpacingPx.Space3};
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
  margin-right: ${({ theme }) => theme.UI.SpacingPx.Space1};
`
