import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { EmployeeRoutes } from '../../../../routing/routes'
import { breakpoints } from '../../../../theme/layout/breakpoints'
import { EventType, useTracking } from '../../../analytics/hooks/useTracking'
import { useAuthMemberAxios } from '../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../api/urls'
import { ButtonPrimary } from '../../../components/button/ButtonPrimary'
import { Select } from '../../../components/form/Select'
import { DetailPageHeader } from '../../../components/general/DetailPageHeader'
import { Dialog } from '../../../components/general/Dialog'
import { IntercomLogo } from '../../../components/intercom/IntercomLogo'
import { ContentContainer } from '../../../components/page-layout'
import { BodyMediumRegular } from '../../../components/typography'
import { useIntercom } from '../../../intercom/hooks/useIntercom'
import { useMember } from '../../../member/hooks/useMember'
import { useUser } from '../../../user/hooks/useUser'
import { FloatingContainerButton } from '../../onboarding/components/FloatingContainerButton'

import type { SelectOption } from '../../../components/form/Select'
import type { DetailPageHeaderAction } from '../../../components/general/DetailPageHeader'
import type { MspCard } from 'database'

// -- Constants --
export enum MspCardStatus {
  Active = 'Active',
  Stolen = 'Stolen',
  Lost = 'Lost',
  Damaged = 'Damaged',
  Blocked = 'Blocked',
}

export enum DeliveryMethod {
  Home = 'home',
  Manual = 'manual',
}

export const LostChargeCardScreen = () => {
  // -- Hooks --
  const { currentChargeCard } = useMember()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { refetch } = useUser()
  const { trackEvent } = useTracking()
  const { show } = useIntercom()
  const isDesktop = useMediaQuery(breakpoints.desktop)

  // -- State --
  const [loading, setLoading] = useState(false)
  const [isBlockConfirmationOpen, setIsBlockConfirmationOpen] = useState(false)
  const [lostReason, setLostReason] = useState<MspCardStatus | undefined>(
    undefined
  )
  const [deliveryMethod, setDeliveryMethod] = useState<
    DeliveryMethod | undefined
  >(undefined)

  // -- Data --
  const [, execute] = useAuthMemberAxios<MspCard>(
    {
      url: Urls.blockChargeCard,
      method: 'POST',
    },
    {
      manual: true,
    }
  )

  const lostCardReasons: SelectOption[] = [
    {
      key: MspCardStatus.Lost,
      label: t('charge-card.block.reason.lost'),
    },
    {
      key: MspCardStatus.Stolen,
      label: t('charge-card.block.reason.stolen'),
    },
  ]

  const deliveryOptions: SelectOption[] = [
    {
      key: DeliveryMethod.Home,
      label: t('charge-card.block.delivery.home'),
    },
    {
      key: DeliveryMethod.Manual,
      label: t('charge-card.block.delivery.manual'),
    },
  ]

  // -- Actions --
  const headerActions: DetailPageHeaderAction[] = [
    {
      name: 'Intercom',
      customIcon: <IntercomLogo />,
      onClick: show,
    },
  ]

  // -- Handlers --
  const handleBlockCardSubmit = async () => {
    if (!currentChargeCard) {
      return console.error('No active charge card')
    }

    if (!lostReason) {
      return console.error('No reason selected')
    }

    try {
      setLoading(true)

      await execute({
        data: {
          mspUid: currentChargeCard.mspUid,
          status: lostReason,
        },
      })

      await refetch()
      trackEvent(EventType.Submit, 'block_msp_card', {
        reason: lostReason,
        deliveryMethod: deliveryMethod,
      })
      toast.success(t('charge-card.block.success'))
      navigate(EmployeeRoutes.ChargeCard)
    } catch {
      setLoading(false)
      toast.error(t('charge-card.block.error'))

      return console.error('Failed to block msp card')
    }
  }

  // -- Render --
  if (!currentChargeCard || currentChargeCard.mspCardStatus !== 'Active') {
    return <Navigate to={EmployeeRoutes.ChargeCard} replace />
  }

  return (
    <ContentContainer>
      <StHeaderContainer>
        <DetailPageHeader
          onBack={() => navigate(EmployeeRoutes.ChargeCard)}
          title={t('charge-card.block.lost.title')}
          topTitle={t('charge-card.detail.title')}
          actions={headerActions}
        />
      </StHeaderContainer>

      <StContentWrapper>
        <StFormContainer>
          <StFormContent>
            <BodyMediumRegular>
              <StSpacedText>
                {t('charge-card.block.lost.description')}
              </StSpacedText>
              {t('charge-card.block.lost.notice')}
            </BodyMediumRegular>
            <Select
              label={t('charge-card.block.lost.reason.label')}
              placeholder={t('charge-card.block.lost.reason.placeholder')}
              value={lostReason}
              onChange={(value) =>
                value && setLostReason(value as MspCardStatus)
              }
              options={lostCardReasons}
            />
            <Select
              label={t('charge-card.block.lost.delivery.label')}
              placeholder={t('charge-card.block.lost.delivery.placeholder')}
              value={deliveryMethod}
              onChange={(value) =>
                value && setDeliveryMethod(value as DeliveryMethod)
              }
              options={deliveryOptions}
            />
          </StFormContent>
        </StFormContainer>

        {isDesktop ? (
          <FloatingContainerButton
            onClick={() => setIsBlockConfirmationOpen(true)}
            title={t('charge-card.block.button')}
            disabled={!lostReason || !deliveryMethod}
          />
        ) : (
          <StButtonContainer>
            <StSubmitButton
              onClick={() => setIsBlockConfirmationOpen(true)}
              disabled={!lostReason || !deliveryMethod}
            >
              {t('charge-card.block.button')}
            </StSubmitButton>
          </StButtonContainer>
        )}
      </StContentWrapper>

      {/* Confirmation Modal */}
      <Dialog
        title={t('charge-card.block.confirm.title')}
        open={isBlockConfirmationOpen}
        onOpenChange={setIsBlockConfirmationOpen}
        primaryButtonText={t('charge-card.block.confirm.button')}
        secondaryButtonText={t('charge-card.block.confirm.cancel')}
        onClickPrimaryButton={handleBlockCardSubmit}
        onClickSecondaryButton={() => setIsBlockConfirmationOpen(false)}
        disablePrimaryButton={loading}
        width="640px"
      />
    </ContentContainer>
  )
}

const StHeaderContainer = styled.div`
  position: relative;
`

const StFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};
`

const StContentWrapper = styled.div`
  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

const StFormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space6};
  max-width: 600px;
`

const StButtonContainer = styled.div`
  position: fixed;
  bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};
  left: ${({ theme }) => theme.UI.SpacingPx.Space6};
  right: ${({ theme }) => theme.UI.SpacingPx.Space6};
`

const StSubmitButton = styled(ButtonPrimary)`
  width: 100%;
`

const StSpacedText = styled.div`
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};
`
