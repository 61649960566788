import { autoUpdate, flip, offset, size, useFloating } from '@floating-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik } from 'formik'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import { useDebounce, useMediaQuery, useOnClickOutside } from 'usehooks-ts'
// eslint-disable-next-line import/named
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'

import { breakpoints } from '../../../../theme/layout/breakpoints'
import { useAxios } from '../../../api/hooks/useAxios'
import { ButtonQuaternary } from '../../../components/button/ButtonQuaternary'
import { AddressSelect } from '../../../components/form/address-search/AddressSelect'
import {
  Input,
  StLabel,
  StLabelContainer,
} from '../../../components/form/Input'
import { StError } from '../../../components/form/Select'
import { Dialog } from '../../../components/general/Dialog'
import { Loader } from '../../../components/general/Loader'
import {
  BodyMediumRegularCss,
  BodySmallRegular,
  BodySmallSemiBold,
} from '../../../components/typography'
import { euCountryCodes } from '../../../core/consts/countries'

import type {
  MapboxAddress,
  MapboxSuggestion,
  SuggestionResponse,
} from '../../../components/form/address-search/types'
import type { ChangeEvent } from 'react'

// -- Vars --
const SESSION_TOKEN = uuidv4()

// -- Type --
// Extract the types we need from mapbox so we can easily fake them for a custom address
export type PartialMapboxAddress = {
  features: Array<{
    properties: {
      context: {
        address: {
          id: string
          name: string
          street_name: string
          address_number: string
        }
        place?: {
          id: string
          name: string
        }
        postcode?: {
          id: string
          name: string
        }
        country: {
          id: string
          name: string
          country_code: string
        }
        region?: {
          id: string
          name: string
          region_code: string
        }
      }
    }
    geometry: {
      coordinates?: [number, number]
    }
  }>
}

type PersonalAddressSearchProps = {
  displayValue?: string
  onSelect: (address: MapboxAddress | PartialMapboxAddress | null) => void
  onChange?: (event: ChangeEvent) => void
  onBlur?: () => void
  name: string
  label?: string
  placeholder?: string
  disabled?: boolean
  sharp?: boolean
  showIcon?: boolean
  clearable?: boolean
  types?: (
    | 'country'
    | 'region'
    | 'postcode'
    | 'district'
    | 'place'
    | 'city'
    | 'locality'
    | 'neighborhood'
    | 'street'
    | 'address'
  )[]
  location?: { longitude: number; latitude: number }
  error?: string
  touched?: boolean
}

export const PersonalAddressSearch = ({
  displayValue = '',
  onSelect,
  onChange,
  onBlur,
  placeholder,
  types = [],
  location,
  disabled,
  sharp = true,
  label,
  name,
  showIcon,
  clearable,
  touched,
  error,
}: PersonalAddressSearchProps) => {
  // -- State --
  const [searchValue, setSearchValue] = useState(displayValue)
  const debouncedSearchValue = useDebounce(searchValue, 500)
  const [open, setOpen] = useState(false)
  const [openAddressCreation, setOpenAddressCreation] = useState(false)
  const [suggestions, setSuggestions] = useState<MapboxSuggestion[]>()
  const ref = useRef(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const isDesktop = useMediaQuery(breakpoints.desktop)

  // -- Hooks --
  const { t, i18n } = useTranslation()
  useOnClickOutside(ref, () => setOpen(false))

  const { refs, floatingStyles } = useFloating({
    open: open,
    onOpenChange: setOpen,
    placement: 'bottom',
    strategy: 'absolute',
    middleware: [
      offset(10),
      flip({
        fallbackPlacements: ['top'],
      }),
      // Match reference object (input) width
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
          })
        },
      }),
    ],
    whileElementsMounted(referenceElement, floatingElement, update) {
      return autoUpdate(referenceElement, floatingElement, update, {
        animationFrame: true,
      })
    },
  })

  // -- Search --
  const [{ data, loading: fetchingSuggestions }, execute] =
    useAxios<SuggestionResponse>(
      {
        url: 'https://api.mapbox.com/search/searchbox/v1/suggest',
        params: {
          access_token: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
          session_token: SESSION_TOKEN,
          q: searchValue,
          language: i18n.language,
          types: types.length > 0 ? types.join(', ') : undefined,
          country: euCountryCodes.join(','),
          proximity: location
            ? `${location.longitude},${location.latitude}`
            : undefined,
          limit: isDesktop ? 4 : 3,
        },
      },
      { manual: true }
    )

  const [, getAddressDetail] = useAxios<MapboxAddress>(
    {
      params: {
        access_token: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
        session_token: SESSION_TOKEN,
        language: i18n.language,
      },
    },
    { manual: true }
  )

  const validationSchema = Yup.object().shape({
    street: Yup.string().required(
      t('onboarding.address-search.create-address.error.street')
    ),
    number: Yup.string().required(
      t('onboarding.address-search.create-address.error.number')
    ),
    city: Yup.string().required(
      t('onboarding.address-search.create-address.error.city')
    ),
    postcode: Yup.string().required(
      t('onboarding.address-search.create-address.error.postcode')
    ),
  })

  const searchSuggestionsHeader = useMemo(() => {
    if (suggestions) {
      return t('onboarding.address-search.select-address')
    }

    return searchValue.length > 2
      ? t('onboarding.address-search.enter-house-number')
      : t('onboarding.address-search.enter-address')
  }, [suggestions, searchValue])

  // -- Effects --
  useEffect(() => {
    // Check if the search value is longer than 2 characters and contains a number
    if (searchValue.length > 2 && /\d/.test(searchValue)) {
      execute()
    } else {
      setSuggestions(undefined)
    }
  }, [debouncedSearchValue])

  useEffect(() => {
    if (data?.suggestions) {
      setSuggestions(data.suggestions)
    }
  }, [data])

  useEffect(() => {
    setSearchValue(displayValue)
  }, [displayValue])

  // -- Handlers --
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange ? onChange(event) : null
    setSearchValue(event.target.value)

    if (displayValue) {
      onBlur?.()
      onSelect(null)
    }
  }

  const handleClear = () => {
    setSearchValue('')
    setSuggestions([])
    setOpen(false)
    onBlur?.()
  }

  // When selecting an address from the dropdown
  const handleSelectAddress = async (address: MapboxSuggestion) => {
    setOpen(false)

    const AddressDetailResponse = await getAddressDetail(
      `https://api.mapbox.com/search/searchbox/v1/retrieve/${address.mapbox_id}`
    )

    onBlur?.()
    onSelect(AddressDetailResponse.data)
  }

  const handleClickInput = () => {
    setOpen(true)
  }

  const handleCreateAddress = () => {
    setOpenAddressCreation(true)
    setOpen(false)
  }

  // When filling in the address creation form
  const handleCreateAddressSubmit = async (values: {
    street: string
    number: string
    city: string
    postcode: string
  }) => {
    const address: PartialMapboxAddress = {
      features: [
        {
          properties: {
            context: {
              address: {
                id: uuidv4(),
                name: `${values.street} ${values.number}`,
                street_name: values.street,
                address_number: values.number,
              },
              place: {
                id: uuidv4(),
                name: values.city,
              },
              postcode: {
                id: uuidv4(),
                name: values.postcode,
              },
              country: {
                id: uuidv4(),
                name: 'België',
                country_code: 'BE',
              },
            },
          },
          geometry: {
            coordinates: undefined,
          },
        },
      ],
    }

    onSelect(address)
    setOpenAddressCreation(false)
  }

  return (
    <>
      <StContainer ref={ref}>
        {label ? (
          <StLabelContainer>
            <StLabel $size="md" $readOnly={false} htmlFor={name}>
              {label}
            </StLabel>
          </StLabelContainer>
        ) : null}
        <StInputFieldContainer
          $disabled={disabled}
          $sharp={sharp}
          $error={touched && !!error}
          $touched={touched}
          ref={refs.setReference}
        >
          {showIcon ? <StSearchIcon icon={['fasr', 'search']} /> : null}
          <StInput
            ref={inputRef}
            id={name}
            value={searchValue}
            onChange={(event) => handleChange(event)}
            placeholder={placeholder}
            type="text"
            onFocus={handleClickInput}
            disabled={disabled}
            data-testid="personalAddressSearchInput"
          />
          {clearable && searchValue ? (
            <StClearButton onClick={handleClear}>
              <FontAwesomeIcon icon={['fasr', 'xmark-large']} />
            </StClearButton>
          ) : null}
          {touched && !disabled && (
            <StStatusIcon
              icon={['fasr', error ? 'triangle-exclamation' : 'check']}
              $error={!!error}
            />
          )}
        </StInputFieldContainer>

        {touched && error ? <StError>{error}</StError> : null}

        {open && (
          <StSuggestionsContainer
            ref={refs.setFloating}
            style={floatingStyles}
            data-testid="personalAddressSearchSuggestions"
            $hasLabel={!!label}
          >
            <StSuggestionsContainerHeader>
              {searchSuggestionsHeader}
            </StSuggestionsContainerHeader>
            <StSuggestionsList>
              {fetchingSuggestions ? (
                <Loader />
              ) : (
                <>
                  {suggestions && suggestions.length > 0 ? (
                    suggestions.map((address) => (
                      <AddressSelect
                        key={address.mapbox_id}
                        address={address}
                        onClick={() => {
                          handleSelectAddress(address)
                        }}
                      />
                    ))
                  ) : (
                    <StSuggestionsContainerItem>
                      {t('onboarding.address-search.no-results')}
                    </StSuggestionsContainerItem>
                  )}
                  {suggestions && (
                    <StCreateAddress
                      size="md"
                      type="button"
                      onClick={handleCreateAddress}
                    >
                      {t('onboarding.address-search.create-address')}
                    </StCreateAddress>
                  )}
                </>
              )}
            </StSuggestionsList>
          </StSuggestionsContainer>
        )}
      </StContainer>

      <Formik
        validationSchema={validationSchema}
        initialValues={{
          street: '',
          number: '',
          city: '',
          postcode: '',
        }}
        onSubmit={handleCreateAddressSubmit}
      >
        {({
          handleChange,
          errors,
          values,
          touched,
          handleBlur,
          submitForm,
        }) => (
          <Dialog
            title={t('onboarding.address-search.create-address.title')}
            description={t(
              'onboarding.address-search.create-address.description'
            )}
            open={openAddressCreation}
            onClickCloseButton={() => setOpenAddressCreation(false)}
            primaryButtonText={t(
              'onboarding.address-search.create-address.submit'
            )}
            secondaryButtonText={t(
              'onboarding.address-search.create-address.cancel'
            )}
            onClickPrimaryButton={submitForm}
            onClickSecondaryButton={() => setOpenAddressCreation(false)}
            disableScrollLocking
          >
            <StAddressCreationContainer>
              <Input
                name="street"
                label={t('onboarding.address-search.create-address.street')}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.street}
                touched={touched.street}
                value={values.street}
              />
              <Input
                name="number"
                label={t('onboarding.address-search.create-address.number')}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.number}
                touched={touched.number}
                value={values.number}
              />
              <Input
                name="postcode"
                label={t('onboarding.address-search.create-address.postcode')}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.postcode}
                touched={touched.postcode}
                value={values.postcode}
              />
              <Input
                name="city"
                label={t('onboarding.address-search.create-address.city')}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.city}
                touched={touched.city}
                value={values.city}
              />
            </StAddressCreationContainer>
          </Dialog>
        )}
      </Formik>
    </>
  )
}

const StContainer = styled.div`
  position: relative;
  height: min-content;
`

// const StLabel = styled.label`
//   ${BodyLargeSemiBoldCss}
//   display: block;

//   margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space2};
// `

const StInputFieldContainer = styled.div<{
  $disabled?: boolean
  $sharp?: boolean
  $touched?: boolean
  $error?: boolean
}>`
  height: ${({ theme }) => theme.UI.SpacingPx.Space13};
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  border: ${({ $disabled }) => ($disabled ? 'none' : '1px solid')};
  border-color: ${({ theme, $error, $touched }) =>
    $error
      ? theme.components.input.error
      : $touched
      ? theme.components.input.success
      : theme.components.input.border};

  border-radius: ${({ theme, $sharp }) =>
    $sharp ? theme.UI.SpacingPx.Space1 : theme.UI.SpacingPx.Space2};
  background-color: ${({ theme, $disabled }) =>
    $disabled
      ? theme.components.input['disabled-bg']
      : theme.components.input.bg};
  overflow: hidden;

  &:focus-within {
    box-shadow: 0px 0px 0px 2px
      ${({ theme }) => theme.theme.colors['primary-0']};
    border-color: ${({ theme }) => theme.theme.colors['primary-0']};
  }
`

const StSearchIcon = styled(FontAwesomeIcon)`
  margin-left: ${({ theme }) => theme.UI.SpacingPx.Space5};
  color: ${({ theme }) => theme.theme.colors['nonary-2']};
`

const StInput = styled.input`
  ${BodyMediumRegularCss}

  height: 100%;
  width: 100%;

  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space4};
  padding-right: ${({ theme }) => theme.UI.SpacingPx.Space12};

  border: none;

  text-overflow: ellipsis;

  &:disabled {
    color: ${({ theme }) => theme.components.input['disabled-text']};
  }

  &::placeholder {
    color: ${({ theme }) => theme.components.input['placeholder-text']};
  }

  &:focus {
    outline: none;
  }
`

const StClearButton = styled.button`
  padding: 0;
  margin-right: ${({ theme }) => theme.UI.SpacingPx.Space5};
  background-color: transparent;
  border: none;

  cursor: pointer;

  color: ${({ theme }) => theme.theme.colors['nonary-2']};
`

const StSuggestionsContainer = styled.div<{ $hasLabel?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};

  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space5};

  background-color: ${({ theme }) => theme.theme.colors.white};
  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-9']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};

  box-shadow: ${({ theme }) => theme.shadows.menu};

  overflow-y: scroll;
  overflow-x: hidden;

  z-index: 1000;
`

const StSuggestionsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};

  max-height: 300px;
  overflow-y: auto;

  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space5};
`

const StSuggestionsContainerHeader = styled(BodySmallSemiBold)`
  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space4} ${theme.UI.SpacingPx.Space5}`};
  padding-top: 0;

  border-bottom: 1px solid ${({ theme }) => theme.theme.colors['nonary-9']};
`

const StSuggestionsContainerItem = styled(BodySmallRegular)`
  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space2} ${theme.UI.SpacingPx.Space5}`};
`

const StStatusIcon = styled(FontAwesomeIcon)<{
  $error?: boolean
}>`
  position: absolute;
  right: ${({ theme }) => theme.UI.SpacingPx.Space4};
  top: 50%;
  transform: translateY(-50%);

  margin: auto 0;

  color: ${({ $error, theme }) =>
    $error ? theme.components.input.error : theme.components.input.success};
`

const StCreateAddress = styled(ButtonQuaternary)`
  margin: 0 ${({ theme }) => theme.UI.SpacingPx.Space5};
  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space2};

  text-align: left;
  align-self: flex-start;
`

// Address creation

const StAddressCreationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

  max-width: 100%;
  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space8};

  @media ${breakpoints.mobile} {
    width: 80vw;
    max-width: 764px;
    margin-bottom: 0;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${({ theme }) => theme.UI.SpacingPx.Space6};
    padding-top: ${({ theme }) => theme.UI.SpacingPx.Space10};
  }
`
