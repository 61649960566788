import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import { BodySmallRegular } from '../../../components/typography'

type ChargingSessionInfoBannerProps = {
  title: string
  description: string
  type: 'info' | 'error' | 'warning'
  backgroundColor?: string
  titleColor?: string
}

export const ChargingSessionInfoBanner = ({
  title,
  description,
  type,
}: ChargingSessionInfoBannerProps) => {
  const icon =
    type === 'info'
      ? 'info-circle'
      : type === 'error'
      ? 'exclamation-circle'
      : 'warning'

  return (
    <StBanner $type={type}>
      <StTitle $type={type}>
        <FontAwesomeIcon icon={['fasr', icon]} />
        <BodySmallRegular>{title}</BodySmallRegular>
      </StTitle>
      <StDescription>{description}</StDescription>
    </StBanner>
  )
}

const StBanner = styled.div<{ $type: string }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space4};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  background-color: ${({ theme, $type }) =>
    $type === 'error'
      ? theme.theme.colors['senary-4']
      : theme.theme.colors['nonary-9']};
`

const StTitle = styled.div<{ $type: string }>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
  color: ${({ theme, $type }) => {
    return $type === 'error'
      ? theme.theme.colors.error
      : theme.theme.colors['nonary-0']
  }};
  font-weight: 600;
`

const StDescription = styled(BodySmallRegular)`
  color: ${({ theme }) => theme.theme.text.body.black};
`
