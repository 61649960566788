import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu'
import { styled } from 'styled-components'

import { BodyMediumRegular } from '../typography'

import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import type { PropsWithChildren, ReactNode } from 'react'

export type DropdownMenuItem = {
  name: string
  onClick: () => void
  icon?: IconProp
  show?: () => boolean
  active?: boolean
}

export type DropdownMenuGroup = {
  items: DropdownMenuItem[]
  key: string
}

type DropdownMenuProps = {
  groups: DropdownMenuGroup[]
  topElement?: ReactNode
  open?: boolean
  onOpenChange?: (open: boolean) => void
} & PropsWithChildren

export const DropdownMenu = ({
  groups,
  children,
  topElement,
  open,
  onOpenChange,
}: DropdownMenuProps) => {
  return (
    <RadixDropdownMenu.Root open={open} onOpenChange={onOpenChange}>
      <RadixDropdownMenu.Trigger asChild>{children}</RadixDropdownMenu.Trigger>

      <RadixDropdownMenu.Portal>
        <StDropdownContent
          className="DropdownMenuContent"
          sideOffset={10}
          alignOffset={0}
          align="end"
        >
          {topElement}
          <StDropdownContentContainer>
            {groups.map((group, index: number, array) => (
              <StGroup key={group.key}>
                {group.items.map((option: DropdownMenuItem) =>
                  option.show?.() === false ? null : (
                    <StMenuItem
                      key={option.name}
                      onSelect={() => option.onClick()}
                      $active={option.active}
                    >
                      {option.icon && <FontAwesomeIcon icon={option.icon} />}
                      <BodyMediumRegular>{option.name}</BodyMediumRegular>
                    </StMenuItem>
                  )
                )}

                {index !== array.length - 1 && <StSeparator />}
              </StGroup>
            ))}
          </StDropdownContentContainer>
        </StDropdownContent>
      </RadixDropdownMenu.Portal>
    </RadixDropdownMenu.Root>
  )
}

const StDropdownContent = styled(RadixDropdownMenu.Content)`
  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  background-color: ${({ theme }) => theme.theme.colors.white};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};

  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.08);
`

const StDropdownContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};

  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space3} ${theme.UI.SpacingPx.Space3}`};
`

const StMenuItem = styled(RadixDropdownMenu.Item)<{ $active?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space2} ${theme.UI.SpacingPx.Space3}`};
  cursor: pointer;

  border-radius: 4px;

  ${({ $active, theme }) =>
    $active && `background-color: ${theme.theme.colors['nonary-9']};`}

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.theme.colors['nonary-9']};
    outline: none;
  }
`

const StGroup = styled(RadixDropdownMenu.Group)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

const StSeparator = styled(RadixDropdownMenu.Separator)`
  height: 1px;
  background-color: ${({ theme }) => theme.theme.colors['nonary-7']};
  margin: ${({ theme }) => theme.UI.SpacingPx.Space1} 0;
`
