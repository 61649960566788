export const PoleIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 25 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.3683"
        y="1.55971"
        width="22.1492"
        height="22.9129"
        rx="1.14565"
        fill="#2D2D2D"
        stroke="#2D2D2D"
        strokeWidth="2.29129"
      />
      <path
        d="M6.04571 13.9316L15.1154 5.3392L13.3253 11.2464L18.4569 12.0221L9.38718 20.6145L11.1474 14.7371L6.04571 13.9316Z"
        fill="#DCF730"
      />
      <path
        d="M15.1154 24.0918L15.1154 22.5643L12.0604 22.5643L12.0604 24.0918L15.1154 24.0918ZM12.0604 50.0598C12.0604 50.9034 12.7443 51.5873 13.5879 51.5873C14.4315 51.5873 15.1154 50.9034 15.1154 50.0598L12.0604 50.0598ZM12.0604 24.0918L12.0604 50.0598L15.1154 50.0598L15.1154 24.0918L12.0604 24.0918Z"
        fill="#2D2D2D"
      />
    </svg>
  )
}
