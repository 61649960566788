import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { EventType, useTracking } from '../../../../analytics/hooks/useTracking'
import { Dialog } from '../../../../components/general/Dialog'
import { BodyMediumRegular } from '../../../../components/typography'

import type { UserWithContractAndCards } from 'types'

type FreezeEmployeeDialogProps = {
  open: boolean
  setOpen: (open: boolean) => void
  handleUnfreeze: () => void
  isLoading: boolean
  employee: UserWithContractAndCards
}

export const UnfreezeEmployeeDialog = ({
  open,
  setOpen,
  handleUnfreeze,
  isLoading,
  employee,
}: FreezeEmployeeDialogProps) => {
  // -- Hooks --
  const { t } = useTranslation()
  const { trackEvent } = useTracking()

  // -- Handlers --
  const closeDialog = () => {
    setOpen(false)
    trackEvent(EventType.Close, 'close_unfreeze_member_modal')
  }

  const handleUnfreezeStep = () => {
    handleUnfreeze()
    closeDialog()
  }

  // -- Render --
  return (
    <Dialog
      open={open}
      onClickCloseButton={() => setOpen(false)}
      title={t('admin.employee-detail.freeze-dialog.unfreeze-title', {
        fullName: employee.fullName,
      })}
      primaryButtonText={t('admin.employee-detail.unfreeze-dialog.confirm')}
      secondaryButtonText={t('admin.employee-detail.freeze-dialog.cancel')}
      onClickPrimaryButton={handleUnfreezeStep}
      onClickSecondaryButton={closeDialog}
      disablePrimaryButton={isLoading}
    >
      <StDeleteContent>
        <BodyMediumRegular>
          {t('admin.employee-detail.unfreeze-dialog.confirm-description', {
            fullName: employee.fullName,
          })}
        </BodyMediumRegular>
      </StDeleteContent>
    </Dialog>
  )
}

const StDeleteContent = styled.div`
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space5};

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space10};
`
