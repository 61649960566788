import { useInterval } from 'usehooks-ts'

import { useAxios } from '../../api/hooks/useAxios'
import { Urls } from '../../api/urls'

const POLLING_INTERVAL = 10000

export const useOptimileStatus = () => {
  const [{ error }, refetch] = useAxios({
    url: Urls.OptimileStatus,
  })

  useInterval(refetch, POLLING_INTERVAL)

  return { optimileDown: !!error }
}
