import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { css, styled } from 'styled-components'

import { ToolTipWrapper, ToolTipFollowOptions } from '../general/ToolTipWrapper'
import { BodyExtraSmallRegular, BodyExtraSmallSemiBold } from '../typography'

import type { IconProp } from '@fortawesome/fontawesome-svg-core'

export type FractionElementProps = {
  title: string
  formattedAmount: string
  width: number
  total: number
  backgroundColor?: string
  disabled?: boolean
  icon?: IconProp
  firstElement?: boolean
  lastElement?: boolean
  subText?: string
}

export const FractionElement = ({
  title,
  width,
  total,
  backgroundColor,
  disabled,
  icon,
  firstElement,
  lastElement,
  formattedAmount,
  subText,
}: FractionElementProps) => {
  if (width === 0 || !total) {
    return null
  }

  const toolTipContent = (
    <StToolTip>
      <StToolTipBar $backgroundColor={backgroundColor} />
      <StToolTipInformation>
        <BodyExtraSmallSemiBold>{title}</BodyExtraSmallSemiBold>
        <BodyExtraSmallRegular>{formattedAmount}</BodyExtraSmallRegular>
        <BodyExtraSmallRegular>
          {subText ?? `${width.toFixed(2)}%`}
        </BodyExtraSmallRegular>
      </StToolTipInformation>
    </StToolTip>
  )

  return (
    <StFractionElementContainer $width={width}>
      <ToolTipWrapper
        toolTip={toolTipContent}
        follow={ToolTipFollowOptions.Horizontal}
        placement="bottom"
      >
        <StFractionElement
          $backgroundColor={backgroundColor}
          $width={width}
          $disabled={disabled}
          $first={firstElement}
          $last={lastElement}
        >
          {width > 8 && icon && <FontAwesomeIcon icon={icon} size="sm" />}
        </StFractionElement>
      </ToolTipWrapper>
    </StFractionElementContainer>
  )
}

export const StFractionElementContainer = styled.div<{ $width: number }>`
  width: ${({ $width }) => `${$width}%`};
`

export const StFractionElement = styled.div<{
  $width: number
  $backgroundColor?: string
  $disabled?: boolean
  $first?: boolean
  $last?: boolean
}>`
  height: ${({ theme }) => theme.UI.SpacingPx.Space9};

  display: grid;
  place-items: center;

  background-color: ${({ theme, $backgroundColor }) =>
    $backgroundColor ?? theme.theme.colors['nonary-8']};

  ${({ $first, $width }) =>
    $first &&
    css`
      border-radius: ${() => ($width === 100 ? '4px' : '4px 0 0 4px')};
    `}

  ${({ $last, $width }) =>
    $last &&
    css`
      border-radius: ${() => ($width === 100 ? '4px' : '0 4px 4px 0')};
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      background-color: ${({ theme }) => theme.theme.colors['nonary-8']};
      color: ${({ theme }) => theme.theme.text.body['gray-mid']};
    `}
`

const StToolTip = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
  flex-wrap: nowrap;
`

export const StToolTipBar = styled.div<{ $backgroundColor?: string }>`
  width: ${({ theme }) => theme.UI.SpacingPx.Space1};

  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};
  background-color: ${({ $backgroundColor, theme }) =>
    $backgroundColor ?? theme.theme.colors['nonary-8']};
`

export const StToolTipInformation = styled.div`
  display: flex;
  flex-direction: column;
`
