import { Form, Formik, type FormikProps } from 'formik'
import { useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'
import * as Yup from 'yup'

import { EmployeeRoutes } from '../../../../routing/routes'
import { breakpoints } from '../../../../theme/layout/breakpoints'
import { EventType, useTracking } from '../../../analytics/hooks/useTracking'
import { ButtonPrimary } from '../../../components/button/ButtonPrimary'
import { Input } from '../../../components/form/Input'
import { Select } from '../../../components/form/Select'
import { FormCardHeader } from '../../../components/form-card/FormCardHeader'
import { DetailPageHeader } from '../../../components/general/DetailPageHeader'
import { useAppInfo } from '../../../core/hooks/useAppInfo'
import { shallowEqual } from '../../../core/lib/shallowEqual'
import { useChangeLanguage } from '../../../translations/hooks/useChangeLanguage'
import { useUser } from '../../../user/hooks/useUser'

import type { SupportedLanguage } from '../../../translations/types/Languages'

type Values = {
  language: SupportedLanguage
}

export const EmployeeGeneralSettings = () => {
  // -- State --
  const [editable, setEditable] = useState<boolean>(false)

  // -- Hooks --
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()
  const isDesktop = useMediaQuery(breakpoints.desktop)
  const { user, updateUser, updateLoading } = useUser()
  const { trackEvent } = useTracking()
  const { changeLanguage } = useChangeLanguage()
  const appInfo = useAppInfo()

  const formRef = useRef<FormikProps<Values>>(null)

  const isProduction = import.meta.env.VITE_ENV === 'production'

  const initialValues: Values = {
    language:
      i18n.language === 'cimode' && !isProduction
        ? i18n.language
        : user?.language || 'en',
  }

  const validationSchema = Yup.object().shape({
    language: Yup.string().required(t('employee.settings.language.required')),
  })

  // -- Handlers --
  const handleOpen = () => {
    setEditable(true)
    trackEvent(EventType.Click, `edit_general_settings`)
  }

  const handleClose = () => {
    setEditable(false)
    formRef.current?.resetForm()
    trackEvent(EventType.Click, `cancel_edit_general_settings`)
  }

  const handleSubmit = async (values: { language: SupportedLanguage }) => {
    if (values.language !== 'cimode') {
      try {
        await updateUser({
          language: values.language,
        })
      } catch {
        toast.error(t('employee.settings.general.error'))
      }
    }

    setEditable(false)
    trackEvent(EventType.Submit, `update_general_settings`)

    changeLanguage(values.language)

    formRef.current?.resetForm({
      values: values,
    })
  }

  // -- Render --
  return (
    <StContainer>
      {!isDesktop && (
        <DetailPageHeader
          title={t('employee.settings.general.title')}
          onBack={
            isDesktop
              ? undefined
              : state?.backTo
              ? () => navigate(state.backTo)
              : () => navigate(EmployeeRoutes.Settings)
          }
          actions={
            editable
              ? [
                  {
                    name: 'edit',
                    icon: ['fasr', 'xmark-large'],
                    onClick: () => {
                      handleClose()
                    },
                  },
                ]
              : [
                  {
                    name: 'edit',
                    icon: ['fasr', 'pen'],
                    onClick: () => {
                      handleOpen()
                    },
                  },
                ]
          }
        />
      )}

      {/* Settings Form */}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        innerRef={formRef}
        enableReinitialize
      >
        {({ values, setFieldValue, resetForm }) => (
          <>
            <StForm>
              {isDesktop && (
                <FormCardHeader
                  disableSubmit={shallowEqual(initialValues, values)}
                  handleSetEditable={handleOpen}
                  editable={editable}
                  handleClose={() => {
                    resetForm()
                    handleClose()
                  }}
                  title={t('employee.settings.general.form-title')}
                />
              )}

              <Select
                label={t('employee.settings.general.language')}
                value={values.language}
                onChange={(value) => setFieldValue('language', value)}
                options={
                  [
                    {
                      key: 'nl',
                      label: 'Nederlands',
                    },
                    {
                      key: 'fr',
                      label: 'Français',
                    },
                    {
                      key: 'en',
                      label: 'English',
                    },
                    //  Don't show in production
                    isProduction
                      ? undefined
                      : {
                          key: 'cimode',
                          label: 'Debug mode',
                        },
                  ].filter(Boolean) as { key: string; label: string }[]
                }
                readOnly={!editable}
                disabled={updateLoading}
              />

              {/* Fake input for app version */}
              <Input
                name="app_version"
                onChange={() => {}}
                value={appInfo?.version || 'Web'}
                label={t('employee.settings.general.app-version')}
                readOnly={true}
                disabled={true}
              />

              {editable && !isDesktop && (
                <StMobileSubmitButton
                  compact
                  type="submit"
                  disabled={
                    shallowEqual(initialValues, values) || updateLoading
                  }
                >
                  {t('employee.settings.general.save')}
                </StMobileSubmitButton>
              )}
            </StForm>
          </>
        )}
      </Formik>
    </StContainer>
  )
}

const StContainer = styled.div`
  @media ${breakpoints.desktop} {
    padding-top: ${({ theme }) => theme.UI.SpacingPx.Space6};
  }
`

const StForm = styled(Form)`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

  position: relative;

  /* Extra padding for button height + gap height */
  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space24};

  @media ${breakpoints.desktop} {
    padding: ${({ theme }) => theme.UI.SpacingPx.Space10};
    grid-template-columns: 1fr 1fr;

    border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
    border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
    gap: ${({ theme }) => theme.UI.SpacingPx.Space10};
  }
`

const StMobileSubmitButton = styled(ButtonPrimary)`
  width: calc(100% - ${({ theme }) => theme.UI.SpacingPx.Space12});
  position: fixed;
  bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};
`
