import { useEffect } from 'react'
import { Outlet, Navigate } from 'react-router-dom'

import { useAuthentication } from '../../modules/authentication/hooks/useAuthentication'
import { useSplashScreen } from '../../modules/authentication/hooks/useSplashScreen'
import { EmployeeRoutes } from '../routes'

export const UnauthenticatedGuard = () => {
  const { isAuthenticated, isLoading } = useAuthentication()
  const { hideSplashScreen, translationsAreLoaded } = useSplashScreen()

  // -- Effects --
  useEffect(() => {
    if (!isLoading && translationsAreLoaded) {
      hideSplashScreen()
    }
  }, [isLoading, hideSplashScreen, translationsAreLoaded])

  if (isLoading) {
    return null
  }

  return isAuthenticated ? (
    <Navigate to={EmployeeRoutes.Root} replace />
  ) : (
    <Outlet />
  )
}
