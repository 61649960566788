import ContentLoader from 'react-content-loader'

export const UsageStatisticsLoader = ({
  amountOfStats = 4,
}: {
  amountOfStats?: 3 | 4
}) => {
  return (
    <ContentLoader
      speed={2}
      height={235}
      viewBox="0 0 1260 235"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0%" y="0" rx="8" ry="8" width="10.89%" height="87" />
      <rect x="14.11%" y="0" rx="8" ry="8" width="10.89%" height="87" />
      <rect x="28.23%" y="0" rx="8" ry="8" width="10.89%" height="87" />
      {amountOfStats > 3 && (
        <rect x="42.34%" y="0" rx="8" ry="8" width="10.89%" height="87" />
      )}
      <rect x="0%" y="111" rx="8" ry="8" width="32.26%" height="124" />
      <rect x="33.87%" y="111" rx="8" ry="8" width="32.26%" height="124" />
      <rect x="67.74%" y="111" rx="8" ry="8" width="32.26%" height="124" />
    </ContentLoader>
  )
}
