import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { useAuthMemberAxios } from '../../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../../api/urls'
import { TextArea } from '../../../../components/form/TextArea'
import { FloatingContainerButton } from '../../components/FloatingContainerButton'
import { OnboardingStepTitle } from '../../components/OnboardingStepTitle'
import { useOnboarding } from '../../hooks/useOnboarding'

export const ExtraCommentStep = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const { handleNext, setLoading, values: onboardingValues } = useOnboarding()

  // -- Data --
  const [, execute] = useAuthMemberAxios(
    {
      url: Urls.OnboardingData,
      method: 'POST',
    },
    {
      manual: true,
    }
  )

  // -- Vars --
  const validationSchema = Yup.object().shape({
    extraComment: Yup.string()
      .max(750, t('onboarding.extra-comment.error'))
      .optional(),
  })

  // -- Handlers --
  const handleSubmit = async ({ extraComment }: { extraComment: string }) => {
    try {
      setLoading(true)

      await execute({
        data: {
          extraComment,
          skippedExtraComment: extraComment === '',
        },
      })

      handleNext({
        ...onboardingValues,
        extraComment,
        skippedExtraComment: extraComment === '',
      })
    } catch {
      setLoading(false)
      return console.error('Failed to save extra comments')
    }
  }

  // -- Render --
  return (
    <div>
      <OnboardingStepTitle>
        {t('onboarding.extra-comment.title')}
      </OnboardingStepTitle>

      <Formik
        initialValues={{ extraComment: onboardingValues.extraComment ?? '' }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnBlur
      >
        {({ errors, touched, values, handleChange, handleBlur }) => (
          <Form>
            <TextArea
              name="extraComment"
              testId="extraComment"
              label={t('onboarding.extra-comment.label')}
              value={values.extraComment}
              error={errors.extraComment}
              touched={touched.extraComment}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <FloatingContainerButton
              title={t('onboarding.account.submit')}
              disabled={!!errors.extraComment}
            />
          </Form>
        )}
      </Formik>
    </div>
  )
}
