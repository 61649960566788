import { Trans, useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { Spinner } from '../../../../components/general/Spinner'
import { BodyExtraSmallRegular } from '../../../../components/typography'

type AverageCostBreakdownProps = {
  loading: boolean
  withHcpAverage: string
  withoutHcpAverage: string
  amountWithHcp: number
  amountWithoutHcp: number
}

export const AverageCostBreakdown = ({
  loading,
  withHcpAverage,
  withoutHcpAverage,
  amountWithHcp,
  amountWithoutHcp,
}: AverageCostBreakdownProps) => {
  const { t } = useTranslation()

  if (loading) {
    return (
      <StContainer>
        <StToolTipBar />
        <StLoader>
          <Spinner />
        </StLoader>
      </StContainer>
    )
  }

  return (
    <StContainer>
      <StToolTipBar />
      <StBreakdownContainer>
        <BodyExtraSmallRegular>
          <Trans
            t={t}
            i18nKey="employer.chargingSessions.costBreakdown.withHcp"
            values={{
              amount: amountWithHcp,
              average: withHcpAverage,
            }}
            components={{
              string: <strong />,
            }}
          />
        </BodyExtraSmallRegular>

        <BodyExtraSmallRegular>
          <Trans
            t={t}
            i18nKey="employer.chargingSessions.costBreakdown.withoutHcp"
            values={{
              amount: amountWithoutHcp,
              average: withoutHcpAverage,
            }}
            components={{
              string: <strong />,
            }}
          />
        </BodyExtraSmallRegular>
      </StBreakdownContainer>
    </StContainer>
  )
}

const StContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};

  min-height: 30px;
`

const StBreakdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

const StToolTipBar = styled.div<{ $backgroundColor?: string }>`
  width: ${({ theme }) => theme.UI.SpacingPx.Space1};

  flex-shrink: 0;

  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};
  background-color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`

const StLoader = styled.div`
  display: grid;
  place-items: center;
`
