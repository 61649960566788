import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as RadixAccordion from '@radix-ui/react-accordion'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import {
  BodyExtraSmallRegular,
  BodyMediumRegular,
  BodyMediumSemiBold,
} from '../../../components/typography'
import { useMember } from '../../../member/hooks/useMember'
import { useUser } from '../../../user/hooks/useUser'

type MemberSwitcherProps = {
  onCompanySelect?: () => void
}

export const MemberSwitcher = ({ onCompanySelect }: MemberSwitcherProps) => {
  const { user } = useUser()
  const {
    currentMember,
    setCurrentMember,
    members,
    isCurrentMemberSuperAdmin,
    companies,
  } = useMember()
  const { t } = useTranslation()

  // Get available companies based on user role
  const availableCompanies = useMemo(() => {
    if (isCurrentMemberSuperAdmin) {
      return companies ?? []
    }

    // For regular users, get unique companies from their memberships
    return (members ?? []).reduce<Array<(typeof members)[0]['company']>>(
      (accumulator, member) => {
        if (!member.company) return accumulator

        const companyExists = accumulator.some(
          (company) => company?.id === member.company?.id
        )

        if (!companyExists) {
          accumulator.push(member.company)
        }

        return accumulator
      },
      []
    )
  }, [isCurrentMemberSuperAdmin, companies, members])

  const loadCompany = (companyId: string) => {
    if (isCurrentMemberSuperAdmin) {
      setCurrentMember(currentMember.id, companyId)
    } else {
      const member = members.find((m) => m.companyId === companyId)
      if (member) {
        setCurrentMember(member.id)
      }
    }
    onCompanySelect?.()
  }

  if (!availableCompanies?.length) {
    return null
  }

  return (
    <StContainer>
      <StMemberTitle>{user?.fullName}</StMemberTitle>
      <RadixAccordion.Root
        type="single"
        disabled={availableCompanies.length === 1}
      >
        <RadixAccordion.Item value="members">
          <StCurrentMember>
            <BodyMediumRegular>{currentMember.company.name}</BodyMediumRegular>
            <StSwitcherIndication>
              <FontAwesomeIcon icon={['fass', 'arrows-repeat']} size="sm" />
              <BodyExtraSmallRegular>
                {t('employer.profile.switch_company')}
              </BodyExtraSmallRegular>
            </StSwitcherIndication>
          </StCurrentMember>
          <StAccordionContent className="AccordionContent">
            {availableCompanies
              .filter((company) => company.id !== currentMember.company.id)
              .map((company) => (
                <StMember
                  key={company.id}
                  onClick={() => loadCompany(company.id)}
                >
                  <BodyMediumRegular title={company.name}>
                    {company.name}
                  </BodyMediumRegular>
                  <StSwitcherIndication>
                    <BodyExtraSmallRegular>
                      {t('employer.profile.switch_to_company')}
                    </BodyExtraSmallRegular>
                  </StSwitcherIndication>
                </StMember>
              ))}
          </StAccordionContent>
        </RadixAccordion.Item>
      </RadixAccordion.Root>
    </StContainer>
  )
}

const StMemberTitle = styled(BodyMediumSemiBold)`
  padding: ${({ theme }) => `0 ${theme.UI.SpacingPx.Space7}`};
`

const StContainer = styled.div`
  width: 400px;
  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space6};
  border-bottom: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
`

const StSwitcherIndication = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;

  color: ${({ theme }) => theme.theme.colors['secondary-1']};

  height: ${({ theme }) => theme.UI.SpacingPx.Space7};
  border-radius: 9999px;

  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space3};

  background-color: ${({ theme }) => theme.theme.colors['secondary-5']};
`

const StCurrentMember = styled(RadixAccordion.Trigger)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};

  width: 100%;
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space7};
  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space1};

  background-color: transparent;
  color: ${({ theme }) => theme.theme.text.body.black};
  border: none;
  min-height: ${({ theme }) => theme.UI.SpacingPx.Space8};

  cursor: pointer;

  > p {
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
  }

  &[data-state='open'],
  &[data-disabled] {
    cursor: default;

    ${StSwitcherIndication} {
      display: none;
    }
  }

  &:hover ${StSwitcherIndication} {
    background-color: ${({ theme }) => theme.theme.colors['secondary-4']};
  }
`
const StAccordionContent = styled(RadixAccordion.Content)`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `0px ${theme.UI.SpacingPx.Space7}`};
  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space5};
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space1};
  max-height: 350px;

  &[data-state='open'] {
    margin-top: ${({ theme }) => theme.UI.SpacingPx.Space2};
  }

  // Sets higher priority.
  &.AccordionContent {
    overflow-y: auto;
  }

  // Sets higher priority.
  &.AccordionContent {
    overflow-y: auto;
  }
`

const StMember = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  min-height: 28px;
  cursor: pointer;
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};

  > p {
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  ${StSwitcherIndication} {
    display: none;
  }

  &:hover {
    color: ${({ theme }) => theme.theme.text.body.black};

    ${StSwitcherIndication} {
      display: flex;
    }
  }
`
