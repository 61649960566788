import { styled } from 'styled-components'

import { BodySmallRegular } from '../typography'

import {
  FractionElement,
  StFractionElementContainer,
  type FractionElementProps,
  StFractionElement,
} from './FractionElement'

type FractionsProps = {
  data: FractionElementProps[]
  disabled?: boolean
  disabledMessage?: string
}

export const Fractions = ({
  data,
  disabled,
  disabledMessage,
}: FractionsProps) => {
  return (
    <StFractionsContainer>
      {disabled ? (
        <StFractionElementContainer $width={100}>
          <StFractionElement $width={100} $disabled $first={true} $last={true}>
            {disabledMessage && (
              <BodySmallRegular>{disabledMessage}</BodySmallRegular>
            )}
          </StFractionElement>
        </StFractionElementContainer>
      ) : (
        data.map((item, index) => (
          <FractionElement
            key={index}
            {...item}
            firstElement={index === 0}
            lastElement={data.length - 1 === index}
          />
        ))
      )}
    </StFractionsContainer>
  )
}
const StFractionsContainer = styled.div`
  display: flex;
  gap: 2px;
`
