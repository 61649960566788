import { RemoveScroll } from 'react-remove-scroll'

import { Skeleton } from '../../../components/Skeleton'

export function ReimbursementsDataListItemSkeleton() {
  return (
    <RemoveScroll>
      <Skeleton height={80} />
    </RemoveScroll>
  )
}
