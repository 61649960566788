import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'

import { EmployerRoutes } from '../../../../routing/routes'
import { useAuthentication } from '../../../authentication/hooks/useAuthentication'
import {
  DropdownMenu,
  type DropdownMenuGroup,
} from '../../../components/general/DropdownMenu'
import { useMember } from '../../../member/hooks/useMember'

import { MemberSwitcher } from './MemberSwitcher'

export const EmployerProfileMenu = () => {
  const { logout } = useAuthentication()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { currentMember } = useMember()
  const [isOpen, setIsOpen] = useState(false)

  const menuGroups: DropdownMenuGroup[] = [
    {
      key: 'profile',
      items: [
        {
          name: t('employer.settings.title'),
          onClick: () => navigate(EmployerRoutes.Settings),
          icon: ['fasr', 'cog'],
        },
        {
          name: t('employer.profile.privacy.title'),
          onClick: () => navigate(EmployerRoutes.Privacy),
          icon: ['fasr', 'lock'],
        },
        {
          name: t('employer.profile.logout'),
          onClick: () => logout(),
          icon: ['fasr', 'arrow-right-from-bracket'],
        },
      ],
    },
  ]

  return (
    <DropdownMenu
      groups={menuGroups}
      topElement={<MemberSwitcher onCompanySelect={() => setIsOpen(false)} />}
      key={currentMember?.id}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      <StTrigger>
        <FontAwesomeIcon
          data-state="closed"
          icon={['fasr', 'circle-user']}
          size="lg"
        />
        <FontAwesomeIcon
          data-state="open"
          icon={['fass', 'circle-user']}
          size="lg"
        />
      </StTrigger>
    </DropdownMenu>
  )
}

const StTrigger = styled.button`
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;

  display: grid;
  place-items: center;

  svg[data-state='open'] {
    display: none;
  }

  svg[data-state='closed'] {
    display: block;
  }

  &[data-state='open'] {
    svg[data-state='open'] {
      display: block;
    }

    svg[data-state='closed'] {
      display: none;
    }
  }
`
