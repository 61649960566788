import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import { useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import { formatDateRange } from 'utils/src'

import { EventType, useTracking } from '../../../../analytics/hooks/useTracking'
import { useAuthMemberAxios } from '../../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../../api/urls'
import { ButtonSecondary } from '../../../../components/button/ButtonSecondary'
import { FilterTypes } from '../../../../components/filters/Filters'
import { StatisticCard } from '../../../../components/general/StatisticCard'
import { BodyExtraSmallRegular, H4 } from '../../../../components/typography'
import { useGetDateFnsLocale } from '../../../../core/hooks/useGetDateFnsLocale'
import { downloadBlob } from '../../../../core/lib/downloadBlob'
import {
  ColumnType,
  DataTable,
} from '../../../../dataTable/components/DataTable'
import { useDataTable } from '../../../../dataTable/hooks/useDataTable'
import { Filters } from '../../../../employee/onboarding/components/Filters'
import { formatIntervalInDuration } from '../../../../translations/utils/date'
import { useUser } from '../../../../user/hooks/useUser'
import { ChargingSessionTypeLabel } from '../../../usage/components/ChargingSessionTypeLabel'
import { ChargingSpeedFractions } from '../../../usage/components/ChargingSpeedFractions'
import { CostPerTypeFractions } from '../../../usage/components/CostPerTypeFractions'
import { UsageFractions } from '../../../usage/components/UsageFractions'
import { UsageStatisticsLoader } from '../../../usage/components/UsageStatisticsLoader'
import { ChargingSessionType } from '../../../usage/types/types'
import {
  formatCurrency,
  formatPowerConsumption,
} from '../../../utils/statistics'

import type { Filter } from '../../../../components/filters/Filters'
import type { Column } from '../../../../dataTable/components/DataTable'
import type { ChargingSessionStatistics } from '../../../usage/types/types'
import type { ChargingSession } from 'database'
import type { ChargingSessionWithDetectedAnomalies } from 'types'

type EmployeeDetailUsageDataTableProps = {
  employeeId: string
  onRowClick?: (session: ChargingSessionWithDetectedAnomalies) => void
}

export const EmployeeDetailUsageDataTable = ({
  employeeId,
  onRowClick,
}: EmployeeDetailUsageDataTableProps) => {
  // -- Hooks --
  const { t } = useTranslation()
  const { trackEvent } = useTracking()
  const dateFnsLocale = useGetDateFnsLocale()
  const {
    statistics,
    filterValues,
    setFilterValues,
    clearFilters,
    initialLoading,
    tableData,
  } = useDataTable<ChargingSessionStatistics>()
  const { user } = useUser()

  // -- State --
  const [exportLoading, setExportLoading] = useState(false)

  // -- Vars --
  const dateLabel =
    filterValues.dateFrom && filterValues.dateTo
      ? formatDateRange(
          {
            start: filterValues.dateFrom as Date,
            end: filterValues.dateTo as Date,
          },
          dateFnsLocale
        )
      : undefined

  const columns = useMemo<Column<ChargingSessionWithDetectedAnomalies>[]>(
    () => [
      {
        key: 'start',
        label: t('employer.chargingSessions.date'),
        type: ColumnType.DATE,
        sortable: true,
      },

      {
        key: 'location',
        label: t('employer.chargingSessions.location'),
        type: ColumnType.STRING,
        transformData: (data: ChargingSessionWithDetectedAnomalies) => {
          const hasIllegalRegionAnomaly = data.anomalies?.some(
            (anomaly) => anomaly.type === 'IllegalRegion'
          )

          return (
            <StLocationContainer>
              <ChargingSessionTypeLabel session={data} />
              {hasIllegalRegionAnomaly && (
                <StErrorCircle
                  title={t('employer.anomalies.table.illegal_region')}
                >
                  <FontAwesomeIcon
                    icon={['fasr', 'circle-exclamation']}
                    fontSize={15}
                  />
                </StErrorCircle>
              )}
            </StLocationContainer>
          )
        },
        sortable: true,
      },
      {
        key: 'duration',
        label: t('employer.chargingSessions.duration'),
        type: ColumnType.STRING,
        transformData: (data: ChargingSession) => {
          if (!data.start || !data.end) {
            return '–'
          }

          const result =
            formatIntervalInDuration(
              new Date(data.start),
              new Date(data.end),
              t
            ) ?? '-'

          return result === '' ? null : result
        },
        sortable: true,
      },
      {
        key: 'costPerKwh',
        label: t('employer.chargingSessions.cost-per-kwh'),
        type: ColumnType.STRING,
        sortable: true,
        transformData: (data: ChargingSession) => {
          const cost =
            data.type === ChargingSessionType.HOME
              ? data.hcpPrice
              : data.mspPrice

          if (cost === 0 || data.kwh === 0) {
            return formatCurrency(0, user.language)
          }

          return formatCurrency(cost / data.kwh, user.language)
        },
      },
      {
        key: 'kwh',
        label: t('employer.chargingSessions.charged'),
        type: ColumnType.STRING,
        sortable: true,
        transformData: (data: ChargingSession) => {
          return formatPowerConsumption(data.kwh ?? 0, 2, user.language)
        },
      },
      {
        key: 'mspPrice',
        label: t('employer.chargingSessions.cost'),
        prepend: '€',
        type: ColumnType.NUMBER,
        decimal: 2,
        sortable: true,
        transformData: (chargingSession: ChargingSession) => {
          return chargingSession.type === ChargingSessionType.HOME
            ? chargingSession.hcpPrice
            : chargingSession.mspPrice
        },
      },
    ],
    [t, user.language]
  )

  const filters = useMemo<Filter[]>(
    () => [
      {
        key: 'chargingSessionType',
        placeholder: t('employer.chargingSessions.location'),
        type: FilterTypes.Multi,
        options: Object.values(ChargingSessionType).map((type) => {
          return {
            key: type,
            label: t(`employer.chargingSessions.type.${type}`),
          }
        }),
      },
      {
        key: 'dateRange',
        type: FilterTypes.DateRange,
      },
    ],
    [t]
  )

  // -- Data --
  const [, exportSessions] = useAuthMemberAxios(
    {
      url: Urls.employeeChargingSessionsExport,
      method: 'POST',
      params: {
        filters: {
          ...filterValues,
        },
        employeeId,
      },
      responseType: 'blob',
    },
    { manual: true }
  )

  // -- Handlers --

  const handleExportSessions = async () => {
    try {
      trackEvent(EventType.Click, 'export_overview')
      setExportLoading(true)
      const response = await exportSessions()

      // Save file
      downloadBlob(
        response.data,
        t('employer.chargingSessions.title').toLocaleLowerCase() +
          format(new Date(), '_dd_MM_yyyy-HH_mm'),
        '.xlsx'
      )
    } catch {
      toast.error(t('employer.chargingSessions.export.error'))
    }

    setExportLoading(false)
  }

  const handleRowClick = (row: ChargingSessionWithDetectedAnomalies) => {
    if (onRowClick) {
      onRowClick(row)
    }
  }

  return (
    <StContainer>
      <StHeader>
        <H4>{t('admin.employee-detail.usage.title')}</H4>
        <Filters
          key={'usage-data-table-filters'}
          filters={filters}
          filterValues={filterValues}
          onFiltersChange={(filterKey, value) =>
            setFilterValues((oldValues) => ({
              ...oldValues,
              [filterKey]: value,
            }))
          }
          onClearFilters={() => {
            clearFilters()
          }}
          loading={initialLoading && !tableData}
          align="right"
        >
          <div>
            <ButtonSecondary
              icon={['fass', 'arrow-down-to-line']}
              iconAlignment="left"
              loading={exportLoading}
              onClick={handleExportSessions}
            >
              {t('employer.chargingSessions.export')}
            </ButtonSecondary>
          </div>
        </Filters>
      </StHeader>
      {/* Statistics */}
      {statistics ? (
        <StStatisticsContainer>
          {/* Statistics values */}
          <StStatisticsRow>
            <StStatistic>
              <StStatisticLabel>
                {t('employer.usage.statistics.totalUsage')}
              </StStatisticLabel>
              <H4>
                {formatPowerConsumption(
                  statistics?.totalUsage ?? 0,
                  2,
                  user.language
                )}
              </H4>
              <StStatisticLabel>{dateLabel}</StStatisticLabel>
            </StStatistic>
            <StStatistic>
              <StStatisticLabel>
                {t('employer.usage.statistics.totalCost')}
              </StStatisticLabel>
              <H4>
                {formatCurrency(statistics?.totalCost ?? 0, user.language)}
              </H4>
              <StStatisticLabel>{dateLabel}</StStatisticLabel>
            </StStatistic>
            <StStatistic>
              <StStatisticLabel>
                {t('employer.usage.statistics.averageCostPerKwh')}
              </StStatisticLabel>
              <H4>
                {formatCurrency(
                  (statistics?.totalCost ?? 0) / (statistics?.totalUsage ?? 1),
                  user.language
                )}
              </H4>
              <StStatisticLabel>{dateLabel}</StStatisticLabel>
            </StStatistic>
          </StStatisticsRow>

          {/* Statistic fractions */}

          <StStatistics>
            <StatisticCard
              title={t('employer.usage.statistics.chargingBreakdown')}
              subTitle={dateLabel}
            >
              <UsageFractions statistics={statistics} />
            </StatisticCard>
            <StatisticCard
              title={t('employer.usage.statistics.chargingSpeed')}
              subTitle={dateLabel}
            >
              <ChargingSpeedFractions statistics={statistics} />
            </StatisticCard>
            <StatisticCard
              title={t('employer.usage.statistics.extraCosts')}
              subTitle={dateLabel}
            >
              <CostPerTypeFractions statistics={statistics} />
            </StatisticCard>
          </StStatistics>
        </StStatisticsContainer>
      ) : initialLoading ? (
        <UsageStatisticsLoader amountOfStats={3} />
      ) : undefined}

      {/* DataTable */}
      <DataTable<ChargingSessionWithDetectedAnomalies>
        columns={columns}
        emptyFiltersTitle={t(
          'admin.employee-detail.usage.table.empty-filters-title'
        )}
        emptyTitle={t('admin.employee-detail.usage.table.empty-title')}
        emptyFiltersDescription={t(
          'admin.employee-detail.usage.table.empty-filters-description'
        )}
        emptyDescription={t(
          'admin.employee-detail.usage.table.empty-description'
        )}
        onRowClick={handleRowClick}
      />
    </StContainer>
  )
}

const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space10};

  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space10};

  padding-bottom: 70px;
`

const StHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const StStatisticsRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space10};
`

const StStatistic = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StStatisticLabel = styled(BodyExtraSmallRegular)`
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`

const StStatistics = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

  > * {
    flex: 1 0 0;
  }
`

const StStatisticsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};
`

const StLocationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StErrorCircle = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: ${({ theme }) => theme.theme.colors.error};
`
