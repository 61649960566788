import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import { ToolTipWrapper } from '../general/ToolTipWrapper'

import type { Placement } from '@floating-ui/react'
import type { ReactNode } from 'react'

type InfoPopupProps = {
  content: ReactNode
  theme?: 'light' | 'dark'
  placement?: Placement
}

export const InfoPopup = ({
  content,
  theme = 'dark',
  placement = 'bottom-end',
}: InfoPopupProps) => {
  return (
    <ToolTipWrapper toolTip={content} placement={placement} theme={theme}>
      <StIcon>
        <FontAwesomeIcon icon={['fass', 'info-circle']} />
      </StIcon>
    </ToolTipWrapper>
  )
}

const StIcon = styled.div`
  color: ${({ theme }) => theme.theme.colors['secondary-1']};
  background-color: transparent;
  border: none;
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space1};
  cursor: pointer;
`
