import { useTranslation } from 'react-i18next'
import { useIntercom } from 'react-use-intercom'
import { styled } from 'styled-components'

import { ButtonPrimary } from '../../../../components/button/ButtonPrimary'
import { IntercomCard } from '../../../../components/intercom/IntercomCard'
import {
  BodyLargeSemiBold,
  BodySmallRegular,
} from '../../../../components/typography'
import { OnboardingStepTitle } from '../../components/OnboardingStepTitle'

export const EnergyContractOwnerNotImplementedStep = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const { show } = useIntercom()

  return (
    <StContainer>
      <StOnboardingStepTitle>
        {t('onboarding.energyContractOwnerNotImplemented.title')}
      </StOnboardingStepTitle>
      <StCardContainer>
        <StExplanationCard>
          <BodyLargeSemiBold>
            {t('onboarding.energyContractOwnerNotImplemented.sub-title')}
          </BodyLargeSemiBold>
          <BodySmallRegular>
            {t('onboarding.energyContractOwnerNotImplemented.description')}
          </BodySmallRegular>
          <ButtonPrimary
            size="sm"
            icon={['fass', 'paper-plane-top']}
            compact
            onClick={show}
          >
            {t('onboarding.energyContractOwnerNotImplemented.cta')}
          </ButtonPrimary>
        </StExplanationCard>
        <IntercomCard
          title={t(
            'onboarding.energyContractOwnerNotImplemented.intercom-card.title'
          )}
          subtitle={t(
            'onboarding.energyContractOwnerNotImplemented.intercom-card.subtitle'
          )}
        />
      </StCardContainer>
    </StContainer>
  )
}

const StContainer = styled.div`
  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space5};
`

const StOnboardingStepTitle = styled(OnboardingStepTitle)`
  max-width: 50%;
`

const StCardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space10};
`

const StExplanationCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};

  padding: ${({ theme }) => theme.UI.SpacingPx.Space10};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
`
