import {
  addMinutes,
  differenceInMinutes,
  eachDayOfInterval,
  eachHourOfInterval,
  eachMonthOfInterval,
  endOfDay,
  format,
  getMinutes,
  isEqual,
  isFuture,
  isSameDay,
  isSameMonth,
  isToday,
  setMinutes,
  startOfDay,
} from 'date-fns'

import { DateRangeMode } from '../../employee/hems/components/UsageDetails'

import type { Chart, ChartArea, ScriptableLineSegmentContext } from 'chart.js'
import type { DefaultTheme } from 'styled-components/dist/types'

function eachFifteenMinutesOfInterval({
  start,
  end,
}: {
  start: Date
  end: Date
}) {
  // Round to the nearest 15
  let current = setMinutes(start, Math.ceil(getMinutes(start) / 15) * 15)
  const group = []

  while (end > current) {
    group.push(current)
    current = addMinutes(current, 15)
  }

  return group
}

export const formatData = (
  dateRange: { start: Date; end: Date },
  dateRangeMode: DateRangeMode,
  data: { label: string; value: number }[],
  t: (key: string) => string
): { timestamp: Date; label: string; value?: number }[] => {
  // Store array of all hours inside the day
  const dayInterval = eachFifteenMinutesOfInterval(dateRange)

  const monthInterval = eachDayOfInterval(dateRange)

  const yearInterval = eachMonthOfInterval(dateRange)

  switch (dateRangeMode) {
    case DateRangeMode.DAY: {
      return dayInterval.map((timestamp) => {
        const matchingData = data.find((dataObject) =>
          isEqual(new Date(dataObject.label), timestamp)
        )

        return {
          timestamp,
          label: format(timestamp, 'HH') + t('common.hour-suffix'),
          value: isFuture(timestamp) ? undefined : matchingData?.value ?? 0,
        }
      })
    }

    case DateRangeMode.MONTH: {
      return monthInterval.map((timestamp) => {
        const matchingData = data.find((dataObject) =>
          isSameDay(new Date(dataObject.label), timestamp)
        )

        return {
          timestamp,
          label: format(timestamp, 'd'),
          value: isFuture(timestamp) ? undefined : matchingData?.value ?? 0,
        }
      })
    }

    default:
    case DateRangeMode.YEAR: {
      return yearInterval.map((timestamp) => {
        const matchingData = data.find((dataObject) =>
          isSameMonth(new Date(dataObject.label), timestamp)
        )

        return {
          timestamp,
          label: format(timestamp, 'MMM'),
          value: isFuture(timestamp) ? undefined : matchingData?.value ?? 0,
        }
      })
    }
  }
}

export const dynamicFormatLabels = (labels: string[], raw = false) => {
  // Detect interval between label timestamps (minutes)
  // Fill 24h with those timestamps

  const interval = differenceInMinutes(new Date(labels[1]), new Date(labels[0]))

  const amountOfLabels = (24 * 60) / interval

  return Array.from({ length: amountOfLabels }, (_, index) => {
    const date = startOfDay(new Date(labels[0]))

    date.setMinutes(index * interval)

    return raw ? date.toISOString() : format(date, 'HH:mm')
  })
}

export const spreadDataOverDay = (
  data: { label: string; value: number }[],
  fillInterval = false,
  perQuarterHour = false
) => {
  let resultingData: (number | undefined)[] = []

  if (data.length === 0) {
    return []
  }

  const resultingDates = (
    perQuarterHour ? eachFifteenMinutesOfInterval : eachHourOfInterval
  )({
    start: startOfDay(new Date(data[0].label)),
    end: endOfDay(new Date(data[0].label)),
  })

  resultingData = resultingDates.map((d) => {
    const matchingData = data.find((dataObject) =>
      isEqual(new Date(dataObject.label), d)
    )

    return matchingData?.value
  })

  // Get last value in the array, there can be undefined values in between
  const lastValueIndex =
    fillInterval && !isToday(new Date(data[0].label))
      ? resultingData.length
      : resultingData.reduce(
          (accumulator, current, index) => (current ? index : accumulator),
          0
        )

  resultingData = resultingData.map((value, index) =>
    index <= (lastValueIndex ?? 0)
      ? value === undefined
        ? 0
        : value
      : undefined
  )

  return resultingData
}

export const formatTooltip = (
  dateRangeMode: DateRangeMode,
  t: (key: string) => string,
  timestamp?: Date
) => {
  if (timestamp === undefined) {
    return ''
  }

  switch (dateRangeMode) {
    case DateRangeMode.DAY: {
      return format(timestamp, 'H:mm') + t('common.hour-suffix')
    }

    case DateRangeMode.MONTH: {
      return format(timestamp, 'd MMM yyyy')
    }

    case DateRangeMode.YEAR: {
      return format(timestamp, 'MMM yyyy')
    }

    default: {
      return timestamp.toString()
    }
  }
}

export const drawBarChartTooltip = (
  context: CanvasRenderingContext2D,
  chartArea: ChartArea,
  x: number,
  top: number,
  bottom: number,
  theme: DefaultTheme,
  tooltipText: string
) => {
  // Draw vertical line
  context.save()
  context.beginPath()

  // Draw first part
  context.moveTo(x, 32)
  context.lineTo(x, top - 2)

  // Draw second part
  if (bottom > 0) {
    context.moveTo(x, bottom + 2)
    context.lineTo(x, chartArea.bottom)
  }

  context.lineWidth = chartArea.width > 500 ? 2 : 1
  context.strokeStyle = '#A5ABB6'

  context.stroke()
  context.restore()

  /// lets save current state as we make a lot of changes
  context.save()

  /// set font
  const fontSize = 12
  const font = `${fontSize}px F37 Jagger`
  context.font = font

  /// draw text from top - makes life easier at the moment
  context.textBaseline = 'top'

  /// color for background
  context.fillStyle = theme.theme.colors['primary-3']
  context.strokeStyle = theme.theme.colors['primary-3']

  /// get width of text
  const width = context.measureText(tooltipText).width
  const height = 24
  const paddingX = 8

  const fullWidth = width + paddingX * 2

  /// draw background rect assuming height of font
  const hasSpaceOnLeft = x - fullWidth / 2 > 0
  const hasSpaceOnRight = x + fullWidth / 2 < chartArea.right

  context.beginPath()
  context.roundRect(
    hasSpaceOnLeft
      ? hasSpaceOnRight
        ? x - fullWidth / 2
        : chartArea.right - fullWidth
      : 0,
    8,
    fullWidth,
    height,
    4
  )
  context.stroke()
  context.fill()

  /// text color
  context.fillStyle = theme.theme.colors.black

  // Draw text on top centered vertically
  // Take into account different browser behaviour
  const offsets = getTextOffsets(font)

  context.fillText(
    tooltipText,
    (hasSpaceOnLeft
      ? hasSpaceOnRight
        ? x - width / 2
        : chartArea.right - (width + 8)
      : 0 + 8) + offsets.x,
    8 + (height - fontSize) / 2 + offsets.y
  )
  /// restore original state
  context.restore()
}

export const drawLineChartTooltip = (
  context: CanvasRenderingContext2D,
  chartArea: ChartArea,
  x: number,
  theme: DefaultTheme,
  dots: { x: number; y: number }[],
  tooltipText: string
) => {
  // Draw vertical line
  context.save()
  context.beginPath()

  // Draw dots
  dots.forEach((dot) => {
    context.moveTo(dot.x, dot.y)
    context.arc(dot.x, dot.y, 3, 0, Math.PI * 2)
  })

  // Draw first part
  context.moveTo(x, 32)
  context.lineTo(x, chartArea.bottom)

  context.lineWidth = 1
  context.strokeStyle = '#A5ABB6'
  context.fillStyle = '#A5ABB6'

  context.stroke()
  context.fill()
  context.restore()

  /// lets save current state as we make a lot of changes
  context.save()

  /// set font
  const fontSize = 12
  const font = `${fontSize}px F37 Jagger`
  context.font = font

  /// draw text from top - makes life easier at the moment
  context.textBaseline = 'top'

  /// color for background
  context.fillStyle = theme.theme.colors['primary-3']
  context.strokeStyle = theme.theme.colors['primary-3']

  /// get width of text
  const width = context.measureText(tooltipText).width
  const height = 24
  const paddingX = 8

  const fullWidth = width + paddingX * 2

  /// draw background rect assuming height of font
  const hasSpaceOnLeft = x - fullWidth / 2 > 0
  const hasSpaceOnRight = x + fullWidth / 2 < chartArea.right

  context.beginPath()
  context.roundRect(
    hasSpaceOnLeft
      ? hasSpaceOnRight
        ? x - fullWidth / 2
        : chartArea.right - fullWidth
      : 0,
    8,
    fullWidth,
    height,
    4
  )
  context.stroke()
  context.fill()

  /// text color
  context.fillStyle = theme.theme.colors.black

  // Draw text on top centered vertically
  // Take into account different browser behaviour
  const offsets = getTextOffsets(font)

  context.fillText(
    tooltipText,
    (hasSpaceOnLeft
      ? hasSpaceOnRight
        ? x - width / 2
        : chartArea.right - (width + 8)
      : 0 + 8) + offsets.x,
    8 + (height - fontSize) / 2 + offsets.y
  )

  /// restore original state
  context.restore()
}

export function getGradient(
  context: ScriptableLineSegmentContext,
  chartContext: CanvasRenderingContext2D,
  chart: Chart,
  positiveColor: string,
  negativeColor: string
) {
  if (context.p0.parsed.y * context.p1.parsed.y < 0) {
    // if the segment changes sign from p0 to p1
    const x0 = context.p0.parsed.x
    const x1 = context.p1.parsed.x
    const y0 = context.p0.parsed.y
    const y1 = context.p1.parsed.y

    //transform values to pixels
    const x0px = chart.scales.x.getPixelForValue(x0)
    const x1px = chart.scales.x.getPixelForValue(x1)
    const y0px = chart.scales.y.getPixelForValue(y0)
    const y1px = chart.scales.y.getPixelForValue(y1)

    // create gradient form p0 to p1
    const gradient = chartContext.createLinearGradient(x0px, y0px, x1px, y1px)

    // calculate frac - the relative length of the portion of the segment
    // from p0 to the point where the segment intersects the x axis
    const frac = Math.abs(y0) / (Math.abs(y0) + Math.abs(y1))

    // set colors at the ends of the segment
    const [col_p0, col_p1] =
      y0 > 0 ? [positiveColor, negativeColor] : [negativeColor, positiveColor]

    gradient.addColorStop(0, col_p0)
    gradient.addColorStop(frac, col_p0)
    gradient.addColorStop(frac, col_p1)
    gradient.addColorStop(1, col_p1)

    return gradient
  }

  return context.p0.parsed.y >= 0 ? positiveColor : negativeColor
}

function getTextOffsets(font: string) {
  // We're going to create a small, non-rendered canvas onto which we will draw
  // the letter "F", which has a hard top-left point. Seeing how far this point
  // is from 0,0 will give us the offset that is being used by this browser for
  // this font at this font-size.
  const temporaryCanvasWidth = 30
  const temporaryCanvasHeight = 50
  const temporaryCanvas = document.createElement('canvas')
  temporaryCanvas.setAttribute('width', '' + temporaryCanvasWidth)
  temporaryCanvas.setAttribute('height', '' + temporaryCanvasHeight)

  const temporaryContext = temporaryCanvas.getContext('2d')

  if (!temporaryContext) {
    return {
      x: 1,
      y: 1,
    }
  }

  temporaryContext.fillStyle = '#ffffff'
  temporaryContext.textBaseline = 'top'
  temporaryContext.font = font
  temporaryContext.fillText('F', 0, 0)

  const imageData = temporaryContext.getImageData(
    0,
    0,
    temporaryCanvasWidth,
    temporaryCanvasHeight
  )
  const pixelData = imageData.data
  // The pixel data for the canvas is stored as a linear series of R,G,B,A
  // readings. Which means, each pixel consumes 4 indices in the data array;
  // hence the concept of a "pixel width".
  const pixelWidth = 4
  // When the text is rendered to the canvas, it is anti-aliased, which means
  // that it has soft, partially-transparent edges. As we're scanning for
  // pixels within the pixel data, we want to skip over "mostly transparent"
  // pixels so that we can find a nice, dark pixel that better represents the
  // visual edge of the text glyph.
  const alphaCutoff = 127

  // CAUTION: This is NOT A GENERAL PURPOSE approach. This is working based on
  // several assumptions: that the font is using a SANS-SERIF face and that the
  // test letter, "F", has no unexpected rising or falling in either the
  // vertical or the horizontal axis. What this means is that as we scan the
  // liner pixel data, the first "strong" pixel (ie, a pixel that crosses the
  // non-transparent threshold) that we find should represent BOTH the X AND Y
  // delta between the origin point and where the browser is rendering the text
  // characters.
  for (let index = 0; index < pixelData.length; index += pixelWidth) {
    // Check the A threshold (of R,G,B,A), which is the last reading in the
    // pixel tuple.
    if (pixelData[index + pixelWidth - 1] > alphaCutoff) {
      // Since the pixel data is one linear series of readings, we have to
      // convert the linear offset into a set of X,Y offsets.
      const x = (index / pixelWidth) % temporaryCanvasWidth
      const y = Math.floor(index / pixelWidth / temporaryCanvasWidth)

      return {
        x: -x + 1,
        y: -y + 1,
      }
    }
  }

  // If we found no pixel data (maybe the font was SO LARGE that it actually
  // didn't render on our small, temporary canvas), just default to zero.
  return {
    x: 1,
    y: 1,
  }
}
