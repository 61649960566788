import type {
  IconDefinition,
  IconName,
  IconPrefix,
} from '@fortawesome/fontawesome-svg-core'

export const doubleBoltIcon: IconDefinition = {
  icon: [
    // SVG viewbox width (in pixels)
    25,

    // SVG viewbox height (in pixels)
    15,

    // Aliases (not needed)
    [],

    // Unicode as hex value (not needed)
    '',

    // SVG path data, create a single path here: https://jakearchibald.github.io/svgomg/
    'M.938 8.125 9.25.25 7.61 5.664l4.702.711L4 14.25l1.613-5.387-4.676-.738Zm12.25 0L21.5.25l-1.64 5.414 4.703.711-8.313 7.875 1.613-5.387-4.675-.738Z',
  ],
  iconName: 'double-bolt' as IconName,
  prefix: 'custom-icons' as IconPrefix,
}
