import { startOfMonth } from 'date-fns'
import { t } from 'i18next'
import { useMemo } from 'react'
import { styled } from 'styled-components'
import { formatDateRange } from 'utils/src'

import { useAuthMemberAxios } from '../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../api/urls'
import { StatisticCard } from '../../../components/general/StatisticCard'
import { H4 } from '../../../components/typography'
import { BodyMediumRegular } from '../../../components/typography/Typography.styled'
import { useGetDateFnsLocale } from '../../../core/hooks/useGetDateFnsLocale'
import { ChargingSpeedFractions } from '../../usage/components/ChargingSpeedFractions'
import { CostPerTypeFractions } from '../../usage/components/CostPerTypeFractions'
import { UsageFractions } from '../../usage/components/UsageFractions'

export const EmployerHomeStatistics = () => {
  // -- Data --
  const statisticsFilters = useMemo(
    () => ({
      dateFrom: startOfMonth(new Date()),
      dateTo: new Date(),
    }),
    []
  )

  const dateFnsLocale = useGetDateFnsLocale()

  const [{ data: statistics, loading }] = useAuthMemberAxios({
    url: Urls.employerChargingSessionsStatistics,
    params: statisticsFilters,
  })

  if (loading || !statistics) return null

  return (
    <div>
      <StTitleRow>
        <StTitle>{t('employer.home.overview')}</StTitle>
        <BodyMediumRegular>
          {formatDateRange(
            {
              start: statisticsFilters.dateFrom,
              end: statisticsFilters.dateTo,
            },
            dateFnsLocale
          )}
        </BodyMediumRegular>
      </StTitleRow>
      {statistics && (
        <StStatistics>
          <StatisticCard
            title={t('employer.usage.statistics.chargingBreakdown')}
            subTitle={formatDateRange(
              {
                start: statisticsFilters.dateFrom,
                end: statisticsFilters.dateTo,
              },
              dateFnsLocale
            )}
          >
            <UsageFractions statistics={statistics} />
          </StatisticCard>
          <StatisticCard
            title={t('employer.usage.statistics.chargingSpeed')}
            subTitle={formatDateRange(
              {
                start: statisticsFilters.dateFrom,
                end: statisticsFilters.dateTo,
              },
              dateFnsLocale
            )}
          >
            <ChargingSpeedFractions statistics={statistics} />
          </StatisticCard>
          <StatisticCard
            title={t('employer.usage.statistics.extraCosts')}
            subTitle={formatDateRange(
              {
                start: statisticsFilters.dateFrom,
                end: statisticsFilters.dateTo,
              },
              dateFnsLocale
            )}
          >
            <CostPerTypeFractions statistics={statistics} />
          </StatisticCard>
        </StStatistics>
      )}
    </div>
  )
}

const StStatistics = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

  > * {
    flex: 1 0 0;
  }
`

const StTitle = styled(H4)`
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space4};
`

const StTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space4};
`
