import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as RadixAccordion from '@radix-ui/react-accordion'
import { useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../../../theme/layout/breakpoints'
import { ButtonPrimary } from '../../../../components/button/ButtonPrimary'
import { Select } from '../../../../components/form/Select'
import { ZohoImage, ZohoModule } from '../../../../components/image/ZohoImage'
import { InfoPopup } from '../../../../components/info-popup/InfoPopup'
import {
  BodyExtraSmallMedium,
  BodyExtraSmallMediumCss,
  BodyLargeSemiBoldCss,
  BodyMediumRegular,
  BodyMediumRegularCss,
  BodySmallRegular,
  H3,
  H5,
} from '../../../../components/typography'
import { applyVat, formatDecimals } from '../../../../core/utils/number'
import { useIntercom } from '../../../../intercom/hooks/useIntercom'
import { useMember } from '../../../../member/hooks/useMember'
import { useUser } from '../../../../user/hooks/useUser'
import { useOnboarding } from '../../hooks/useOnboarding'
import { useProductAttributes } from '../../hooks/useProductAttributes'
import {
  getConnectionMethodName,
  getProductDescription,
  getProductName,
  getProductSpecifications,
} from '../../util/product'

import { ImageSlider } from './ImageSlider'
import { ProductColorButton } from './ProductColorButton'

import type { Color, ConnectionMethod, MountingType, Product } from 'types'

type ProductDetailProps = {
  product: Product
  onProductSelection: (
    productId: string,
    colorId: string,
    connectionMethodId: string,
    mountingOptionId: string,
    mountingOption: string
  ) => void
}

export const ProductDetail = ({
  product,
  onProductSelection,
}: ProductDetailProps) => {
  // -- Hooks --
  const { t } = useTranslation()
  const { ProductAttributes } = useProductAttributes()
  const { values: onboardingValues } = useOnboarding()
  const { currentMember } = useMember()
  const { user } = useUser()
  const intercom = useIntercom()
  const isDesktop = useMediaQuery(breakpoints.desktop)

  // -- State --
  const carouselRef = useRef<{ scrollTo: (index: number) => void }>(null)

  // -- Data --
  const mountingOptions = useMemo(
    () =>
      [
        {
          key: 'Wall',
          label: t('onboarding.product-selection-overview.wall-mount'),
        },
        {
          key: 'Pole',
          label: t('onboarding.product-selection-overview.pole-mount'),
        },
      ].filter(
        (option) =>
          product.mountingOptions.some(
            (mountingOption) => mountingOption.type === option.key
          ) || option.key === 'NULL'
      ),
    [product.mountingOptions, t]
  )

  const connectionMethods = useMemo(
    () =>
      product.connectionMethods?.map((connectionMethod: ConnectionMethod) => {
        return {
          key: connectionMethod.id,
          label: getConnectionMethodName(connectionMethod, user.language),
        }
      }),
    [product.connectionMethods, user.language]
  )

  // -- Intial values --
  const initialColor = useMemo(() => {
    let color
    if (onboardingValues.color && product.id === onboardingValues.product) {
      color = product.colors.find(
        (color) => color.id === onboardingValues.color
      )
    }

    // If the color is not found, default to the first color (which will always be the cheapest option)
    return color || product.colors?.[0]
  }, [])

  const initialConnectionMethod = useMemo(() => {
    let connectionMethod
    if (
      onboardingValues.connectionMethod &&
      product.id === onboardingValues.product
    ) {
      connectionMethod = product.connectionMethods.find(
        (connectionMethod) =>
          connectionMethod.id === onboardingValues.connectionMethod
      )
    }

    // If the connection method is not found, default to the first connection method (which will always be the cheapest option)
    return connectionMethod || product.connectionMethods[0]
  }, [])

  const initialMountingType = useMemo(() => {
    let mountingType
    if (
      onboardingValues.connectionMethod &&
      product.id === onboardingValues.product
    ) {
      mountingType = product.mountingOptions.find(
        (mountingOption) =>
          mountingOption.id === onboardingValues.connectionMethod
      )
    }

    if (mountingType) return mountingType

    const preselectedMountingType = product.mountingOptions.find(
      (mountingOption) =>
        mountingOption.type === onboardingValues.installationMethodKey
    )

    // If the Mounting type is not found, default to the first mounting type (which will always be the cheapest option)
    return preselectedMountingType || product.mountingOptions[0]
  }, [])

  // -- State --
  const [selectedColor, setSelectedColor] = useState<Color>(initialColor)
  const [selectedMountingOption, setSelectedMountingOption] =
    useState<MountingType>(initialMountingType)
  const [selectedConnectionMethod, setSelectedConnectionMethod] =
    useState<ConnectionMethod>(initialConnectionMethod)

  // -- Handlers --
  const handleSelectColor = (color: Color) => {
    const colorImage = color.detailImages?.[0]

    if (colorImage) {
      const imageIndex = sliderImages.findIndex(
        (image) => image.id === colorImage.id
      )

      imageIndex >= 0 && carouselRef.current?.scrollTo(imageIndex)
    }

    setSelectedColor(color)
  }

  // -- Vars --
  const disableConfirmation = !(
    (selectedColor || !product.colors?.length) &&
    selectedMountingOption &&
    selectedConnectionMethod
  )

  const calculatedPrice =
    applyVat(product.price, currentMember.company.applyVat ? product.vat : 0) +
    applyVat(
      selectedColor.price,
      currentMember.company.applyVat ? selectedColor.vat : 0
    ) +
    applyVat(
      selectedConnectionMethod.price,
      currentMember.company.applyVat ? selectedConnectionMethod.vat : 0
    ) +
    applyVat(
      selectedMountingOption.price,
      currentMember.company.applyVat ? selectedMountingOption.vat : 0
    )

  const sliderImages = useMemo(
    () => [
      ...product.gallery,
      ...product.colors.flatMap((color) => color.detailImages || []),
    ],
    [product]
  )

  const deviceContribution = currentMember?.hcpContract?.deviceContribution || 0
  const remainingBudget = deviceContribution - calculatedPrice
  const overBudget = Math.sign(remainingBudget) === -1

  const infoContent = useMemo(() => {
    return (
      <StPopperContent>
        <BodyExtraSmallMedium>
          {currentMember.hcpContract.context}
        </BodyExtraSmallMedium>
        <StQuestionLink onClick={intercom.show}>
          <Trans
            t={t}
            i18nKey="onboarding-product-selection-overview.info-popper.questions"
            components={{
              u: <u />,
            }}
          />
        </StQuestionLink>
      </StPopperContent>
    )
  }, [calculatedPrice, remainingBudget, deviceContribution])

  return product ? (
    <StContainer>
      <StHeaderContainer>
        <StImageContainer>
          {sliderImages.length > 0 ? (
            <ImageSlider images={sliderImages} ref={carouselRef} />
          ) : (
            <StImage>
              <ZohoImage
                module={ZohoModule.Products}
                id={product.id}
                altText={product.productName}
              />
            </StImage>
          )}
        </StImageContainer>
        <StProductHeader>
          {isDesktop ? <H3>{getProductName(product, user.language)}</H3> : null}
          <ProductAttributes product={product} />
          <div>
            <BodyMediumRegular>
              <Trans
                t={t}
                i18nKey={'onboarding.product-selection-overview.price'}
                components={{ bold: <StPrice /> }}
                values={{
                  price: formatDecimals(calculatedPrice),
                }}
              />
              <StVat>
                {currentMember.company.applyVat
                  ? t('onboarding.product-selection-overview.price.include-vat')
                  : t(
                      'onboarding.product-selection-overview.price.exclude-vat'
                    )}
              </StVat>
            </BodyMediumRegular>

            <StBudgetContainer>
              <BodySmallRegular>
                {overBudget
                  ? t(
                      'onboarding.product-selection-overview.installation-breakdown-over-budget',
                      {
                        overflow: formatDecimals(-remainingBudget),
                      }
                    )
                  : t(
                      'onboarding.product-selection-overview.covered-by-employer'
                    )}
              </BodySmallRegular>
              {currentMember.hcpContract.context &&
                currentMember.hcpContract.context !== '' && (
                  <InfoPopup content={infoContent} />
                )}
            </StBudgetContainer>
          </div>
          <StSelectionContainer>
            <Select
              options={mountingOptions}
              value={selectedMountingOption.type}
              onChange={(value?: string) => {
                setSelectedMountingOption(
                  product.mountingOptions.find(
                    (mountingOption) => mountingOption.type === value
                  ) as MountingType
                )
              }}
              disabled={mountingOptions.length === 1}
            />
            <Select
              options={connectionMethods}
              value={selectedConnectionMethod.id}
              onChange={(value?: string) => {
                setSelectedConnectionMethod(
                  product.connectionMethods.find(
                    (connectionMethod) => connectionMethod.id === value
                  ) as ConnectionMethod
                )
              }}
              disabled={connectionMethods?.length === 1}
            />
          </StSelectionContainer>
          <StColorContainer>
            {product.colors?.length > 0
              ? product.colors.map((color: Color) => (
                  <ProductColorButton
                    onClick={() => handleSelectColor(color)}
                    key={color.id}
                    color={color}
                    selected={selectedColor?.id === color.id}
                    disabled={product.colors?.length === 1}
                  />
                ))
              : null}
          </StColorContainer>
        </StProductHeader>
        <StProductSelectionContainer>
          <ButtonPrimary
            disabled={disableConfirmation}
            onClick={() => {
              if (
                !(
                  selectedColor &&
                  selectedConnectionMethod &&
                  selectedMountingOption
                )
              ) {
                return
              }

              onProductSelection(
                product.id,
                selectedColor.id,
                selectedConnectionMethod.id,
                selectedMountingOption.id,
                selectedMountingOption.type
              )
            }}
          >
            {t('onboarding.product-selection-detail.select')}
          </ButtonPrimary>
        </StProductSelectionContainer>
      </StHeaderContainer>

      {/* Accordion section */}
      <RadixAccordion.Root
        type="multiple"
        defaultValue={[
          getProductDescription(product, user.language)
            ? 'description'
            : 'specs',
        ]}
      >
        <StAccordionItem value="description">
          <StAccordionTrigger>
            <FontAwesomeIcon icon={['fass', 'chevron-right']} />
            <StAccordionHeader>
              {t('onboarding.product-selection-detail.description')}
            </StAccordionHeader>
          </StAccordionTrigger>
          <StAccordionContent>
            <BodyMediumRegular>
              {getProductDescription(product, user.language)}
            </BodyMediumRegular>
          </StAccordionContent>
        </StAccordionItem>
        <StAccordionItem value="specs">
          <StAccordionTrigger>
            <FontAwesomeIcon icon={['fass', 'chevron-right']} />
            <StAccordionHeader>
              {t('onboarding.product-selection-detail.specs')}
            </StAccordionHeader>
          </StAccordionTrigger>
          <StAccordionContent>
            <BodyMediumRegular>
              {getProductSpecifications(product, user.language)}
            </BodyMediumRegular>
          </StAccordionContent>
        </StAccordionItem>
      </RadixAccordion.Root>
    </StContainer>
  ) : null
}

const StContainer = styled.div`
  width: 100%;
  max-width: 100%;

  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space26};

  @media ${breakpoints.mobile} {
    width: 80vw;
    max-width: 1068px;
    margin-bottom: 0;
  }
`

const StHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  column-gap: ${({ theme }) => theme.UI.SpacingPx.Space10};
  row-gap: ${({ theme }) => theme.UI.SpacingPx.Space7};

  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space7};
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space7};
  border-bottom: 1px solid
    ${({ theme }) => theme.components['card-select']['default-border']};

  @media ${breakpoints.mobile} {
    grid-template-columns: 200px auto;
    row-gap: ${({ theme }) => theme.UI.SpacingPx.Space10};
  }

  @media ${breakpoints.desktop} {
    grid-template-columns: 360px auto;
  }
`
const StImageContainer = styled.div`
  grid-row: 2;
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};

  display: flex;
  min-width: 0;

  @media ${breakpoints.mobile} {
    grid-row: 1 / span 2;
  }
`

const StImage = styled.div`
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  overflow: hidden;
`

const StProductHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space3};

  @media ${breakpoints.mobile} {
    margin-bottom: 0;
  }
`

const StPrice = styled.span`
  ${BodyLargeSemiBoldCss}
  display: inline;
  margin-bottom: 6px;
`

const StVat = styled.span`
  ${BodyMediumRegularCss}
  display: inline;
`

const StColorContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`

const StSelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`

const StProductSelectionContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;

  width: 100%;

  margin-bottom: var(--content-bottom-inset);
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space6};

  @media ${breakpoints.mobile} {
    position: relative;
    width: auto;
    padding: 0;
    margin: 0;

    justify-self: flex-start;
  }
`
const StAccordionTrigger = styled(RadixAccordion.Trigger)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
  padding: 0;

  background-color: transparent;
  color: ${({ theme }) => theme.theme.text.body.black};
  border: none;

  cursor: pointer;

  & > svg {
    transition: transform 0.1s ease-in-out;
  }

  &:hover {
    text-decoration: underline;
  }

  &[data-disabled] {
    color: initial;
  }

  &[data-state='open'] {
    > svg {
      transform: rotate(90deg);
    }
  }
`

const StAccordionItem = styled(RadixAccordion.Item)`
  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space7};

  &:not(:last-of-type) {
    margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space7};
    border-bottom: 1px solid
      ${({ theme }) => theme.components['card-select']['default-border']};
  }
`

const StAccordionHeader = styled(H5)`
  margin: 0;
`

const StAccordionContent = styled(RadixAccordion.Content)`
  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space7};
  white-space: pre-wrap;
`

const StBudgetContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StPopperContent = styled.div`
  max-width: 320px;
  color: ${({ theme }) => theme.theme.text.body.white};

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StQuestionLink = styled.button`
  ${BodyExtraSmallMediumCss}

  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  text-align: left;

  color: ${({ theme }) => theme.theme.text.body.white};
`
