import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../../theme/layout/breakpoints'
import { BottomSheet } from '../../../components/bottom-sheet/BottomSheet'
import { ButtonPrimary } from '../../../components/button/ButtonPrimary'
import { ButtonTertiary } from '../../../components/button/ButtonTertiary'
import { Select } from '../../../components/form/Select'
import { InfoButton } from '../../../components/general/InfoButton'
import {
  BodyExtraSmallMedium,
  BodyMediumRegular,
  BodySmallRegular,
} from '../../../components/typography'
import { useIntercom } from '../../../intercom/hooks/useIntercom'

import { ChargingSpeedPicker } from './ChargingSpeedPicker'

type ChargingSpeedOption = {
  key: string
  label: string
}

type ChargingSpeedCardProps = {
  currentSpeed: number
  onSpeedChange: (speed: number) => void
  infoArticleId?: number
  maxSpeed: number
  isCharging?: boolean
}

export const ChargingSpeedCard = ({
  currentSpeed,
  onSpeedChange,
  infoArticleId,
  maxSpeed,
  isCharging = false,
}: ChargingSpeedCardProps) => {
  const { t } = useTranslation()
  const { showArticle } = useIntercom()
  const isDesktop = useMediaQuery(breakpoints.desktop)
  const [selectedSpeed, setSelectedSpeed] = useState(currentSpeed)
  const [isSpeedPickerOpen, setIsSpeedPickerOpen] = useState(false)

  // Update selected speed when the current speed from API changes
  useEffect(() => {
    setSelectedSpeed(currentSpeed)
  }, [currentSpeed])

  // Default min speed is 6A, which is a common minimum for charging
  const minSpeed = 6
  // Use the API-provided maxSpeed
  const effectiveMaxSpeed = Math.max(minSpeed, maxSpeed)

  // Generate speed options from minSpeed to effectiveMaxSpeed
  const speedOptions: ChargingSpeedOption[] = Array.from(
    { length: effectiveMaxSpeed - minSpeed + 1 },
    (_, index) => ({
      key: String(index + minSpeed),
      label: `${index + minSpeed} A`,
    })
  )

  // Handle desktop speed change
  const handleDesktopSpeedChange = (value?: string | number) => {
    if (!value) {
      return
    }

    const newSpeed = Number.parseInt(String(value), 10)
    setSelectedSpeed(newSpeed)
  }

  // Handle desktop save button click
  const handleSaveDesktopChanges = () => {
    onSpeedChange(selectedSpeed)
  }

  // Handle mobile speed picker
  const handleOpenPicker = () => {
    if (isCharging) {
      return
    }

    setIsSpeedPickerOpen(true)
  }

  const handleClosePicker = () => {
    setIsSpeedPickerOpen(false)
  }

  const handleConfirmSpeed = () => {
    onSpeedChange(selectedSpeed)

    setIsSpeedPickerOpen(false)
  }

  // Handle info button click
  const handleOpenInfo = () => {
    if (infoArticleId) {
      showArticle(infoArticleId)
    }
  }

  return (
    <StChargingSpeedCard>
      {isDesktop ? (
        <>
          <StDesktopHeader>
            <StDesktopLabel>
              {t('employee.profile.charger.speed.current_label')}
            </StDesktopLabel>
            <StDesktopSpeedWrapper>
              <StDesktopSelectContainer>
                <Select
                  options={speedOptions}
                  value={String(selectedSpeed)}
                  onChange={handleDesktopSpeedChange}
                  placeholder={t('employee.profile.charger.speed.select')}
                  disabled={isCharging}
                />
              </StDesktopSelectContainer>
              <StInfoButtonContainer>
                <InfoButton onClick={handleOpenInfo} />
              </StInfoButtonContainer>
            </StDesktopSpeedWrapper>
          </StDesktopHeader>

          {isCharging ? (
            <StInfoText>
              {t('employee.profile.charger.speed.charging_warning')}
            </StInfoText>
          ) : (
            <StInfoText>
              {t('employee.profile.charger.speed.info_text')}
            </StInfoText>
          )}

          <StDesktopButtonContainer>
            <ButtonPrimary
              onClick={handleSaveDesktopChanges}
              disabled={selectedSpeed === currentSpeed || isCharging}
            >
              {t('employee.profile.charger.speed.confirm')}
            </ButtonPrimary>
          </StDesktopButtonContainer>
        </>
      ) : (
        <>
          <StCurrentSpeedContainer>
            <StCurrentSpeed>
              {t('employee.profile.charger.speed.current_display')}{' '}
              <b>
                {currentSpeed} {t('employee.profile.charger.speed.unit')}
              </b>
            </StCurrentSpeed>
            <StInfoButtonContainer>
              <InfoButton onClick={handleOpenInfo} />
            </StInfoButtonContainer>
          </StCurrentSpeedContainer>

          <StSpeedSelectorTrigger
            onClick={handleOpenPicker}
            disabled={isCharging}
          >
            <StBodyExtraSmallMedium>
              {t('employee.profile.charger.speed.label')}
            </StBodyExtraSmallMedium>
          </StSpeedSelectorTrigger>

          {isCharging ? (
            <StInfoText>
              {t('employee.profile.charger.speed.charging_warning')}
            </StInfoText>
          ) : (
            <StInfoText>
              {t('employee.profile.charger.speed.info_text')}
            </StInfoText>
          )}
        </>
      )}

      {/* BottomSheet for mobile speed selection */}
      {!isDesktop && (
        <BottomSheet
          isOpen={isSpeedPickerOpen}
          onClose={handleClosePicker}
          hasBackdrop={true}
          detent="content-height"
          disableDrag={true}
          title={t('employee.profile.charger.speed.title')}
        >
          <StBottomSheetContainer>
            <StBottomSheetContent>
              <StCurrentSpeedInBottomSheet>
                {t('employee.profile.charger.speed.current_display')}{' '}
                <b>
                  {currentSpeed} {t('employee.profile.charger.speed.unit')}
                </b>
              </StCurrentSpeedInBottomSheet>

              <ChargingSpeedPicker
                value={selectedSpeed}
                onChange={setSelectedSpeed}
                maxSpeed={effectiveMaxSpeed}
              />

              <ButtonPrimary
                onClick={handleConfirmSpeed}
                disabled={selectedSpeed === currentSpeed}
              >
                {t('employee.profile.charger.speed.confirm')}
              </ButtonPrimary>
            </StBottomSheetContent>
          </StBottomSheetContainer>
        </BottomSheet>
      )}
    </StChargingSpeedCard>
  )
}

const StChargingSpeedCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

  background-color: ${({ theme }) => theme.theme.colors['nonary-9']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space5};
`

const StCurrentSpeedContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StCurrentSpeed = styled(BodyMediumRegular)`
  margin: 0;
`

const StInfoButtonContainer = styled.div`
  display: flex;
  align-items: center;
`

const StCurrentSpeedInBottomSheet = styled(BodyMediumRegular)`
  margin: 0;
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

const StInfoText = styled(BodySmallRegular)`
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`

const StSpeedSelectorTrigger = styled(ButtonTertiary)`
  justify-content: flex-start;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`

const StBodyExtraSmallMedium = styled(BodyExtraSmallMedium)`
  color: ${({ theme }) => theme.theme.colors['nonary-2']};
`

const StBottomSheetContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space8};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space6};
  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space5};
`

const StBottomSheetContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};
`

// Desktop specific styles
const StDesktopHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

const StDesktopSpeedWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StDesktopLabel = styled(BodyMediumRegular)`
  margin: 0;
  white-space: nowrap;
`

const StDesktopSelectContainer = styled.div`
  width: 120px;
`

const StDesktopButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space3};
`
