import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMemo, useState } from 'react'
import ContentLoader from 'react-content-loader'
import { styled } from 'styled-components'

import {
  BodySmallRegularCss,
  BodySmallSemiBoldCss,
} from '../../components/typography'
import { useDataTable } from '../hooks/useDataTable'

import type { BaseRow } from './DataTable'
import type { PaginationObject } from '../types/Types'

interface DataTableFooterProps<T> {
  metaData?: PaginationObject<T>['meta']
  loading?: boolean
}

const PAGES_TO_SHOW = 5

export const DataTableFooter = <T extends BaseRow>({
  metaData,
  loading = false,
}: DataTableFooterProps<T>) => {
  const { handlePageChange } = useDataTable()

  // Keep track of the page number inside the component for optimistic updates
  const [innerPage, setInnerPage] = useState(metaData?.page ?? 1)

  const pageCount = metaData
    ? Math.ceil(metaData.filteredItemCount / metaData.take)
    : 0

  const pageNumbers = useMemo<number[]>(() => {
    if (!metaData) {
      return []
    }

    const pages = []

    // Calculate the start page
    // If it's less than 0, set it to 0 so we show the first x pages
    // If it's more than the last x pages, set it so we show the last x pages
    // x is PAGEs_TO_SHOW
    const startPage = Math.min(
      Math.max(metaData.page - Math.floor(PAGES_TO_SHOW / 2), 0),
      pageCount - PAGES_TO_SHOW + 1
    )

    for (let index = startPage; index <= pageCount; index++) {
      if (index > 0 && index <= pageCount) {
        pages.push(index)
      }

      if (pages.length === PAGES_TO_SHOW) {
        break
      }
    }

    return pages
  }, [metaData, pageCount])

  if (loading) {
    return (
      <StFooterContainer>
        <tr>
          <td colSpan={999}>
            <StFooterCell>
              <ContentLoader
                speed={2}
                width={241}
                height={40}
                viewBox="0 0 241 40"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="1" y="0" rx="6" ry="6" width="20" height="40" />
                <rect x="29" y="0" rx="6" ry="6" width="40" height="40" />
                <rect x="77" y="0" rx="6" ry="6" width="40" height="40" />
                <rect x="125" y="0" rx="6" ry="6" width="40" height="40" />
                <rect x="173" y="0" rx="6" ry="6" width="40" height="40" />
                <rect x="221" y="0" rx="6" ry="6" width="20" height="40" />
              </ContentLoader>
            </StFooterCell>
          </td>
        </tr>
      </StFooterContainer>
    )
  }

  if (pageCount === 0 || !metaData) {
    return null
  }

  return (
    <StFooterContainer>
      <tr>
        <td colSpan={999}>
          <StFooterCell>
            <StPagesContainer>
              {pageCount > 1 && (
                <StFooterNavigationButton
                  disabled={innerPage === 1}
                  onClick={() => {
                    handlePageChange(innerPage - 1)
                    setInnerPage(innerPage - 1)
                  }}
                >
                  <FontAwesomeIcon icon={['fasr', 'chevron-left']} />
                </StFooterNavigationButton>
              )}

              {pageNumbers.map((pageNumber) => (
                <StPageButton
                  key={pageNumber}
                  disabled={innerPage === pageNumber}
                  onClick={() => {
                    handlePageChange(pageNumber)
                    setInnerPage(pageNumber)
                  }}
                >
                  {pageNumber}
                </StPageButton>
              ))}

              {pageCount > 1 && (
                <StFooterNavigationButton
                  disabled={innerPage === pageCount}
                  onClick={() => {
                    handlePageChange(innerPage + 1)
                    setInnerPage(innerPage + 1)
                  }}
                >
                  <FontAwesomeIcon icon={['fasr', 'chevron-right']} />
                </StFooterNavigationButton>
              )}
            </StPagesContainer>
          </StFooterCell>
        </td>
      </tr>
    </StFooterContainer>
  )
}

const StFooterContainer = styled.tfoot``

const StFooterCell = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space10};
`

const StPagesContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StPageButton = styled.button`
  ${BodySmallRegularCss}
  background-color: transparent;
  border: none;

  cursor: pointer;
  border-radius: 6px;

  width: ${({ theme }) => theme.UI.SpacingPx.Space10};
  aspect-ratio: 1 / 1;

  &:disabled {
    ${BodySmallSemiBoldCss}
    background-color: ${({ theme }) => theme.theme.colors['nonary-9']};
    cursor: default;
  }

  &:hover {
    background-color: ${({ theme }) => theme.theme.colors['nonary-9']};
  }

  &:focus {
    box-shadow: 0px 0px 0px 2px
      ${({ theme }) => theme.theme.colors['primary-0']};
  }
`

const StFooterNavigationButton = styled.button`
  background-color: transparent;
  border: none;

  cursor: pointer;
  border-radius: 6px;

  width: ${({ theme }) => theme.UI.SpacingPx.Space10};
  aspect-ratio: 1 / 1;
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};

  &:disabled {
    color: ${({ theme }) => theme.theme.text.body['gray-lighter']};
    cursor: default;
    pointer-events: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.theme.colors['nonary-9']};
  }

  &:focus {
    box-shadow: 0px 0px 0px 2px
      ${({ theme }) => theme.theme.colors['primary-0']};
  }
`
