import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { EmployeeRoutes } from '../../../../routing/routes'
import { breakpoints } from '../../../../theme/layout/breakpoints'
import { DetailPageHeader } from '../../../components/general/DetailPageHeader'
import { ContentContainer } from '../../../components/page-layout'
import { Tabs } from '../../../components/Tabs/Tabs'
import { useUser } from '../../../user/hooks/useUser'
import { EmployeeGeneralSettings } from '../components/EmployeeGeneralSettings'
import { EmployeeSmartChargingSettings } from '../components/EmployeeSmartChargingSettings'
import { ProfileMenuItem } from '../components/ProfileMenuItem'

// Non nullable typeguard
function nonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined
}

export const EmployeeSettingsScreen = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isDesktop = useMediaQuery(breakpoints.desktop)
  const { state } = useLocation()
  const { user } = useUser()

  // -- Vars --
  const settingPages = [
    {
      content: <EmployeeGeneralSettings />,
      value: 'general',
      title: t('employee.settings.general.title'),
    },
    user?.addresses[0]?.scoptOnboarded
      ? {
          content: <EmployeeSmartChargingSettings />,
          value: 'smart-charging',
          title: t('employee.settings.smart-charging.title'),
        }
      : undefined,
  ].filter(nonNullable)

  // -- Render --
  return (
    <ContentContainer>
      <DetailPageHeader
        title={t('employee.settings.title')}
        topTitle={t('employee.profile.title')}
        onBack={
          isDesktop
            ? undefined
            : state?.backTo
            ? () => navigate(state.backTo)
            : () => navigate(EmployeeRoutes.Profile)
        }
      />

      {isDesktop ? (
        <>
          <Tabs
            defaultValue={state?.settingScreen ?? 'general'}
            items={settingPages}
          />
        </>
      ) : (
        <StFlexContainer>
          <ProfileMenuItem
            icon={['fasr', 'sliders-simple']}
            title={t('employee.settings.general.title')}
            onClick={() => navigate(EmployeeRoutes.GeneralSettings)}
          />
          {user?.addresses[0]?.scoptOnboarded && (
            <ProfileMenuItem
              icon={['fasr', 'plug']}
              title={t('employee.settings.smart-charging.title')}
              onClick={() => navigate(EmployeeRoutes.SmartChargingSettings)}
            />
          )}
        </StFlexContainer>
      )}
    </ContentContainer>
  )
}

const StFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`
