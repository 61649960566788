import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type ChangeEvent, type HTMLInputTypeAttribute } from 'react'
import { styled } from 'styled-components'

import { BodyLargeSemiBoldCss, BodySmallRegularCss } from '../typography'

export type SearchInputProps = {
  value: string
  onChange: (value: string) => void
  name: string
  label?: string
  placeholder?: string
  disabled?: boolean
  type?: HTMLInputTypeAttribute
}

export const SearchInput = ({
  value,
  onChange,
  name,
  label,
  placeholder,
  disabled,
  type = 'text',
}: SearchInputProps) => {
  return (
    <StContainer>
      {label ? <StLabel htmlFor={name}>{label}</StLabel> : null}
      <StInputContainer $disabled={disabled}>
        <StSearchIcon icon={['fasr', 'magnifying-glass']} />
        <StInput
          id={name}
          name={name}
          value={value ?? ''}
          onChange={(event: ChangeEvent) => {
            const target = event.target as HTMLInputElement
            onChange(target.value)
          }}
          placeholder={placeholder}
          disabled={disabled}
          type={type}
        />
      </StInputContainer>
    </StContainer>
  )
}

const StContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const StLabel = styled.label`
  ${BodyLargeSemiBoldCss}
  display: block;

  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StInputContainer = styled.div<{
  $disabled?: boolean
}>`
  position: relative;

  height: ${({ theme }) => theme.UI.SpacingPx.Space13};
  display: flex;
  align-items: center;

  border: ${({ $disabled }) => ($disabled ? 'none' : '1px solid')};
  border-color: ${({ theme }) => theme.components.input.border};

  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};
  background-color: ${({ theme, $disabled }) =>
    $disabled
      ? theme.components.input['disabled-bg']
      : theme.components.input.bg};
  overflow: hidden;

  &:focus-within {
    box-shadow: 0px 0px 0px 2px
      ${({ theme }) => theme.theme.colors['primary-0']};
  }

  &:hover {
    border-color: ${({ theme }) => theme.theme.colors['primary-0']};
  }
`

const StSearchIcon = styled(FontAwesomeIcon)`
  position: absolute;
  left: ${({ theme }) => theme.UI.SpacingPx.Space4};
  top: 50%;
  transform: translateY(-50%);

  width: ${({ theme }) => theme.UI.SpacingPx.Space4};
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`

const StInput = styled.input`
  ${BodySmallRegularCss}

  height: 100%;
  width: 100%;

  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space12};
  padding-right: ${({ theme }) => theme.UI.SpacingPx.Space4};

  border: none;

  &:disabled {
    color: ${({ theme }) => theme.components.input['disabled-text']};
  }

  &::placeholder {
    color: ${({ theme }) => theme.components.input['placeholder-text']};
  }

  &:focus {
    outline: none;
  }
`
