import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { useAuthMemberAxios } from '../../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../../api/urls'
import { OnboardingStepTitle } from '../../components/OnboardingStepTitle'
import { SelectBoxes } from '../../components/SelectBoxes'
import { useOnboarding } from '../../hooks/useOnboarding'

export enum EnergyContractOwnerKeys {
  Company = 'Company',
  Private = 'Private',
}

export const EnergyContractOwnerStep = () => {
  // -- Data --
  const {
    setLoading,
    handleNext,
    values,
    items: { energyContractOwnerItems },
  } = useOnboarding()

  const [, execute] = useAuthMemberAxios(
    {
      url: Urls.OnboardingData,
      method: 'POST',
    },
    {
      manual: true,
    }
  )

  // -- State --
  const [selectedItem, setSelectedItem] = useState(
    values.energyContractOwnerKey
  )

  // -- Hooks --
  const { t } = useTranslation()

  // -- Vars --

  // -- Handlers --
  const handleSelect = async (key: EnergyContractOwnerKeys) => {
    try {
      setSelectedItem(key)
      setLoading(true)

      if (key !== values.energyContractOwnerKey) {
        await execute({
          data: {
            energyContractOwnerKey: key,
          },
        })
      }

      // Finalize onboarding if company is selected, this option will not be supported yet
      handleNext(
        { ...values, energyContractOwnerKey: key },
        key === EnergyContractOwnerKeys.Company
      )
    } catch {
      setLoading(false)
      return console.error('Failed to select energy contract owner')
    }
  }

  // -- Render --
  return (
    <StContainer>
      <OnboardingStepTitle>
        {t('onboarding.energyContractOwner.title')}
      </OnboardingStepTitle>

      <SelectBoxes
        items={energyContractOwnerItems}
        onSelect={handleSelect}
        selectedItem={selectedItem}
      />
    </StContainer>
  )
}

const StContainer = styled.div`
  height: 100%;
`
