import { useAuthAxios } from '../../api/hooks/useAuthAxios'
import { Urls } from '../../api/urls'

export type ChargerConfiguration = {
  loading: boolean
  configError: unknown
  maxCurrent: number | null // Maximum current the charger can support (from Station-MaxCurrent)
  currentValue: number | null // Current value set for the connector (from Connector1-MaxCurrent) TODO: we might need to add a check to see if we have multiple connectors
  updateMaxCurrent: (value: number) => Promise<void>
}

// localStorage key for storing pending update
const PENDING_CURRENT_KEY = 'charger-pending-current'
const PENDING_TIMEOUT_MS = 2 * 60 * 1000 // 2 minutes

export const useChargerConfiguration = (
  deviceId?: number
): ChargerConfiguration => {
  // Get configuration from API
  const [{ data, loading, error: configError }, fetchConfig] = useAuthAxios<
    { key_name: string; value: string }[]
  >(
    {
      url: deviceId
        ? Urls.employeeChargerConfiguration.replace(':id', String(deviceId))
        : '',
    },
    {
      manual: !deviceId,
    }
  )

  // Update configuration from API
  const [, updateConfig] = useAuthAxios<{ key_name: string; value: string }[]>(
    {
      url: deviceId
        ? Urls.employeeChargerConfiguration.replace(':id', String(deviceId))
        : '',
      method: 'PATCH',
    },
    {
      manual: true,
    }
  )

  // Extract configuration values from API response
  const maxCurrentConfig = data?.find(
    (config) => config.key_name === 'Station-MaxCurrent'
  )
  const currentValueConfig = data?.find(
    (config) => config.key_name === 'Connector1-MaxCurrent'
  )

  // Convert string values to numbers
  const maxCurrent = maxCurrentConfig
    ? Number.parseFloat(maxCurrentConfig.value)
    : null
  const apiCurrentValueConfig = currentValueConfig
    ? Number.parseFloat(currentValueConfig.value)
    : null

  // Check localStorage for pending update
  const getPendingUpdate = () => {
    try {
      const stored = localStorage.getItem(PENDING_CURRENT_KEY)

      if (!stored) {
        return null
      }

      const { value, timestamp } = JSON.parse(stored)

      // Check if pending update has expired (a.k.a more than 2 minutes old)
      if (Date.now() - timestamp > PENDING_TIMEOUT_MS) {
        localStorage.removeItem(PENDING_CURRENT_KEY)
        return null
      }

      return Number(value)
    } catch {
      localStorage.removeItem(PENDING_CURRENT_KEY)
      return null
    }
  }

  const pendingValue = getPendingUpdate()
  const currentValue =
    pendingValue === null ? apiCurrentValueConfig : pendingValue

  if (pendingValue !== null && apiCurrentValueConfig === pendingValue) {
    // Values match, so we can clear the pending update
    localStorage.removeItem(PENDING_CURRENT_KEY)
  }

  const updateMaxCurrent = async (value: number) => {
    if (!deviceId) {
      return
    }

    // Store update in localStorage with timestamp
    localStorage.setItem(
      PENDING_CURRENT_KEY,
      JSON.stringify({
        value,
        timestamp: Date.now(),
        deviceId,
      })
    )

    // Send update to API
    await updateConfig({
      data: {
        key_name: 'Connector1-MaxCurrent',
        value: `${value}.0`,
      },
    })

    // Refresh data after update
    fetchConfig()
  }

  return {
    loading,
    configError,
    maxCurrent,
    currentValue,
    updateMaxCurrent,
  }
}
