import type { SVGProps } from 'react'

export const ChargingPoleIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="25"
      height="54"
      viewBox="0 0 25 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="1.42299"
        y="2.05971"
        width="22.1492"
        height="22.9129"
        rx="1.14565"
        fill="#2D2D2D"
        stroke="#2D2D2D"
        strokeWidth="2.29129"
      />
      <path
        d="M8.58984 13.875L14.5273 8.25L13.3555 12.1172L16.7148 12.625L10.7773 18.25L11.9297 14.4023L8.58984 13.875Z"
        fill="#E7FB68"
      />
      <path
        d="M14.0275 26.1182L14.0275 24.5906L10.9725 24.5906L10.9725 26.1182L14.0275 26.1182ZM10.9725 52.0862C10.9725 52.9298 11.6564 53.6137 12.5 53.6137C13.3436 53.6137 14.0275 52.9298 14.0275 52.0862L10.9725 52.0862ZM10.9725 26.1182L10.9725 52.0862L14.0275 52.0862L14.0275 26.1182L10.9725 26.1182Z"
        fill="#2D2D2D"
      />
    </svg>
  )
}
