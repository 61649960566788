import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { BodySmallRegular, H6 } from '../../../components/typography'

import type { Member } from 'database'

export const MemberFrozenCard = ({ member }: { member: Member }) => {
  const { t } = useTranslation()

  return (
    <StCard>
      <StTitle>{t('employee.home.member-frozen-card.title')}</StTitle>
      <BodySmallRegular>
        {t('employee.home.member-frozen-card.description')}
      </BodySmallRegular>
      <BodySmallRegular>
        {t('employee.home.member-frozen-card.deactivation-date', {
          date: format(member.frozenAt ?? new Date(), 'dd/MM/yyyy'),
        })}
      </BodySmallRegular>
    </StCard>
  )
}

const StCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};

  padding: ${({ theme }) => theme.UI.SpacingPx.Space5};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};

  background-color: ${({ theme }) => theme.theme.colors['nonary-10']};
`

const StTitle = styled(H6)`
  margin: 0;
`
