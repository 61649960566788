import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { Map as MapBox } from 'react-map-gl'
import { css, styled } from 'styled-components'
import { match } from 'ts-pattern'

import { breakpoints } from '../../../../../theme/layout/breakpoints'
import { PriceBreakdown } from '../../../../components/charging-session/PriceBreakdown'
import {
  BodyMediumSemiBoldCss,
  BodySmallRegular,
  BodySmallRegularCss,
  BodySmallSemiBold,
} from '../../../../components/typography'
import { useGetDateFnsLocale } from '../../../../core/hooks/useGetDateFnsLocale'
import { ChargingSessionInfoBanner } from '../../../../employee/usage/components/ChargingSessionInfoBanner'
import { ChargingSpeedChip } from '../../../../employee/usage/components/ChargingSpeedChip'
import { valueToDate } from '../../../../translations/utils/date'
import { useUser } from '../../../../user/hooks/useUser'
import { formatCurrency } from '../../../utils/statistics'

import type { ChargingSessionWithDetectedAnomalies } from 'types'

type EmployeeDetailChargingSessionInfoProps = {
  chargingSession: ChargingSessionWithDetectedAnomalies
}

export const EmployeeDetailChargingSessionInfo = ({
  chargingSession,
}: EmployeeDetailChargingSessionInfoProps) => {
  const dateFnsLocale = useGetDateFnsLocale()
  const { t } = useTranslation()
  const { user } = useUser()
  // Check for specific anomaly types
  const hasIllegalRegion = chargingSession.anomalies?.some(
    (anomaly) => anomaly.type === 'IllegalRegion'
  )
  const hasHighCost = chargingSession.anomalies?.some(
    (anomaly) => anomaly.type === 'HighTimeCost'
  )

  // Derive hasAnomaly from specific anomaly types
  const hasAnomaly = hasIllegalRegion || hasHighCost

  // Get the actual anomaly details
  const illegalRegionAnomaly = chargingSession.anomalies?.find(
    (anomaly) => anomaly.type === 'IllegalRegion'
  )
  const highCostAnomaly = chargingSession.anomalies?.find(
    (anomaly) => anomaly.type === 'HighTimeCost'
  )

  // Get the values needed for the translations
  const detectedRegion = illegalRegionAnomaly?.detectedRegion || ''
  const actualCost = highCostAnomaly?.actualCost || 0
  const formattedCost = formatCurrency(actualCost, user.language)

  // Determine banner title and description based on anomaly types
  const { anomalyTitle, anomalyDescription } = match({
    hasIllegalRegion,
    hasHighCost,
    detectedRegion,
    formattedCost,
  })
    .with(
      { hasIllegalRegion: true, hasHighCost: true },
      ({ detectedRegion, formattedCost }) => ({
        anomalyTitle: t('employer.anomalies.banner.title.both'),
        anomalyDescription: t('employer.anomalies.banner.description.both', {
          country: detectedRegion,
          cost: formattedCost,
        }),
      })
    )
    .with({ hasIllegalRegion: true }, ({ detectedRegion }) => ({
      anomalyTitle: t('employer.anomalies.banner.title.illegal_region'),
      anomalyDescription: t(
        'employer.anomalies.banner.description.illegal_region',
        {
          country: detectedRegion,
        }
      ),
    }))
    .with({ hasHighCost: true }, ({ formattedCost }) => ({
      anomalyTitle: t('employer.anomalies.banner.title.high_cost'),
      anomalyDescription: t('employer.anomalies.banner.description.high_cost', {
        cost: formattedCost,
      }),
    }))
    .otherwise(() => ({
      anomalyTitle: '',
      anomalyDescription: '',
    }))

  const isHomeChargingSession = chargingSession.type === 'Home'

  const startDate = valueToDate(chargingSession.start)
  const startHour = format(startDate, 'HH:mm', dateFnsLocale)
  const endDate = valueToDate(chargingSession.end)
  const endHour = format(endDate, 'HH:mm', dateFnsLocale)

  const chargingSessionUser =
    chargingSession?.mspCard?.member?.user?.fullName ||
    chargingSession?.member?.user?.fullName

  return (
    <>
      <StChargingSessionInfo>
        {hasAnomaly && (
          <StBannerContainer>
            <ChargingSessionInfoBanner
              title={anomalyTitle}
              description={anomalyDescription}
              type={'error'}
            />
          </StBannerContainer>
        )}

        {chargingSession.type === 'Work' && (
          <ChargingSessionInfoBanner
            title={t('admin.employee-detail.usage.work-not-tracked.title')}
            description={t(
              'admin.employee-detail.usage.work-not-tracked.description'
            )}
            type={'info'}
          />
        )}

        <StChargingSessionRow>
          <StChargingSessionDetails>
            <BodySmallSemiBold>
              {isHomeChargingSession
                ? t('admin.employee-detail.usage.home')
                : chargingSession.locationName}
            </BodySmallSemiBold>
            <StAddress>
              <BodySmallRegular>{chargingSession.address}</BodySmallRegular>
              <BodySmallRegular>
                {`${chargingSession.postcode} ${chargingSession.city}, ${chargingSession.country}`}
              </BodySmallRegular>
            </StAddress>
            <StUserName>{chargingSessionUser}</StUserName>
            <StData>
              {startHour} - {endHour}
            </StData>
          </StChargingSessionDetails>
          <ChargingSpeedChip chargingSession={chargingSession} />
        </StChargingSessionRow>

        <PriceBreakdown chargingSession={chargingSession} />

        {chargingSession.longitude && chargingSession.latitude && (
          <StMap>
            <MapBox
              longitude={chargingSession.longitude}
              latitude={chargingSession.latitude}
              zoom={15}
              reuseMaps
              dragRotate={false}
              touchPitch={false}
              pitchWithRotate={false}
              mapboxAccessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}
              style={{
                width: '100%',
                height: '200px',
              }}
              mapStyle="mapbox://styles/bothrsdev/cloy3aalb013d01qo1geifax9"
              onRender={(event) => {
                event.target.resize()
              }}
              scrollZoom={{ around: 'center' }}
            />

            <StMarkerWrapper>
              <StMarker $selected={true} $clusterSize={4}>
                <FontAwesomeIcon icon={['fass', 'bolt']} fontSize={18} />
              </StMarker>
            </StMarkerWrapper>
          </StMap>
        )}
      </StChargingSessionInfo>
    </>
  )
}

const StChargingSessionInfo = styled.div`
  ${BodySmallRegularCss}
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space8};

  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space6};
  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space10};

  @media ${breakpoints.desktop} {
    padding-top: 0;
  }
`

const StChargingSessionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const StChargingSessionDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StAddress = styled.div`
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`

const StUserName = styled(BodySmallSemiBold)`
  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space6};
`

const StData = styled(BodySmallRegular)`
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`

const StMap = styled.div`
  position: relative;

  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  overflow: hidden;
`

const StMarkerWrapper = styled.div`
  position: absolute;
  bottom: 50%;
  left: 50%;

  // Make sure the pointer is in the middle of the map so zooming doesn't feel off
  transform: translateX(-50%) translateY(-2%);

  pointer-events: none;
`

const StMarker = styled.div<{
  $selected?: boolean
  $clusterSize?: number
}>`
  height: calc(40px + ${({ $clusterSize = 0 }) => $clusterSize}px);
  aspect-ratio: 1 / 1;

  display: flex;
  justify-content: center;
  align-items: center;

  ${BodyMediumSemiBoldCss}

  background-color: ${({ theme, $selected }) =>
    $selected
      ? theme.theme.colors['secondary-1']
      : theme.theme.colors['primary-1']};
  color: ${({ theme, $selected }) =>
    $selected ? theme.theme.colors['primary-1'] : theme.theme.colors.black};

  border-radius: 999px;

  cursor: pointer;

  margin-bottom: 7px;

  ${({ $clusterSize }) =>
    $clusterSize
      ? ''
      : css`
          &::before {
            content: '';

            aspect-ratio: 1 /1;

            position: absolute;
            top: -2px;
            right: -2px;

            width: 12px;
            border-radius: 999px;
            background-color: ${({ theme }) => theme.theme.colors.error};

            box-shadow: 0px 0px 0px 3px #bdc7cc;
          }
        `}

  &::after {
    content: '';

    aspect-ratio: 1 /1;

    position: absolute;
    bottom: 0px;
    z-index: -1;

    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 15px solid
      ${({ theme, $selected }) =>
        $selected
          ? theme.theme.colors['secondary-1']
          : theme.theme.colors['primary-1']};
  }
`

const StBannerContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};
`
