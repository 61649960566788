import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { EmployeeRoutes } from '../../../../routing/routes'
import { breakpoints } from '../../../../theme/layout/breakpoints'
import { EventType, useTracking } from '../../../analytics/hooks/useTracking'
import { useChargerConfiguration } from '../../../chargingPoint/hooks/useChargerConfiguration'
import { useChargingPoint } from '../../../chargingPoint/hooks/useChargingPoint'
import { DetailPageHeader } from '../../../components/general/DetailPageHeader'
import { FullPageLoader } from '../../../components/general/FullPageLoader'
import { ContentContainer } from '../../../components/page-layout'
import {
  BodyMediumMedium,
  BodySmallRegular,
  H5,
} from '../../../components/typography'
import { useIntercom } from '../../../intercom/hooks/useIntercom'
import { useUser } from '../../../user/hooks/useUser'
import { ChargerInformationCard } from '../components/ChargerInformationCard'
import { ChargingSpeedCard } from '../components/ChargingSpeedCard'
import { NotOperationalCard } from '../components/NotOperationalCard'

export const EmployeeChargerScreen = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const { trackEvent } = useTracking()
  const { show: showIntercom } = useIntercom()
  const navigate = useNavigate()
  const { state } = useLocation()
  const isDesktop = useMediaQuery(breakpoints.desktop)
  const { user } = useUser()

  // Charging speed article ID in Intercom
  const CHARGING_SPEED_ARTICLE_ID = t(
    'employee.profile.charger.speed.help-article-id'
  )

  // Get charger information
  const { loading, charger, error, charging, operational, address } =
    useChargingPoint()

  // Get charger configuration - includes maxCurrent from the charger API
  const {
    loading: configLoading,
    currentValue,
    maxCurrent,
    updateMaxCurrent,
  } = useChargerConfiguration(charger?.device?.id)

  // Determine if user has smart charging based on address data
  const hasSmartCharging = !!(
    user.addresses[0]?.scoptOnboarded && user.addresses[0]?.scoptId
  )

  // -- Callbacks --
  const handleNotOperationalContactClick = useCallback(() => {
    trackEvent(EventType.Click, 'charger_not_operational')
    showIntercom()
  }, [trackEvent, showIntercom])

  const handleSpeedChange = useCallback(
    (speed: number) => {
      // Track the event
      trackEvent(EventType.Click, 'charger_speed_change', { speed })

      // Update speed via API
      updateMaxCurrent(speed)
    },
    [trackEvent, updateMaxCurrent]
  )

  // Loading state
  if ((loading && !charger) || configLoading) {
    return <FullPageLoader withNavigation />
  }

  if (error || !charger) {
    throw new Error('Failed to load charger data')
  }

  // -- Render --
  return (
    <ContentContainer>
      <DetailPageHeader
        title={t('employee.profile.charger.title')}
        topTitle={t('employee.profile.title')}
        onBack={
          isDesktop
            ? undefined
            : state?.backTo
            ? () => navigate(state.backTo)
            : () => navigate(EmployeeRoutes.Profile)
        }
      />
      <StContainer>
        <StContainerColumn className="charger-info">
          <ChargerInformationCard
            style="gray"
            charging={charging}
            operational={operational}
          />

          {!operational && (
            <NotOperationalCard
              onContactClick={handleNotOperationalContactClick}
            />
          )}
        </StContainerColumn>

        {!hasSmartCharging && (
          <StContainerColumn className="charger-speed">
            <StDetailsContainer>
              <H5>{t('employee.profile.charger.speed')}</H5>
              <ChargingSpeedCard
                currentSpeed={currentValue || 0}
                onSpeedChange={handleSpeedChange}
                infoArticleId={Number(CHARGING_SPEED_ARTICLE_ID)}
                maxSpeed={maxCurrent || 0}
                isCharging={charging}
              />
            </StDetailsContainer>
          </StContainerColumn>
        )}

        <StContainerColumn className="charger-details">
          <StDetailsContainer>
            <H5>{t('employee.profile.charger.details')}</H5>

            <StDetailCard>
              <div>
                <StBodySmallRegular>
                  {t('employee.profile.charger.brand')}
                </StBodySmallRegular>
                <StBodyMediumMedium>{charger.device.vendor}</StBodyMediumMedium>
              </div>

              <div>
                <StBodySmallRegular>
                  {t('employee.profile.charger.model')}
                </StBodySmallRegular>
                <StBodyMediumMedium>
                  {charger.device.model_number}
                </StBodyMediumMedium>
              </div>

              <div>
                <StBodySmallRegular>
                  {t('employee.profile.charger.address')}
                </StBodySmallRegular>
                <StBodyMediumMedium>{address}</StBodyMediumMedium>
              </div>

              {hasSmartCharging && (
                <div>
                  <StBodySmallRegular>
                    {t('employee.profile.charger.smart_charging')}
                  </StBodySmallRegular>
                  <StBodyMediumMedium>
                    {t('employee.profile.charger.smart_charging_enabled')}
                  </StBodyMediumMedium>
                </div>
              )}
            </StDetailCard>
          </StDetailsContainer>
        </StContainerColumn>
      </StContainer>
    </ContentContainer>
  )
}

const StContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};

  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};

  @media ${breakpoints.desktop} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'charger-info charger-details'
      'charger-speed charger-details';
    gap: ${({ theme }) => theme.UI.SpacingPx.Space15};

    padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space10};

    .charger-info {
      grid-area: charger-info;
    }

    .charger-details {
      grid-area: charger-details;
    }

    .charger-speed {
      grid-area: charger-speed;
    }
  }
`

const StContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};
`

const StDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

  h5 {
    margin: 0;
  }
`

const StBodySmallRegular = styled(BodySmallRegular)`
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`
const StBodyMediumMedium = styled(BodyMediumMedium)`
  margin: ${({ theme }) => theme.UI.SpacingPx.Space3} 0;
`
const StDetailCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

  @media ${breakpoints.desktop} {
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
    padding: ${({ theme }) => theme.UI.SpacingPx.Space5};
  }
`
