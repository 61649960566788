import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-router-dom'
import { styled } from 'styled-components'

import { BodyMediumMediumCss } from '../typography'

import type { IconProp } from '@fortawesome/fontawesome-svg-core'

type NavigationItemProps = {
  name?: string
  to: string
  icon?: IconProp
  badge?: number
}

export const NavigationItem = ({
  name,
  to,
  icon,
  badge,
}: NavigationItemProps) => {
  return (
    <StNavLink to={to} end>
      <StContent>
        {icon ? <FontAwesomeIcon icon={icon} /> : null}
        {name}
        {badge ? <StBadge>{badge}</StBadge> : null}
      </StContent>
    </StNavLink>
  )
}

const StNavLink = styled(NavLink)`
  ${BodyMediumMediumCss}
  font-family: 'F37 Jagger';
  background-color: transparent;
  border: none;
  border-bottom: 6px solid transparent;
  padding-top: 6px;

  color: ${({ theme }) => theme.components.menu.default['item-text']};
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;

  &.active {
    border-bottom: 6px solid
      ${({ theme }) => theme.components.menu.default['border-select']};
    font-weight: 700;

    &:focus-visible {
      box-shadow: 0px 0px 0px 2px
        ${({ theme }) => theme.theme.colors['primary-0']};
    }
  }
`

const StContent = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
  position: relative;
`

const StBadge = styled.span`
  font-family: Inter;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 150%;
  background-color: ${({ theme }) => theme.theme.colors['primary-1']};
  border-radius: 999px;
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space2};
  font-size: 12px;
  height: 20px;
  min-width: 20px;
  display: grid;
  place-items: center;
  position: absolute;
  right: -30px;
  top: -10px;
`
