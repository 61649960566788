import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useSplashScreen } from '../../authentication/hooks/useSplashScreen'
import { goToStore } from '../../core/lib/goToStore'

import { OutdatedSvg } from './assets/OutdatedSvg'
import { FullScreenError } from './FullScreenError'

export const OutdatedScreen = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const { hideSplashScreen, translationsAreLoaded } = useSplashScreen()

  // -- Effects --
  useEffect(() => {
    if (translationsAreLoaded) {
      hideSplashScreen()
    }
  }, [translationsAreLoaded, hideSplashScreen])

  // -- Render --
  return (
    <FullScreenError
      onClick={goToStore}
      title={t('outdated-app-screen.title')}
      subTitle={t('outdated-app-screen.subtitle')}
      buttonText={t('outdated-app-screen.button')}
      svg={<OutdatedSvg />}
    />
  )
}
