import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { styled, css, useTheme } from 'styled-components'

import { ToolTipWrapper } from '../../../components/general/ToolTipWrapper'
import { StToolTipBar } from '../../../components/statistics/FractionElement'
import {
  BodyExtraSmallRegular,
  BodySmallRegular,
} from '../../../components/typography'
import { onHover } from '../../../core/lib/styleHelpers'
import { useUser } from '../../../user/hooks/useUser'
import { formatCurrency } from '../../utils/statistics'

import type { MemberForUsageReport } from 'types'

interface TotalCostBreakdownProps {
  member: MemberForUsageReport
}

export const TotalCostBreakdown = ({ member }: TotalCostBreakdownProps) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { user } = useUser()

  return (
    <StTotalCostContainer>
      <StIconWithLabel>
        <BodySmallRegular>
          {formatCurrency(member.totalCost, user.language)}
        </BodySmallRegular>
        <ToolTipWrapper
          toolTip={
            <StToolTip>
              <StToolTipBar
                $backgroundColor={theme.theme.text.body['gray-mid']}
              />
              <StToolTipInformation>
                {/* Energy */}
                <StTooltipLabel>
                  <FontAwesomeIcon fontSize={12} icon={['fass', 'plug']} />
                  <BodyExtraSmallRegular>
                    {t('employer.usage.costBreakdown.chargingCost')}
                  </BodyExtraSmallRegular>
                </StTooltipLabel>
                <BodyExtraSmallRegular>
                  {formatCurrency(member.totalEnergyCost, user.language)}
                </BodyExtraSmallRegular>

                {/* Time */}
                <StTooltipLabel>
                  <FontAwesomeIcon fontSize={12} icon={['fass', 'parking']} />
                  <BodyExtraSmallRegular>
                    {t('employer.usage.costBreakdown.timeCost')}
                  </BodyExtraSmallRegular>
                </StTooltipLabel>
                <BodyExtraSmallRegular>
                  {formatCurrency(member.totalTimeCost, user.language)}
                </BodyExtraSmallRegular>

                {/* Start */}
                <StTooltipLabel>
                  <FontAwesomeIcon
                    fontSize={12}
                    icon={['fass', 'location-pin-lock']}
                  />
                  <BodyExtraSmallRegular>
                    {t('employer.usage.costBreakdown.startCost')}
                  </BodyExtraSmallRegular>
                </StTooltipLabel>
                <BodyExtraSmallRegular>
                  {formatCurrency(member.totalStartCost, user.language)}
                </BodyExtraSmallRegular>
              </StToolTipInformation>
            </StToolTip>
          }
          placement={'bottom-end'}
        >
          <StUsageInfoContainer>
            <FontAwesomeIcon
              className="info-icon"
              icon={['fasr', 'info-circle']}
            />
            <FontAwesomeIcon
              className="info-icon-hover"
              icon={['fass', 'info-circle']}
            />
          </StUsageInfoContainer>
        </ToolTipWrapper>
      </StIconWithLabel>
    </StTotalCostContainer>
  )
}

const StTotalCostContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
`

const StIconWithLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

export const StToolTip = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
  flex-wrap: nowrap;
`

export const StUsageInfoContainer = styled.div`
  position: relative;
  z-index: 1;

  ${onHover(css`
    .info-icon-hover {
      opacity: 1;
    }

    .info-icon {
      opacity: 0;
    }
  `)}

  .info-icon {
    position: relative;
    opacity: 1;
  }

  .info-icon-hover {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
  }

  & svg {
    color: ${({ theme }) => theme.theme.text.body['gray-mid']};
  }
`

const StToolTipInformation = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-column-gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
  grid-row-gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StTooltipLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`
