import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ErrorBoundary } from '@sentry/react'
import { useEffect } from 'react'
import { Toaster } from 'react-hot-toast'
import { Outlet, useLocation } from 'react-router-dom'
import { useMediaQuery } from 'usehooks-ts'

import { trackPageView } from './modules/analytics/lib/trackPageView'
import { HandleAuthRedirect } from './modules/authentication/components/HandleAuthRedirect'
import { AuthenticationProvider } from './modules/authentication/providers/AuthenticationProvider'
import { EnvironmentBanner } from './modules/components/EnvironmentBanner'
import { DownTimeScreen } from './modules/components/error/DownTimeScreen'
import { ErrorScreen } from './modules/components/error/ErrorScreen'
import { OfflineScreen } from './modules/components/error/OfflineScreen'
import { OutdatedScreen } from './modules/components/error/OutdatedScreen'
import { FullPageLoader } from './modules/components/general/FullPageLoader'
import { useAppSettings } from './modules/core/hooks/useAppSettings'
import { useOfflineDetection } from './modules/core/hooks/useOfflineDetection'
import { useOptimileStatus } from './modules/core/hooks/useOptimileStatus'
import { IntercomProvider } from './modules/intercom/providers/IntercomProvider'
import { RegisterNotifications } from './modules/notification/components/RegisterNotifications'
import { theme } from './theme'
import { breakpoints } from './theme/layout/breakpoints'

// Use this component to load logic that needs to be initalised after navigation is loaded
export const AppRoot = () => {
  // -- Hooks --
  const { isOffline } = useOfflineDetection()
  const location = useLocation()
  const isDesktop = useMediaQuery(breakpoints.desktop)
  const { loading, outdated } = useAppSettings()

  const { optimileDown } = useOptimileStatus()

  // -- Effects --
  useEffect(() => {
    trackPageView()

    // Reset scroll position on navigation
    window.scrollTo(0, 0)
  }, [location])

  if (isOffline) {
    return <OfflineScreen />
  }

  if (loading) {
    return (
      <FullPageLoader
        withNavigation={!location.pathname.includes('embedded')}
      />
    )
  }

  if (optimileDown) {
    return <DownTimeScreen />
  }

  if (outdated) {
    return <OutdatedScreen />
  }

  return (
    <ErrorBoundary fallback={<ErrorScreen />}>
      <RegisterNotifications />
      <IntercomProvider>
        <AuthenticationProvider>
          <EnvironmentBanner />
          <HandleAuthRedirect />
          <Outlet />
        </AuthenticationProvider>
        <div>
          <Toaster
            containerStyle={{
              marginTop: 'var(--inset-top, 0px)',
              zIndex: 99999999,
            }}
            position={isDesktop ? 'bottom-center' : 'top-center'}
            toastOptions={{
              className: 'toasterClass',
              style: {
                fontFamily:
                  theme.typography['body-small'].Regular['font-family'],
                fontSize: theme.typography['body-small'].Regular['font-size'],
                lineHeight:
                  theme.typography['body-small'].Regular['line-height'],
                fontWeight:
                  theme.typography['body-small'].Regular['font-weight'],
                letterSpacing:
                  theme.typography['body-small'].Regular['letter-spacing'],
                color: theme.components.toaster['body-text'],
                width: isDesktop ? 'auto' : '100%',
                maxWidth: isDesktop
                  ? '500px'
                  : `calc(100vw - ${theme.UI.SpacingPx.Space14})`,
                borderRadius: '6px',
                padding: `10px ${theme.UI.SpacingPx.Space3}`,
              },
              error: {
                duration: 3000,
                position: isDesktop ? 'bottom-left' : 'bottom-center',
                style: {
                  backgroundColor: theme.theme.colors.error,
                },
                iconTheme: {
                  secondary: theme.components.toaster.icon,
                  primary: theme.theme.colors.error,
                },
                icon: (
                  <FontAwesomeIcon
                    fontSize={18}
                    icon={['fass', 'circle-exclamation']}
                  />
                ),
              },
              success: {
                duration: 3000,
                position: isDesktop ? 'bottom-left' : 'bottom-center',
                style: {
                  backgroundColor: theme.theme.colors['quaternary-1'],
                },
                iconTheme: {
                  secondary: theme.components.toaster.icon,
                  primary: theme.theme.colors['quaternary-1'],
                },
              },
            }}
          />
        </div>
      </IntercomProvider>
    </ErrorBoundary>
  )
}
