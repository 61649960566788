import { useTranslation } from 'react-i18next'

import { Fractions } from '../../../components/statistics/Fractions'
import { useUser } from '../../../user/hooks/useUser'
import {
  formatCurrency,
  statisticsCostPerTypeData,
} from '../../utils/statistics'

import type { ChargingSessionStatistics } from '../types/types'

type CostPerTypeFractionsProps = {
  statistics?: ChargingSessionStatistics | null
}

export const CostPerTypeFractions = ({
  statistics,
}: CostPerTypeFractionsProps) => {
  const { t } = useTranslation()
  const { user } = useUser()

  const { startCostPercentage, timeCostPercentage } =
    statisticsCostPerTypeData(statistics)

  // Calculate normalized percentages for just time and start costs
  const totalCostPercentage = timeCostPercentage + startCostPercentage
  const normalizedTimeCostPercentage =
    (timeCostPercentage /
      (totalCostPercentage === 0 ? 1 : totalCostPercentage)) *
    100
  const normalizedStartCostPercentage =
    (startCostPercentage /
      (totalCostPercentage === 0 ? 1 : totalCostPercentage)) *
    100

  const noExtraCosts =
    statistics?.totalCostPerPriceLineType?.Start === 0 &&
    statistics?.totalCostPerPriceLineType?.Time === 0

  return (
    <Fractions
      data={[
        {
          title: t('employer.usage.costPerTypeFractions.time'),
          formattedAmount: formatCurrency(
            statistics?.totalCostPerPriceLineType?.Time ?? 0,
            user.language
          ),
          subText: t('employer.usage.costPerTypeFractions.time.subtext', {
            percentage: timeCostPercentage.toFixed(2),
            cost: formatCurrency(statistics?.totalCost ?? 0, user.language),
          }),
          width: normalizedTimeCostPercentage,
          total: statistics?.totalCost ?? 0,
          backgroundColor: '#E6EAEF',
          icon: ['fass', 'square-parking'],
        },
        {
          title: t('employer.usage.costPerTypeFractions.start'),
          formattedAmount: formatCurrency(
            statistics?.totalCostPerPriceLineType?.Start ?? 0,
            user.language
          ),
          subText: t('employer.usage.costPerTypeFractions.start.subtext', {
            percentage: startCostPercentage.toFixed(2),
            cost: formatCurrency(statistics?.totalCost ?? 0, user.language),
          }),
          width: normalizedStartCostPercentage,
          total: statistics?.totalCost ?? 0,
          backgroundColor: '#F3F5F7',
          icon: ['fass', 'location-pin-lock'],
        },
      ]}
      disabled={noExtraCosts || !statistics?.totalCost}
      disabledMessage={
        statistics?.totalCost
          ? t('employer.usage.costPerTypeFractions.no-extra-cost')
          : t('employer.chargingSessions.stats.no-data')
      }
    />
  )
}
