import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation, Trans } from 'react-i18next'
import { Navigate, useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { EmployeeRoutes } from '../../../../routing/routes'
import { breakpoints } from '../../../../theme/layout/breakpoints'
import { EventType, useTracking } from '../../../analytics/hooks/useTracking'
import { useAuthMemberAxios } from '../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../api/urls'
import { ButtonPrimary } from '../../../components/button/ButtonPrimary'
import { ButtonQuaternary } from '../../../components/button/ButtonQuaternary'
import { FileInput } from '../../../components/form/file-input/FileInput'
import { Select } from '../../../components/form/Select'
import { DetailPageHeader } from '../../../components/general/DetailPageHeader'
import { Dialog } from '../../../components/general/Dialog'
import { IntercomLogo } from '../../../components/intercom/IntercomLogo'
import { ContentContainer } from '../../../components/page-layout'
import {
  BodyMediumRegular,
  BodyMediumRegularCss,
  H4,
} from '../../../components/typography'
import { useIntercom } from '../../../intercom/hooks/useIntercom'
import { useMember } from '../../../member/hooks/useMember'
import { useUser } from '../../../user/hooks/useUser'
import { FloatingContainerButton } from '../../onboarding/components/FloatingContainerButton'
import { takePicture } from '../../onboarding/lib/takePicture'
import ExampleImage from '../assets/charge-card-example.svg'

import { DeliveryMethod, MspCardStatus } from './LostChargeCardScreen'

import type { SelectOption } from '../../../components/form/Select'
import type { DetailPageHeaderAction } from '../../../components/general/DetailPageHeader'
import type { MspCard } from 'database'

enum Step {
  SelectDeliveryMethod = 1,
  UploadProof = 2,
}

export const BrokenChargeCardScreen = () => {
  // -- Hooks --
  const { currentChargeCard } = useMember()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { refetch } = useUser()
  const { trackEvent } = useTracking()
  const { show } = useIntercom()
  const isDesktop = useMediaQuery(breakpoints.desktop)

  // -- State --
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState<Step>(Step.SelectDeliveryMethod)
  const [isBlockConfirmationOpen, setIsBlockConfirmationOpen] = useState(false)
  const [deliveryMethod, setDeliveryMethod] = useState<
    DeliveryMethod | undefined
  >(undefined)
  const [damageProofImage, setDamageProofImage] = useState<File | undefined>(
    undefined
  )
  const [isUploading, setIsUploading] = useState(false)

  // -- Data --
  const [, execute] = useAuthMemberAxios<MspCard>(
    {
      url: Urls.blockChargeCard,
      method: 'POST',
    },
    {
      manual: true,
    }
  )

  const [, executeUpload] = useAuthMemberAxios<{
    damageProofKey: string
    mspCardId: string
  }>(
    {
      url: Urls.uploadDamageProof,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
    {
      manual: true,
    }
  )

  const deliveryOptions: SelectOption[] = Object.values(DeliveryMethod).map(
    (method) => ({
      key: method,
      label: t(`charge-card.block.delivery.${method}`),
    })
  )

  // -- Actions --
  const headerActions: DetailPageHeaderAction[] = [
    {
      name: 'Intercom',
      customIcon: <IntercomLogo />,
      onClick: show,
    },
  ]

  // -- Handlers --
  const handleAddPhoto = async () => {
    try {
      setIsUploading(true)
      const file = await takePicture(t('charge-card.block.broken.photo.title'))
      setDamageProofImage(file)
      setIsUploading(false)
    } catch (error) {
      console.error('Failed to take photo:', error)
      setIsUploading(false)
    }
  }

  const handleRemovePhoto = () => {
    setDamageProofImage(undefined)
  }

  const handleFileChange = (file: File) => {
    setDamageProofImage(file)
  }

  const handleContinue = () => {
    if (step === Step.SelectDeliveryMethod) {
      setStep(Step.UploadProof)
    } else if (step === Step.UploadProof) {
      setIsBlockConfirmationOpen(true)
    }
  }

  const handleBlockCardSubmit = async () => {
    if (!currentChargeCard || !damageProofImage) {
      return console.error('No active charge card or damage proof image')
    }

    try {
      setLoading(true)

      // First, upload the damage proof image
      const formData = new FormData()
      formData.append('file', damageProofImage)
      formData.append('mspUid', currentChargeCard.mspUid)

      // Set uploading state
      setIsUploading(true)

      // Upload the image
      const uploadResponse = await executeUpload({
        data: formData,
      })

      setIsUploading(false)

      // Then, block the card with the damage proof key
      await execute({
        data: {
          mspUid: currentChargeCard.mspUid,
          status: MspCardStatus.Damaged,
          damageProofKey: uploadResponse.data.damageProofKey,
        },
      })

      await refetch()
      trackEvent(EventType.Submit, 'block_msp_card', {
        reason: 'Damaged',
        deliveryMethod: deliveryMethod,
      })
      toast.success(t('charge-card.block.success'))
      navigate(EmployeeRoutes.ChargeCard)
    } catch (error) {
      setLoading(false)
      setIsUploading(false)
      toast.error(t('charge-card.block.error'))
      return console.error('Failed to block msp card', error)
    }
  }

  // -- Render --
  if (!currentChargeCard || currentChargeCard.mspCardStatus !== 'Active') {
    return <Navigate to={EmployeeRoutes.ChargeCard} replace />
  }

  return (
    <ContentContainer>
      <StHeaderContainer>
        <DetailPageHeader
          onBack={() =>
            step === Step.SelectDeliveryMethod
              ? navigate(EmployeeRoutes.ChargeCard)
              : setStep(Step.SelectDeliveryMethod)
          }
          title={
            isDesktop && step === Step.UploadProof
              ? t('charge-card.block.broken.photo.title')
              : t('charge-card.block.broken.title')
          }
          topTitle={t('charge-card.detail.title')}
          actions={headerActions}
        />
      </StHeaderContainer>

      <StContentWrapper>
        {step === Step.SelectDeliveryMethod && (
          <StFormContainer>
            <BodyMediumRegular>
              <StSpacedText>
                {t('charge-card.block.broken.description')}
              </StSpacedText>
              {t('charge-card.block.broken.notice')}
            </BodyMediumRegular>
            <StFormContent>
              <StSelectWrapper>
                <Select
                  label={t('charge-card.block.broken.delivery.label')}
                  placeholder={t(
                    'charge-card.block.broken.delivery.placeholder'
                  )}
                  value={deliveryMethod}
                  onChange={(value) =>
                    value && setDeliveryMethod(value as DeliveryMethod)
                  }
                  options={deliveryOptions}
                />
              </StSelectWrapper>
            </StFormContent>
          </StFormContainer>
        )}

        {step === Step.UploadProof && (
          <StFormContainer>
            {!isDesktop && <H4>{t('charge-card.block.broken.photo.title')}</H4>}

            <BodyMediumRegular>
              <Trans i18nKey="charge-card.block.broken.photo.description" />
            </BodyMediumRegular>

            {isDesktop && (
              <StUploadSection>
                <StFileUploadTitle>
                  {t('charge-card.block.broken.fileupload.title')}
                </StFileUploadTitle>
                <StFileInputWrapper>
                  <DesktopFileInput
                    file={damageProofImage}
                    onFileChange={handleFileChange}
                    onDelete={handleRemovePhoto}
                    isLoading={isUploading}
                  />
                </StFileInputWrapper>
              </StUploadSection>
            )}

            {/* Only show example when no image on mobile or always on desktop */}
            {(!damageProofImage || isDesktop) && (
              <StExampleSection>
                <StExampleTitle>
                  {t('charge-card.block.broken.photo.example.title')}
                </StExampleTitle>
                <StExampleImage
                  src={ExampleImage}
                  alt="Example of damaged card"
                />
              </StExampleSection>
            )}

            {isUploading ? (
              <StUploadingContainer>
                <BodyMediumRegular>
                  {t('charge-card.block.broken.photo.uploading')}
                </BodyMediumRegular>
              </StUploadingContainer>
            ) : damageProofImage && !isDesktop ? (
              <StImageContainer>
                <StDamageImage
                  src={URL.createObjectURL(damageProofImage)}
                  alt="Damage proof"
                />
                <StDeleteButton onClick={handleRemovePhoto}>
                  <FontAwesomeIcon icon={['fasr', 'trash']} />
                </StDeleteButton>
              </StImageContainer>
            ) : null}
          </StFormContainer>
        )}

        {step === Step.UploadProof && !damageProofImage && !isDesktop ? (
          <StButtonContainer>
            <StSubmitButton onClick={handleAddPhoto}>
              <FontAwesomeIcon icon={['fass', 'plus']} />
              {t('charge-card.block.broken.photo.button')}
            </StSubmitButton>
          </StButtonContainer>
        ) : isDesktop ? (
          <FloatingContainerButton
            onClick={handleContinue}
            title={t('charge-card.block.continue')}
            disabled={
              (step === Step.SelectDeliveryMethod && !deliveryMethod) ||
              (step === Step.UploadProof && !damageProofImage)
            }
          />
        ) : (
          <StButtonContainer>
            <StSubmitButton
              onClick={handleContinue}
              disabled={
                (step === Step.SelectDeliveryMethod && !deliveryMethod) ||
                (step === Step.UploadProof && !damageProofImage)
              }
            >
              {t('charge-card.block.continue')}
            </StSubmitButton>
          </StButtonContainer>
        )}

        {/* Confirmation Modal */}
        <Dialog
          title={t('charge-card.block.confirm.title')}
          description={t('charge-card.block.confirm.description')}
          open={isBlockConfirmationOpen}
          onOpenChange={setIsBlockConfirmationOpen}
          primaryButtonText={t('charge-card.block.confirm.button')}
          secondaryButtonText={t('charge-card.block.confirm.cancel')}
          onClickPrimaryButton={handleBlockCardSubmit}
          onClickSecondaryButton={() => setIsBlockConfirmationOpen(false)}
          disablePrimaryButton={loading || isUploading}
          width="640px"
        />
      </StContentWrapper>
    </ContentContainer>
  )
}

const DesktopFileInput = ({
  file,
  onFileChange,
  onDelete,
  isLoading,
}: {
  file?: File
  onFileChange: (file: File) => void
  onDelete: () => void
  isLoading: boolean
}) => {
  const { t } = useTranslation()

  return (
    <div>
      <FileInput
        file={file}
        onFileChange={onFileChange}
        onDelete={onDelete}
        isLoading={isLoading}
        fileLabel={t('charge-card.block.broken.fileupload.label', { count: 1 })}
        deleteText={t('charge-card.block.broken.photo.delete')}
      />
    </div>
  )
}

const StHeaderContainer = styled.div`
  position: relative;
`

const StButtonContainer = styled.div`
  position: fixed;
  bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};
  left: ${({ theme }) => theme.UI.SpacingPx.Space6};
  right: ${({ theme }) => theme.UI.SpacingPx.Space6};
`

const StSubmitButton = styled(ButtonPrimary)`
  width: 100%;
`

const StFileUploadTitle = styled(BodyMediumRegular)`
  color: ${({ theme }) => theme.theme.colors['nonary-4']};
`

const StExampleSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3}; // 12px gap
`

const StExampleTitle = styled(BodyMediumRegular)`
  color: ${({ theme }) => theme.theme.colors['nonary-4']};
`

const StExampleImage = styled.img`
  width: 50%;
  max-width: 300px;
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StImageContainer = styled.div`
  position: relative;
  width: 50%;
`

const StDeleteButton = styled(ButtonQuaternary)`
  position: absolute !important;
  top: ${({ theme }) => theme.UI.SpacingPx.Space3};
  right: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

const StDamageImage = styled.img`
  width: 100%;
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StUploadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.UI.SpacingPx.Space6};
  background: ${({ theme }) => theme.theme.colors['nonary-9']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
`
const StFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space10};
`

const StContentWrapper = styled.div`
  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space3};
  max-width: 600px;
`

const StFormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};
`

const StSpacedText = styled.div`
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};
`

const StFileInputWrapper = styled.div`
  max-width: 600px;
`

const StUploadSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px; // custom spacing
`

const StSelectWrapper = styled.div`
  @media ${breakpoints.desktop} {
    label {
      ${BodyMediumRegularCss}
      color: ${({ theme }) => theme.theme.colors['nonary-4']};
    }
  }
`
