import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Map as MapBox } from 'react-map-gl'
import { css, styled } from 'styled-components'
import { minutesToHoursAndMinutes } from 'utils/src'

import { breakpoints } from '../../../../theme/layout/breakpoints'
import { PriceBreakdown } from '../../../components/charging-session/PriceBreakdown'
import {
  BodyExtraSmallRegular,
  BodyMediumSemiBoldCss,
  BodySmallRegular,
  BodySmallRegularCss,
  BodySmallSemiBold,
} from '../../../components/typography'
import { useGetDateFnsLocale } from '../../../core/hooks/useGetDateFnsLocale'
import { formatDecimals } from '../../../core/utils/number'
import { valueToDate } from '../../../translations/utils/date'

import { ChargingSpeedChip } from './ChargingSpeedChip'

import type { ChargingSessionWithRelations } from 'types'

type ChargingSessionInfoMspProps = {
  chargingSession: ChargingSessionWithRelations
}

export const ChargingSessionInfoMsp = ({
  chargingSession,
}: ChargingSessionInfoMspProps) => {
  const dateFnsLocale = useGetDateFnsLocale()
  const { t } = useTranslation()

  const startHour = useMemo(() => {
    const startDate = valueToDate(chargingSession.start)
    return format(startDate, 'HH:mm', dateFnsLocale)
  }, [chargingSession.start, dateFnsLocale])

  const endHour = useMemo(() => {
    const endDate = valueToDate(chargingSession.end)
    return format(endDate, 'HH:mm', dateFnsLocale)
  }, [chargingSession.end, dateFnsLocale])

  const totalDuration = useMemo(() => {
    return minutesToHoursAndMinutes(chargingSession.duration)
  }, [chargingSession.duration])

  return (
    <>
      <StChargingSessionInfo>
        <StColumn>
          <StChargingSessionRow>
            <StChargingSessionDetails>
              <BodySmallSemiBold>
                {chargingSession.locationName}
              </BodySmallSemiBold>
              <StAddress>
                <BodySmallRegular>{chargingSession.address}</BodySmallRegular>
                <BodySmallRegular>
                  {`${chargingSession.postcode} ${chargingSession.city}, ${chargingSession.country}`}
                </BodySmallRegular>
              </StAddress>
            </StChargingSessionDetails>
            <ChargingSpeedChip chargingSession={chargingSession} />
          </StChargingSessionRow>

          <StChargingSessionTime>
            <BodySmallRegular>
              {startHour} - {endHour}
            </BodySmallRegular>
            <BodyExtraSmallRegular>
              <StIcon icon={['fasr', 'clock']} />
              {totalDuration.hours > 0 &&
                `${t('employee.chargingSessions.detail.duration-hours', {
                  hours: totalDuration.hours,
                })} `}
              {t('employee.chargingSessions.detail.duration-minutes', {
                minutes: formatDecimals(totalDuration.minutes, 0),
              })}
            </BodyExtraSmallRegular>
          </StChargingSessionTime>
        </StColumn>

        <PriceBreakdown chargingSession={chargingSession} />
      </StChargingSessionInfo>

      {chargingSession.longitude && chargingSession.latitude && (
        <StMap>
          <MapBox
            longitude={chargingSession.longitude}
            latitude={chargingSession.latitude}
            zoom={15}
            reuseMaps
            dragRotate={false}
            touchPitch={false}
            pitchWithRotate={false}
            mapboxAccessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}
            style={{
              width: '100%',
              height: '200px',
            }}
            mapStyle="mapbox://styles/bothrsdev/cloy3aalb013d01qo1geifax9"
            onRender={(event) => {
              event.target.resize()
            }}
            scrollZoom={{ around: 'center' }}
          />

          <StMarkerWrapper>
            <StMarker $selected={true} $clusterSize={4}>
              <FontAwesomeIcon icon={['fass', 'bolt']} fontSize={18} />
            </StMarker>
          </StMarkerWrapper>
        </StMap>
      )}
    </>
  )
}

const StChargingSessionInfo = styled.div`
  ${BodySmallRegularCss}
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};

  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space6};

  @media ${breakpoints.desktop} {
    padding-top: ${({ theme }) => theme.UI.SpacingPx.Space10};
    gap: ${({ theme }) => theme.UI.SpacingPx.Space8};
  }
`

const StChargingSessionTime = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`

const StIcon = styled(FontAwesomeIcon)`
  font-size: ${({ theme }) => theme.UI.SpacingPx.Space3};
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
  margin-right: ${({ theme }) => theme.UI.SpacingPx.Space1};
`

const StMap = styled.div`
  position: relative;
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space10};

  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  overflow: hidden;
`

const StMarkerWrapper = styled.div`
  position: absolute;
  bottom: 50%;
  left: 50%;

  // Make sure the pointer is in the middle of the map so zooming doesn't feel off
  transform: translateX(-50%) translateY(-2%);

  pointer-events: none;
`

const StMarker = styled.div<{
  $selected?: boolean
  $clusterSize?: number
}>`
  height: calc(40px + ${({ $clusterSize = 0 }) => $clusterSize}px);
  aspect-ratio: 1 / 1;

  display: flex;
  justify-content: center;
  align-items: center;

  ${BodyMediumSemiBoldCss}

  background-color: ${({ theme, $selected }) =>
    $selected
      ? theme.theme.colors['secondary-1']
      : theme.theme.colors['primary-1']};
  color: ${({ theme, $selected }) =>
    $selected ? theme.theme.colors['primary-1'] : theme.theme.colors.black};

  border-radius: 999px;

  cursor: pointer;

  margin-bottom: 7px;

  ${({ $clusterSize }) =>
    $clusterSize
      ? ''
      : css`
          &::before {
            content: '';

            aspect-ratio: 1 /1;

            position: absolute;
            top: -2px;
            right: -2px;

            width: 12px;
            border-radius: 999px;
            background-color: ${({ theme }) => theme.theme.colors.error};

            box-shadow: 0px 0px 0px 3px #bdc7cc;
          }
        `}

  &::after {
    content: '';

    aspect-ratio: 1 /1;

    position: absolute;
    bottom: 0px;
    z-index: -1;

    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 15px solid
      ${({ theme, $selected }) =>
        $selected
          ? theme.theme.colors['secondary-1']
          : theme.theme.colors['primary-1']};
  }
`

const StChargingSessionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const StColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StChargingSessionDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StAddress = styled.div`
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`
