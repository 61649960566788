import type { ChargingRegion } from 'database'

export const getChargingRegionDisplayName = (
  region: ChargingRegion
): string => {
  if (!region) {
    throw new Error('Charging region cannot be null')
  }

  // Use translation keys based on the region enum value
  return `employer.contracts.charging_region.${region.toLowerCase()}`
}
