import type { FullUser } from 'types'

export const getLoginMethod = (user: FullUser) => {
  if (!user.auth0Id) {
    return 'No account'
  }

  // Google
  if (user.auth0Id?.includes('google')) {
    return 'Google'
  }

  // Apple
  if (user.auth0Id?.includes('apple')) {
    return 'Apple'
  }

  return 'Email'
}
