/** Ater this many days the status gets set to "overdue" */
export const INVITE_REMINDER_MAX_DAYS = 14
/** The maximum reminders that get sent */
export const INVITE_REMINDER_MAX = 2
/** Every x days a reminder gets sent */
export const INVITE_REMINDER_INTERVAL = Math.round(
  (INVITE_REMINDER_MAX_DAYS - 4) / INVITE_REMINDER_MAX
)

/** Ater this many days the status gets set to "overdue" */
export const ONBOARDING_REMINDER_MAX_DAYS = 12
/** The maximum reminders that get sent */
export const ONBOARDING_REMINDER_MAX = 2
/** Every x days a reminder gets sent */
export const ONBOARDING_REMINDER_INTERVAL = Math.round(
  (ONBOARDING_REMINDER_MAX_DAYS - 2) / ONBOARDING_REMINDER_MAX
)

/** Ater this many days the status gets set to "overdue" */
export const QUOTE_REMINDER_MAX_DAYS = 14
/** The maximum reminders that get sent */
export const QUOTE_REMINDER_MAX = 2
/** Every x days a reminder gets sent */
export const QUOTE_REMINDER_INTERVAL = Math.round(
  (QUOTE_REMINDER_MAX_DAYS - 2) / QUOTE_REMINDER_MAX
)
