import type { SVGProps } from 'react'

export const BlossomChargerPoleIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="25"
      height="54"
      viewBox="0 0 25 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="1.42299"
        y="2.05971"
        width="22.1492"
        height="22.9129"
        rx="1.14565"
        fill="#2D2D2D"
        stroke="#2D2D2D"
        strokeWidth="2.29129"
      />
      <path
        d="M14.8475 17.2212L20.963 15.3201L20.7111 14.5793L14.596 16.4803L19.7978 12.8647L19.3194 12.2343L14.1176 15.8503L17.8968 10.8736L17.2382 10.4156L13.4587 15.3922L15.4457 9.54174L14.6714 9.30113L12.6844 15.1516V9H11.8703V15.1516L9.88367 9.30113L9.10931 9.54174L11.0963 15.3922L7.31681 10.4156L6.65827 10.8736L10.4374 15.8503L5.23567 12.2343L4.75694 12.8647L9.95904 16.4803L3.84396 14.5793L3.59209 15.3201L9.70754 17.2212H3.27734V18H10.5849V16.7522L12.2773 15.3106L13.9702 16.7522V18H21.2773V17.2212H14.8475Z"
        fill="#DCF730"
      />
      <path
        d="M14.0275 26.1182L14.0275 24.5906L10.9725 24.5906L10.9725 26.1182L14.0275 26.1182ZM10.9725 52.0862C10.9725 52.9298 11.6564 53.6137 12.5 53.6137C13.3436 53.6137 14.0275 52.9298 14.0275 52.0862L10.9725 52.0862ZM10.9725 26.1182L10.9725 52.0862L14.0275 52.0862L14.0275 26.1182L10.9725 26.1182Z"
        fill="#2D2D2D"
      />
    </svg>
  )
}
