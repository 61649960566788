import {
  offset,
  safePolygon,
  useClick,
  useClientPoint,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  shift,
  autoUpdate,
} from '@floating-ui/react'
import { useState, type PropsWithChildren, type ReactNode } from 'react'
import { styled } from 'styled-components'

import type { Placement } from '@floating-ui/react'

export enum ToolTipFollowOptions {
  None = 'none',
  Vertical = 'y',
  Horizontal = 'x',
  Both = 'both',
}

type ToolTipWrapperProps = PropsWithChildren & {
  toolTip?: ReactNode
  placement?: Placement
  follow?: ToolTipFollowOptions
  theme?: 'light' | 'dark'
  offsetAmount?: number
}

export const ToolTipWrapper = ({
  toolTip,
  placement = 'bottom',
  follow = ToolTipFollowOptions.None,
  children,
  theme = 'light',
  offsetAmount = 5,
}: ToolTipWrapperProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement,
    strategy: 'fixed',
    middleware: [
      offset(offsetAmount),
      shift({
        padding: 10,
      }),
    ],
    whileElementsMounted(referenceElement, floatingElement, update) {
      return autoUpdate(referenceElement, floatingElement, update, {
        animationFrame: true,
      })
    },
  })

  const hover = useHover(context, {
    mouseOnly: true,
    handleClose: safePolygon(),
  })
  const click = useClick(context, {
    ignoreMouse: true,
  })
  const dismiss = useDismiss(context)

  const clientPoint = useClientPoint(context, {
    enabled: follow && follow !== ToolTipFollowOptions.None,
    axis: follow === ToolTipFollowOptions.None ? undefined : follow,
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    click,
    clientPoint,
    dismiss,
  ])

  return (
    <>
      <StReference ref={refs.setReference} {...getReferenceProps()}>
        {children}
      </StReference>
      {toolTip && isOpen && (
        <StToolTipContent
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
          $lightTheme={theme === 'light'}
        >
          {toolTip}
        </StToolTipContent>
      )}
    </>
  )
}

const StReference = styled.div`
  height: 100%;
`

const StToolTipContent = styled.div<{ $lightTheme: boolean }>`
  width: auto;
  max-width: 350px;

  background-color: ${({ theme, $lightTheme }) =>
    $lightTheme ? theme.theme.colors.white : theme.theme.colors['secondary-1']};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space3};
  border-radius: 6px;

  z-index: 250;

  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1),
    0px 1px 2px -1px rgba(0, 0, 0, 0.03);
`
