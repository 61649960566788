import { Navigate, createBrowserRouter } from 'react-router-dom'

import { AppRoot } from '../AppRoot'
import { LoginScreen } from '../modules/authentication/screens/LoginScreen'
import { DebugScreen } from '../modules/components/debug/DebugScreen'
import { ErrorScreen } from '../modules/components/error/ErrorScreen'
import { LoginErrorScreen } from '../modules/components/error/LoginErrorScreen'
import { BlockChargeCardScreen } from '../modules/employee/chargeCard/screens/BlockChargeCardScreen'
import { BrokenChargeCardScreen } from '../modules/employee/chargeCard/screens/BrokenChargeCardScreen'
import { ChargeCardLandingPageScreen } from '../modules/employee/chargeCard/screens/ChargeCardLandingPageScreen'
import { ChargeCardScreen } from '../modules/employee/chargeCard/screens/ChargeCardScreen'
import { LostChargeCardScreen } from '../modules/employee/chargeCard/screens/LostChargeCardScreen'
import { ChargingPeakScreen } from '../modules/employee/hems/screens/ChargingPeakScreen'
import { HEMSScreen } from '../modules/employee/hems/screens/HemsScreen'
import { UsageDetailsScreen } from '../modules/employee/hems/screens/UsageDetailsScreen'
import { EmployeeHomeScreen } from '../modules/employee/home/screens/EmployeeHomeScreen'
import { UnauthenticatedInviteScreen } from '../modules/employee/invite/screens/UnauthenticatedInviteScreen'
import { MapScreen } from '../modules/employee/map/screens/MapScreen'
import { UnauthenticatedMapScreen } from '../modules/employee/map/screens/UnauthenticatedMapScreen'
import { OnboardingScreen } from '../modules/employee/onboarding/screens/OnboardingScreen'
import { TokenFlowScreen } from '../modules/employee/onboarding/screens/TokenFlowScreen'
import { EmployeeChargerScreen } from '../modules/employee/profile/screens/EmployeeChargerScreen'
import { EmployeeGeneralSettingsScreen } from '../modules/employee/profile/screens/EmployeeGeneralSettingsScreen'
import { EmployeePersonalDataScreen } from '../modules/employee/profile/screens/EmployeePersonalDataScreen'
import { EmployeePrivacyScreen } from '../modules/employee/profile/screens/EmployeePrivacyScreen'
import { EmployeeProfileScreen } from '../modules/employee/profile/screens/EmployeeProfileScreen'
import { EmployeeSettingsScreen } from '../modules/employee/profile/screens/EmployeeSettingsScreen'
import { EmployeeSmartChargingSettingsScreen } from '../modules/employee/profile/screens/EmployeeSmartChargingSettingsScreen'
import { EmployeeQuoteScreen } from '../modules/employee/quote/screens/EmployeeQuoteScreen'
import { EmployeeReimbursementsScreen } from '../modules/employee/reimbursements/screens/EmployeeReimbursementsScreen'
import { EmployeeUsageScreen } from '../modules/employee/usage/screens/EmployeeUsageScreen'
import { ContractsScreen } from '../modules/employer/contracts/screens/ContractsScreen'
import { DocumentsScreen } from '../modules/employer/documents/screens/DocumentsScreen'
import { EmployeeDetailScreen } from '../modules/employer/employees/screens/EmployeeDetailScreen'
import { EmployeesScreen } from '../modules/employer/employees/screens/EmployeesScreen'
import { InviteEmployeesScreen } from '../modules/employer/employees/screens/InviteEmployeesScreen'
import { EmployerHomeScreen } from '../modules/employer/home/screens/EmployerHomeScreen'
import { EmployerPrivacyScreen } from '../modules/employer/profile/screens/EmployerPrivacyScreen'
import { EmployerSettingsScreen } from '../modules/employer/settings/screens/EmployerSettingsScreen'
import { UsageScreen } from '../modules/employer/usage/screens/UsageScreen'

import { AuthenticatedGuard } from './guards/AuthenticatedGuard'
import { EmployeeGuard } from './guards/EmployeeGuard'
import { EmployeeNavigationWrapper } from './guards/EmployeeNavigationWrapper'
import { EmployeeSettingsWrapper } from './guards/EmployeeSettingsWrapper'
import { EmployerGuard } from './guards/EmployerGuard'
import { MobileOnlyGuard } from './guards/MobileOnlyGuard'
import { NavigationEmployerGuard } from './guards/NavigationEmployerGuard'
import { PermissionGuard } from './guards/PermissionGuard'
import { UnauthenticatedGuard } from './guards/UnauthenticatedGuard'
import { EmployeeRoutes, EmployerRoutes, UnauthenticatedRoutes } from './routes'

export const router = createBrowserRouter([
  {
    element: <AppRoot />,
    errorElement: <ErrorScreen />,
    children: [
      // Authenticated routes
      {
        element: <AuthenticatedGuard />,
        children: [
          // Employee routes
          {
            element: <EmployeeGuard />,
            children: [
              {
                element: <EmployeeNavigationWrapper />,
                children: [
                  {
                    path: EmployeeRoutes.Root,
                    element: <EmployeeHomeScreen />,
                  },
                  {
                    path: EmployeeRoutes.Usage,
                    element: <EmployeeUsageScreen />,
                  },
                  {
                    path: EmployeeRoutes.HEMS,
                    element: <HEMSScreen />,
                  },
                  {
                    path: EmployeeRoutes.Map,
                    element: <MapScreen />,
                  },
                  {
                    path: EmployeeRoutes.ChargeCardLandingPage,
                    element: <ChargeCardLandingPageScreen />,
                  },
                  {
                    element: <MobileOnlyGuard />,
                    children: [
                      {
                        path: EmployeeRoutes.Profile,
                        element: <EmployeeProfileScreen />,
                      },
                    ],
                  },
                ],
              },
              {
                element: <EmployeeNavigationWrapper hideMobile />,
                children: [
                  {
                    path: EmployeeRoutes.LostChargeCard,
                    element: <LostChargeCardScreen />,
                  },
                  {
                    path: EmployeeRoutes.BrokenChargeCard,
                    element: <BrokenChargeCardScreen />,
                  },
                  {
                    path: EmployeeRoutes.ChargeCard,
                    element: <ChargeCardScreen />,
                  },
                  {
                    path: EmployeeRoutes.BlockChargeCard,
                    element: <BlockChargeCardScreen />,
                  },

                  {
                    path: EmployeeRoutes.PersonalDetails,
                    element: <EmployeePersonalDataScreen />,
                  },
                  {
                    path: EmployeeRoutes.ChargingStation,
                    element: <EmployeeChargerScreen />,
                  },
                  {
                    path: EmployeeRoutes.Refunds,
                    element: <EmployeeReimbursementsScreen />,
                  },
                  {
                    path: EmployeeRoutes.Privacy,
                    element: <EmployeePrivacyScreen />,
                  },
                  {
                    path: EmployeeRoutes.Quote,
                    element: <EmployeeQuoteScreen />,
                  },
                  {
                    path: EmployeeRoutes.UsageDetails,
                    element: <UsageDetailsScreen />,
                  },
                  {
                    element: <EmployeeSettingsWrapper />,
                    children: [
                      {
                        path: EmployeeRoutes.Settings,
                        element: <EmployeeSettingsScreen />,
                      },
                      {
                        path: EmployeeRoutes.GeneralSettings,
                        element: <EmployeeGeneralSettingsScreen />,
                      },
                      {
                        path: EmployeeRoutes.SmartChargingSettings,
                        element: <EmployeeSmartChargingSettingsScreen />,
                      },
                      {
                        path: EmployeeRoutes.Peak,
                        element: <ChargingPeakScreen />,
                      },
                    ],
                  },
                ],
              },
              {
                path: EmployeeRoutes.Onboarding,
                element: <OnboardingScreen />,
              },
            ],
          },
          // Employer Routes
          {
            element: <EmployerGuard />,
            children: [
              {
                element: <NavigationEmployerGuard />,
                children: [
                  {
                    path: EmployerRoutes.Root,
                    element: <EmployerHomeScreen />,
                  },
                  {
                    path: EmployerRoutes.Documents,
                    element: <DocumentsScreen />,
                  },
                  {
                    path: EmployerRoutes.Settings,
                    element: <EmployerSettingsScreen />,
                  },
                  {
                    path: EmployerRoutes.Privacy,
                    element: <EmployerPrivacyScreen />,
                  },
                  {
                    element: <PermissionGuard permission="VIEW_USAGE_DATA" />,
                    children: [
                      {
                        path: EmployerRoutes.Usage,
                        element: <UsageScreen />,
                      },
                    ],
                  },
                  {
                    element: <PermissionGuard permission="MANAGE_EMPLOYEES" />,
                    children: [
                      {
                        path: EmployerRoutes.Employees,
                        element: <EmployeesScreen />,
                      },
                      {
                        path: EmployerRoutes.EmployeeDetail,
                        element: <EmployeeDetailScreen />,
                      },
                      {
                        path: EmployerRoutes.InviteEmployees,
                        element: <InviteEmployeesScreen />,
                      },
                      {
                        path: EmployerRoutes.Contracts,
                        element: <ContractsScreen />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      // Unauthenticated routes
      // These routes are at the back so they only get shown when no other route matches
      {
        element: <UnauthenticatedGuard />,
        children: [
          {
            path: UnauthenticatedRoutes.Root,
            element: <LoginScreen />,
          },
          {
            path: UnauthenticatedRoutes.Invite,
            element: <UnauthenticatedInviteScreen />,
          },
        ],
      },
      {
        path: UnauthenticatedRoutes.LoginError,
        element: <LoginErrorScreen />,
      },
      {
        path: UnauthenticatedRoutes.Debug,
        element: <DebugScreen />,
      },
      {
        path: UnauthenticatedRoutes.ImageUpload,
        element: <TokenFlowScreen />,
      },
      {
        path: UnauthenticatedRoutes.Map,
        element: <UnauthenticatedMapScreen />,
      },
      {
        path: '*',
        element: <Navigate to={UnauthenticatedRoutes.Root} replace />,
      },
    ],
  },
])
