import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled, useTheme } from 'styled-components'

import { breakpoints } from '../../../../theme/layout/breakpoints'
import { Chart } from '../../../components/chart/Chart'
import {
  BodyExtraSmallSemiBold,
  BodyExtraSmallRegular,
} from '../../../components/typography/Typography.styled'
import { formatTooltip, formatData } from '../../../core/lib/chart'
import { formatDecimals } from '../../../core/utils/number'

import {
  StChartContainer,
  StChartInfoContainer,
  DateRangeMode,
} from './UsageDetails'
import { UsageDetailsInfoCard } from './UsageDetailsInfoCard'

import type { ActiveElement } from 'chart.js'

type UsageDetailsDynamicTariffProps = {
  usageDetails?: {
    dynamicPrice?: [string, number][]
  }
  dateRange: { start: Date; end: Date }
  dateRangeMode: DateRangeMode
  isEnabled?: boolean
}

export function UsageDetailsDynamicTariff({
  usageDetails,
  dateRange,
  dateRangeMode,
}: UsageDetailsDynamicTariffProps) {
  // -- Hooks --
  const theme = useTheme()
  const { t } = useTranslation()

  // -- State --
  const [selectedPrice, setSelectedPrice] = useState<ActiveElement | null>(null)

  // -- Graph data --
  const priceData = useMemo(() => {
    // Map the data to the format expected by formatData
    const mappedData = (usageDetails?.dynamicPrice ?? []).map((data) => ({
      label: data[0],
      value: data[1] / 1000,
    }))

    // Then format using the chart helper
    const formattedData = formatData(dateRange, dateRangeMode, mappedData, t)

    // Fill in the 15-minute intervals with the hourly value
    return formattedData.map((point, index) => {
      // If the point has a value, use it
      if (point.value !== undefined && point.value !== 0) return point

      // Find the corresponding hour in the original data
      const hour = Math.floor(index / 4)
      const hourValue = mappedData[hour]?.value || 0

      return {
        ...point,
        value: hourValue,
      }
    })
  }, [dateRange, dateRangeMode, t, usageDetails?.dynamicPrice])

  // -- Chart --
  const lineChart = useMemo(
    () => (
      <Chart<'line'>
        type="line"
        data={{
          labels: priceData.map((data) => data.label),
          datasets: [
            {
              data: priceData.map((data) => data.value),
              animation: { duration: 0 },
              tension: 0,
              stepped: 'before',
              fill: false,
              backgroundColor: theme.theme.colors['nonary-5'] + '20',
              borderWidth: 1,
              borderColor: theme.theme.colors['nonary-5'],
            },
          ],
        }}
        onSelectedDataPointsChange={(elements) => {
          setSelectedPrice(elements?.[0] ?? null)
        }}
        processTooltipData={(elements) => {
          if (!elements?.length) return ''

          return formatTooltip(
            dateRangeMode,
            t,
            priceData[elements[0].index].timestamp
          )
        }}
      />
    ),
    [priceData, theme.theme.colors, dateRangeMode, t]
  )

  const averagePrice = useMemo(() => {
    if (priceData.length === 0) return 0
    const totalPrice = priceData.reduce(
      (accumulator, current) => accumulator + (current.value ?? 0),
      0
    )
    return totalPrice / priceData.length
  }, [priceData])

  return (
    <StChartContainer>
      <StChartInfoContainer>
        <UsageDetailsInfoCard
          selected={!!selectedPrice}
          value={
            selectedPrice
              ? formatDecimals(priceData[selectedPrice.index].value ?? 0, 2)
              : formatDecimals(averagePrice, 2)
          }
          suffix="€/kWh"
          color={theme.theme.colors['nonary-5']}
          label={selectedPrice ? 'Stroomprijs' : 'Gem. Stroomprijs'}
          empty={dateRangeMode !== DateRangeMode.DAY}
        />
      </StChartInfoContainer>

      {dateRangeMode === DateRangeMode.DAY ? (
        lineChart
      ) : (
        <StEmptyContainer>
          <BodyExtraSmallSemiBold>
            {t(
              `employee.hems.usage-details.dynamic-tariff.${dateRangeMode}.not-available`
            )}
          </BodyExtraSmallSemiBold>
          <BodyExtraSmallRegular>
            {t(
              `employee.hems.usage-details.dynamic-tariff.${dateRangeMode}.not-available-description`
            )}
          </BodyExtraSmallRegular>
        </StEmptyContainer>
      )}
    </StChartContainer>
  )
}

const StEmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: ${({ theme }) => theme.UI.SpacingPx.Space10} 0;

  @media ${breakpoints.desktop} {
    justify-content: center;
    gap: ${({ theme }) => theme.UI.SpacingPx.Space1};

    flex-grow: 1;

    padding: ${({ theme }) => theme.UI.SpacingPx.Space10};

    border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
    border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  }
`
