import { RemoveScroll } from 'react-remove-scroll'
import { styled } from 'styled-components'

import { Skeleton } from '../../components/Skeleton'

export function DataListItemSkeleton() {
  return (
    <RemoveScroll>
      <StContainer>
        <Skeleton width={80} height={20} />
        <Skeleton height={94} />
      </StContainer>
    </RemoveScroll>
  )
}

const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`
