import type { Address } from 'database'

export const formatAddress = (address: Partial<Address>) =>
  address?.street
    ? `${address.street} ${address.number}, ${address.postcode} ${address.city}, ${address.countryName}`
    : ''

export const formatCompanyAddress = (address: {
  street: string
  number: string
  postcode: string
  city: string
  country: string
}) =>
  address?.street
    ? `${address.street} ${address.number}, ${address.postcode} ${address.city}, ${address.country}`
    : ''
