import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { FilterTypes } from '../../../../components/filters/Filters'
import { ColumnType } from '../../../../dataTable/components/DataTable'
import { formatCurrency } from '../../../utils/statistics'
import { AnomalyType } from '../../types/types'

import type { Filter } from '../../../../components/filters/Filters'
import type { Column } from '../../../../dataTable/components/DataTable'
import type { AnomalyWithRelations } from '../../types/types'

const StTypeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const StFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.theme.colors.error};
`

export const useAnomaliesColumns = (includeEmployeeColumn = true) => {
  const { t } = useTranslation()

  return useMemo<Column<AnomalyWithRelations>[]>(() => {
    const columns: Column<AnomalyWithRelations>[] = []

    if (includeEmployeeColumn) {
      columns.push({
        key: 'member.user.fullName',
        label: t('employer.anomalies.table.employee'),
        type: ColumnType.STRING,
        sortable: false,
        transformData: (anomaly) => {
          if (anomaly.member?.user) {
            return anomaly.member?.user.fullName
          }

          return '-'
        },
      })
    }

    columns.push(
      {
        key: 'timestamp',
        label: t('employer.anomalies.table.timestamp'),
        type: ColumnType.DATE,
        sortable: true,
      },
      {
        key: 'type',
        label: t('employer.anomalies.table.type'),
        type: ColumnType.STRING,
        sortable: false,
        transformData: (anomaly) => {
          const typeText =
            anomaly.type === AnomalyType.IllegalRegion
              ? t('employer.anomalies.table.type.illegalRegion')
              : t('employer.anomalies.table.type.highCost')

          return (
            <StTypeContainer>
              <span>{typeText}</span>
              <StFontAwesomeIcon
                icon={['fasr', 'circle-exclamation']}
                fontSize={15}
              />
            </StTypeContainer>
          )
        },
      },
      {
        key: 'description',
        label: t('employer.anomalies.table.description'),
        type: ColumnType.STRING,
        sortable: false,
        transformData: (anomaly) => {
          if (anomaly.detectedRegion) {
            return `${t('employer.anomalies.table.detectedIn')} ${
              anomaly.detectedRegion
            }`
          }

          if (anomaly.actualCost) {
            return formatCurrency(anomaly.actualCost)
          }

          return '-'
        },
      }
    )

    return columns
  }, [t, includeEmployeeColumn])
}

export const useAnomaliesFilters = (includeTypeFilter = true) => {
  const { t } = useTranslation()

  return useMemo<Filter[]>(() => {
    const filters: Filter[] = [
      {
        key: 'dateRange',
        type: FilterTypes.DateRange,
        shortCutType: 'relative',
      },
    ]

    if (includeTypeFilter) {
      filters.push({
        key: 'type',
        placeholder: t('employer.anomalies.filter.type.placeholder'),
        type: FilterTypes.Option,
        options: [
          {
            key: 'NULL',
            label: t('employer.anomalies.filter.type.all'),
          },
          {
            key: AnomalyType.IllegalRegion,
            label: t('employer.anomalies.table.type.illegalRegion'),
          },
          {
            key: AnomalyType.HighTimeCost,
            label: t('employer.anomalies.table.type.highCost'),
          },
        ],
      })
    }

    return filters
  }, [t, includeTypeFilter])
}
