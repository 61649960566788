import { subDays, startOfDay, endOfDay } from 'date-fns'

import { Urls } from '../../../api/urls'
import { SortKeys } from '../../../dataTable/components/DataTableHeader'
import {
  DataTableProvider,
  PaginationType,
} from '../../../dataTable/providers/DataTableProvider'

import { AnomaliesDataTable } from './AnomaliesDataTable'

export const AnomaliesTabContent = () => {
  // Calculate "past 30 days" date range
  const today = new Date()
  const thirtyDaysAgo = subDays(today, 30)
  const defaultDateFrom = startOfDay(thirtyDaysAgo)
  const defaultDateTo = endOfDay(today)

  const anomalyFilters = {
    dateFrom: defaultDateFrom,
    dateTo: defaultDateTo,
  }

  return (
    <DataTableProvider
      paginationType={PaginationType.Infinite}
      url={Urls.employerChargingAnomalies}
      defaultSort="timestamp"
      defaultSortDirection={SortKeys.DESCENDING}
      amount={30}
      initialFilterValues={anomalyFilters}
    >
      <AnomaliesDataTable />
    </DataTableProvider>
  )
}
