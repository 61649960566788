import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { EventType, useTracking } from '../../../../analytics/hooks/useTracking'
import { Select, type SelectOption } from '../../../../components/form/Select'
import { Dialog } from '../../../../components/general/Dialog'
import { BodyMediumRegular } from '../../../../components/typography'

import type { UserWithContractAndCards } from 'types'

type FreezeEmployeeDialogProps = {
  open: boolean
  setOpen: (open: boolean) => void
  handleFreeze: (reason: string) => void
  employee: UserWithContractAndCards
  isLoading: boolean
}

export const FreezeEmployeeDialog = ({
  open,
  setOpen,
  handleFreeze,
  employee,
  isLoading,
}: FreezeEmployeeDialogProps) => {
  // -- Hooks --
  const { t } = useTranslation()
  const { trackEvent } = useTracking()

  // -- Vars --
  const blockFreeze = [
    'SharedWithInstallationPartner',
    'InstallationPlanned',
    'CertificationPlanned',
  ].includes(employee.hcpStatus?.status ?? '')

  // -- State --
  const [step, setStep] = useState<'block' | 'reason' | 'confirm'>(
    blockFreeze ? 'block' : 'reason'
  )
  const [freezeReason, setFreezeReason] = useState<string>('CareerBreak')

  const freezeReasonOptions: SelectOption[] = [
    {
      label: t('admin.employee-detail.freeze-dialog.reason.CareerBreak'),
      key: 'CareerBreak',
    },
    {
      label: t('admin.employee-detail.freeze-dialog.reason.LongTermSickness'),
      key: 'LongTermSickness',
    },
    {
      label: t('admin.employee-detail.freeze-dialog.reason.ParentalLeave'),
      key: 'ParentalLeave',
    },
    {
      label: t('admin.employee-detail.freeze-dialog.reason.Other'),
      key: 'Other',
    },
  ]

  // -- Descriptions --
  const title = useMemo(() => {
    return step === 'block'
      ? t('admin.employee-detail.freeze-dialog.freeze-title', {
          fullName: employee.fullName,
        })
      : step === 'reason'
      ? t('admin.employee-detail.freeze-dialog.reason-title', {
          fullName: employee.fullName,
        })
      : t('admin.employee-detail.freeze-dialog.confirm-title', {
          fullName: employee.fullName,
        })
  }, [step, t, employee.fullName])

  const primaryButtonText = useMemo(() => {
    return step === 'block'
      ? t('admin.employee-detail.freeze-dialog.close')
      : step === 'reason'
      ? t('admin.employee-detail.freeze-dialog.next')
      : t('admin.employee-detail.freeze-dialog.confirm-freeze', {
          fullName: employee.fullName,
        })
  }, [step, t, employee.fullName])

  // -- Handlers --
  const closeDialog = () => {
    setOpen(false)
    setStep(blockFreeze ? 'block' : 'reason')
    trackEvent(EventType.Close, 'close_freeze_member_modal')
  }

  const handleFreezeStep = () => {
    if (step === 'block') {
      closeDialog()
      return
    }

    if (step === 'reason') {
      setStep('confirm')
      return
    }

    handleFreeze(freezeReason)
    closeDialog()
  }

  // -- Content --
  const blockStepContent = (
    <StDeleteContent>
      <BodyMediumRegular>
        {t('admin.employee-detail.freeze-dialog.block-freeze-description1', {
          fullName: employee.fullName,
        })}
      </BodyMediumRegular>
    </StDeleteContent>
  )

  const reasonStepContent = (
    <StDeleteContent>
      <BodyMediumRegular>
        {t('admin.employee-detail.freeze-dialog.reason-description', {
          fullName: employee.fullName,
        })}
      </BodyMediumRegular>
      <Select
        options={freezeReasonOptions}
        value={freezeReason}
        label={t('admin.employee-detail.freeze-dialog.reason-label')}
        onChange={(option) => setFreezeReason(option ?? 'CareerBreak')}
      />
    </StDeleteContent>
  )

  const confirmStepContent = (
    <StDeleteContent>
      <BodyMediumRegular>
        {t('admin.employee-detail.freeze-dialog.confirm-description', {
          fullName: employee.fullName,
        })}
      </BodyMediumRegular>
    </StDeleteContent>
  )

  // -- Render --
  return (
    <Dialog
      open={open}
      onClickCloseButton={() => setOpen(false)}
      title={title}
      primaryButtonText={primaryButtonText}
      secondaryButtonText={
        blockFreeze
          ? undefined
          : t('admin.employee-detail.freeze-dialog.cancel')
      }
      onClickPrimaryButton={handleFreezeStep}
      onClickSecondaryButton={blockFreeze ? undefined : closeDialog}
      disablePrimaryButton={isLoading}
    >
      {step === 'block' && blockStepContent}
      {step === 'reason' && reasonStepContent}
      {step === 'confirm' && confirmStepContent}
    </Dialog>
  )
}

const StDeleteContent = styled.div`
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space5};

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space10};
`
