import type { Anomaly } from 'database'
import type { ChargingSessionWithRelations } from 'types'

export enum ChargingSessionType {
  HOME = 'Home',
  PUBLIC = 'Public',
  WORK = 'Work',
}

export enum ChargingSessionPowerType {
  AC = 'AC',
  DC = 'DC',
}

export enum ChargingSessionPriceLineType {
  Energy = 'Energy',
  Start = 'Start',
  Time = 'Time',
}

export enum AnomalyType {
  IllegalRegion = 'IllegalRegion',
  HighTimeCost = 'HighTimeCost',
}

export type ChargingSessionStatistics = {
  totalUsage: number
  totalUsageHomeChargers: number
  totalUsageWithoutHomeChargers: number
  totalCost: number
  totalCostHomeChargers: number
  totalCostWithoutHomeChargers: number
  totalCostPublicSessions: number
  totalUsagePerType: Record<ChargingSessionType, number>
  totalUsagePerPowerType: Record<ChargingSessionPowerType, number>
  totalCostPerPriceLineType: Record<ChargingSessionPriceLineType, number>

  totalUsersWithHcp: number
  totalUsersWithoutHcp: number
}

export type AnomalyWithRelations = Anomaly & {
  member?: {
    id: string
    user?: {
      fullName: string
      blossomId: string
    }
  }
  chargingSession?: ChargingSessionWithRelations
}
