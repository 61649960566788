import { useTranslation } from 'react-i18next'

import { DataTable } from '../../../dataTable/components/DataTable'
import { useDataTable } from '../../../dataTable/hooks/useDataTable'
import { Filters } from '../../../employee/onboarding/components/Filters'

import {
  useAnomaliesColumns,
  useAnomaliesFilters,
} from './anomalies/AnomaliesTableConfig'
import { StHeader } from './UsageDataTable'

import type { AnomalyWithRelations } from '../types/types'

export const AnomaliesDataTable = () => {
  const { t } = useTranslation()
  const {
    filterValues,
    setFilterValues,
    clearFilters,
    navigationContainerBottom,
  } = useDataTable<AnomalyWithRelations>()

  const filters = useAnomaliesFilters(true)
  const columns = useAnomaliesColumns(true)

  return (
    <>
      <StHeader>
        <Filters
          key="anomalies-data-table-filters"
          filters={filters}
          filterValues={filterValues}
          withSearch
          searchPlaceholder={t('employer.usage.table.filter.search')}
          onFiltersChange={(filterKey, value) => {
            setFilterValues((oldValues) => {
              return {
                ...oldValues,
                [filterKey]: value,
              }
            })
          }}
          onClearFilters={() => {
            clearFilters()
          }}
          loading={false}
          stickyPosition={navigationContainerBottom}
        />
      </StHeader>
      <DataTable<AnomalyWithRelations>
        columns={columns}
        emptyTitle={t('employer.anomalies.table.empty-title')}
        emptyDescription={t('employer.anomalies.table.empty-description')}
        emptyFiltersTitle={t('employer.anomalies.table.empty-title')}
        emptyFiltersDescription={t(
          'employer.anomalies.table.empty-description'
        )}
      />
    </>
  )
}
