import { useInterval } from 'usehooks-ts'

import { useAuthAxios } from '../../api/hooks/useAuthAxios'
import { Urls } from '../../api/urls'
import { createAddressString } from '../../components/form/address-search/createAddressString'

import type { AddressWithDevices, OptimileDevice } from 'types'

type ChargingPoint = {
  loading: boolean
  error: unknown
  charger?: {
    address: AddressWithDevices
    device: OptimileDevice
  }
  address: string
  operational: boolean
  charging: boolean
}

// Polling interval in milliseconds
const POLLING_INTERVAL = 15000 // 15 seconds

export const useChargingPoint = (active = true): ChargingPoint => {
  // -- Data --
  const [{ data, loading, error }, refetch] = useAuthAxios<
    {
      address: AddressWithDevices
      device: OptimileDevice
    }[]
  >(
    {
      url: Urls.employeeChargers,
    },
    {
      // Don't automatically fetch data when hook is not set as active
      manual: !active,
    }
  )
  useInterval(refetch, active ? POLLING_INTERVAL : null)

  // -- Vars --
  const charger = data?.[0]
  const address = charger ? createAddressString(charger.address) : ''

  const chargingPoint = charger?.device?.charging_points?.[0]
  const operational = chargingPoint?.is_operational === true
  const charging = chargingPoint?.status === 'in_use'

  return {
    loading,
    error,
    charger,
    address,
    operational,
    charging,
  }
}
