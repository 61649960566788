import { createContext } from 'react'

import { useAuthMemberAxios } from '../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../api/urls'

import type { PropsWithChildren } from 'react'

type DocumentContext = {
  unreadCount: number
  refetchUnreadCount: () => void
}

export const documentContext = createContext<DocumentContext | null>(null)

export const DocumentProvider = ({ children }: PropsWithChildren) => {
  // -- Data --
  const [{ data: unreadCount }, refetch] = useAuthMemberAxios<number>({
    url: Urls.unreadDocumentsCount,
  })

  return (
    <documentContext.Provider
      value={{
        unreadCount: unreadCount ?? 0,
        refetchUnreadCount: refetch,
      }}
    >
      {children}
    </documentContext.Provider>
  )
}
