import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { useAuthMemberAxios } from '../../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../../api/urls'
import { useMember } from '../../../../member/hooks/useMember'
import { useUser } from '../../../../user/hooks/useUser'
import { OnboardingStepTitle } from '../../components/OnboardingStepTitle'
import { SelectBoxes } from '../../components/SelectBoxes'
import { useOnboarding } from '../../hooks/useOnboarding'

export enum SolutionSelectionKeys {
  Msp_Only = 'Msp_Only',
  Msp_And_Migrate_Hcp = 'Msp_And_Migrate_Hcp',
  Msp_And_Hcp = 'Msp_And_Hcp',
}

export const SolutionSelectionStep = () => {
  // -- Data --
  const {
    setLoading,
    handleNext,
    values,
    items: { solutionSelectionItems },
  } = useOnboarding()
  const { user, refetch: refetchUser } = useUser()
  const { currentMember } = useMember()

  const [, execute] = useAuthMemberAxios(
    {
      url: Urls.SelectSolution,
      method: 'POST',
    },
    {
      manual: true,
    }
  )

  // -- State --
  const [selectedItem, setSelectedItem] = useState(
    currentMember.mspContractId &&
      currentMember.hcpContractId &&
      user.hcpStatus.hasExistingDevice
      ? SolutionSelectionKeys.Msp_And_Migrate_Hcp
      : currentMember.mspContractId &&
        currentMember.hcpContractId &&
        !user.hcpStatus.hasExistingDevice
      ? SolutionSelectionKeys.Msp_And_Hcp
      : currentMember.mspContractId &&
        !currentMember.hcpContractId &&
        !user.hcpStatus.hasExistingDevice
      ? SolutionSelectionKeys.Msp_Only
      : ''
  )

  // -- Hooks --
  const { t } = useTranslation()

  // -- Vars --

  // -- Handlers --
  const handleSelect = async (key: SolutionSelectionKeys) => {
    try {
      setSelectedItem(key)
      setLoading(true)

      if (key !== values.chargerLocationKey) {
        await execute({
          data: {
            solution: key,
          },
        })

        await refetchUser()
      }

      handleNext({ ...values })
    } catch {
      setLoading(false)
      return console.error('Failed to select Blossom solution')
    }
  }

  // -- Render --
  return (
    <StContainer>
      <OnboardingStepTitle>
        {t('onboarding.solution-selection.title')}
      </OnboardingStepTitle>

      <SelectBoxes
        items={solutionSelectionItems}
        onSelect={handleSelect}
        selectedItem={selectedItem}
      />
    </StContainer>
  )
}

const StContainer = styled.div`
  height: 100%;
`
