import { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { css, styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../../../theme/layout/breakpoints'
import { ButtonTertiary } from '../../../../components/button/ButtonTertiary'
import { Chip } from '../../../../components/general/Chip'
import { Dialog } from '../../../../components/general/Dialog'
import { ZohoImage, ZohoModule } from '../../../../components/image/ZohoImage'
import {
  BodyLargeSemiBold,
  BodyLargeSemiBoldCss,
  BodySmallRegular,
  BodySmallRegularCss,
} from '../../../../components/typography'
import { applyVat, formatDecimals } from '../../../../core/utils/number'
import { useMember } from '../../../../member/hooks/useMember'
import { useUser } from '../../../../user/hooks/useUser'
import { useOnboarding } from '../../hooks/useOnboarding'
import { useProductAttributes } from '../../hooks/useProductAttributes'
import { getProductName } from '../../util/product'

import { ProductDetail } from './ProductDetail'

import type { Product } from 'types'

type ProductListItemProps = {
  product: Product
  onSelect: (
    productId: string,
    colorId: string,
    connectionMethodId: string,
    mountingOptionId: string,
    mountingOption: string
  ) => void
}

export const ProductListItem = ({
  product,
  onSelect,
}: ProductListItemProps) => {
  // -- State --
  const [open, setOpen] = useState(false)

  // -- Hooks --
  const { t } = useTranslation()
  const { values } = useOnboarding()
  const { ProductAttributes } = useProductAttributes()
  const { user } = useUser()
  const isDesktop = useMediaQuery(breakpoints.desktop)
  const { currentMember } = useMember()

  // -- Vars --
  const isSelected =
    values.product === product.id &&
    values.color &&
    values.connectionMethod &&
    values.mountingOption

  const price = useMemo(() => {
    if (isSelected) {
      const selectedColor = product.colors.find(
        (color) => color.id === values.color
      )

      const selectedConnectionMethod = product.connectionMethods.find(
        (connectionMethod) => connectionMethod.id === values.connectionMethod
      )

      const selectedMountingOption = product.mountingOptions.find(
        (value) => value.id === values.mountingOption
      )

      return (
        applyVat(
          product.price,
          currentMember.company.applyVat ? product.vat : 0
        ) +
        applyVat(
          selectedColor?.price || 0,
          currentMember.company.applyVat ? selectedColor?.vat : 0
        ) +
        applyVat(
          selectedConnectionMethod?.price || 0,
          currentMember.company.applyVat ? selectedConnectionMethod?.vat : 0
        ) +
        applyVat(
          selectedMountingOption?.price || 0,
          currentMember.company.applyVat ? selectedMountingOption?.vat : 0
        )
      )
    }

    // Else return minimum price
    return (
      applyVat(
        product.price,
        currentMember.company.applyVat ? product.vat : 0
      ) +
      applyVat(
        product.colors[0].price,
        currentMember.company.applyVat ? product.colors[0].vat : 0
      ) +
      applyVat(
        product.connectionMethods[0].price,
        currentMember.company.applyVat ? product.connectionMethods[0].vat : 0
      ) +
      applyVat(
        product.mountingOptions[0].price,
        currentMember.company.applyVat ? product.mountingOptions[0].vat : 0
      )
    )
  }, [isSelected])

  // -- Render --
  return (
    <StBox key={product.id}>
      {isSelected ? (
        <StChip>
          <Chip>{t('onboarding.product-selection-overview.selected')}</Chip>
        </StChip>
      ) : null}
      <StImageContainer onClick={() => setOpen(true)}>
        <ZohoImage
          module={ZohoModule.Products}
          id={product.id}
          altText={product.productName}
        />
      </StImageContainer>
      <StProductHeader>
        <StProductTitle onClick={() => setOpen(true)}>
          {getProductName(product, user.language)}
        </StProductTitle>
        <ProductAttributes product={product} />
      </StProductHeader>
      <StPriceContainer>
        <Trans
          t={t}
          i18nKey={
            isSelected
              ? 'onboarding.product-selection-overview.price'
              : 'onboarding.product-selection-overview.min-price'
          }
          components={{ bold: <StPrice /> }}
          values={{
            price: formatDecimals(price),
          }}
        />
        <StVat>
          {currentMember.company.applyVat
            ? t('onboarding.product-selection-overview.price.include-vat')
            : t('onboarding.product-selection-overview.price.exclude-vat')}
        </StVat>
      </StPriceContainer>

      <ButtonTertiary onClick={() => setOpen(true)}>
        {t('onboarding.product-selection-overview.details')}
      </ButtonTertiary>

      <Dialog
        open={open}
        onOpenChange={setOpen}
        onClickCloseButton={() => setOpen(false)}
        title={isDesktop ? '' : getProductName(product, user.language)}
      >
        <ProductDetail
          product={product}
          onProductSelection={(
            productId: string,
            colorId: string,
            connectionMethodId: string,
            mountingOptionId: string,
            mountingOption: string
          ) => {
            setOpen(false)
            onSelect(
              productId,
              colorId,
              connectionMethodId,
              mountingOptionId,
              mountingOption
            )
          }}
        />
      </Dialog>
    </StBox>
  )
}

const resetButtonStyles = css`
  border: none;
  background-color: transparent;
`

const StBox = styled.div`
  height: 400px;

  display: flex;
  justify-content: space-between;
  flex-direction: column;

  position: relative;
`

const StImageContainer = styled.button`
  height: 134px;
  margin: 0 auto;
  cursor: pointer;
  aspect-ratio: 1 / 1;
  padding: 0;

  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  overflow: hidden;

  ${resetButtonStyles}
`
const StChip = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`

const StProductHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};
`

const StProductTitle = styled.button`
  ${BodyLargeSemiBoldCss}
  ${resetButtonStyles}

  cursor: pointer;
  align-self: flex-start;

  padding: 0;
  color: ${({ theme }) => theme.theme.text.body.black};

  &:hover {
    text-decoration: underline;
  }
`

const StPriceContainer = styled.div`
  ${BodySmallRegularCss}
`

const StPrice = styled(BodyLargeSemiBold)`
  display: inline;
`

const StVat = styled(BodySmallRegular)`
  display: inline;
`
