import type { SupportedLanguage } from '../../translations/types/Languages'
import type { ChargingSessionStatistics } from '../usage/types/types'

export const statisticsChargingTypeData = (
  statistics?: ChargingSessionStatistics | null
) => {
  if (!statistics)
    return {
      homeUsagePercentage: 0,
      publicUsagePercentage: 0,
      workUsagePercentage: 0,
    }

  if (
    !statistics.totalUsagePerType?.Home &&
    !statistics.totalUsagePerType?.Public &&
    !statistics.totalUsagePerType?.Work
  )
    return {
      homeUsagePercentage: 100 / 3,
      publicUsagePercentage: 100 / 3,
      workUsagePercentage: 100 / 3,
    }

  const homeUsage = statistics.totalUsagePerType?.Home
    ? (statistics.totalUsagePerType.Home / statistics.totalUsage) * 100
    : 0
  const publicUsage = statistics.totalUsagePerType?.Public
    ? (statistics.totalUsagePerType.Public / statistics.totalUsage) * 100
    : 0
  const workUsage = statistics.totalUsagePerType?.Work
    ? (statistics.totalUsagePerType.Work / statistics.totalUsage) * 100
    : 0

  return {
    homeUsagePercentage: homeUsage,
    publicUsagePercentage: publicUsage,
    workUsagePercentage: workUsage,
  }
}

export const statisticsChargingSpeedData = (
  statistics?: ChargingSessionStatistics | null
) => {
  if (!statistics) return { acUsagePercentage: 0, dcUsagePercentage: 0 }

  const acUsage = statistics.totalUsagePerPowerType?.AC
    ? (statistics.totalUsagePerPowerType.AC /
        statistics.totalUsagePerType.Public) *
      100
    : 0
  const dcUsage = statistics.totalUsagePerPowerType?.DC
    ? (statistics.totalUsagePerPowerType.DC /
        statistics.totalUsagePerType.Public) *
      100
    : 0

  return { acUsagePercentage: acUsage, dcUsagePercentage: dcUsage }
}

export const statisticsCostPerTypeData = (
  statistics?: ChargingSessionStatistics | null
) => {
  if (!statistics || !statistics.totalCost) {
    return {
      energyCostPercentage: 0,
      startCostPercentage: 0,
      timeCostPercentage: 0,
    }
  }

  const energyCost = statistics.totalCostPerPriceLineType?.Energy
    ? (statistics.totalCostPerPriceLineType.Energy / statistics.totalCost) * 100
    : 0
  const startCost = statistics.totalCostPerPriceLineType?.Start
    ? (statistics.totalCostPerPriceLineType.Start / statistics.totalCost) * 100
    : 0
  const timeCost = statistics.totalCostPerPriceLineType?.Time
    ? (statistics.totalCostPerPriceLineType.Time / statistics.totalCost) * 100
    : 0

  return {
    energyCostPercentage: energyCost,
    startCostPercentage: startCost,
    timeCostPercentage: timeCost,
  }
}

export const formatCurrency = (
  amount: number,
  language: SupportedLanguage = 'nl'
) => {
  const languageCode =
    language === 'nl' ? 'nl-BE' : language === 'en' ? 'en-GB' : 'fr-BE'

  return new Intl.NumberFormat(languageCode, {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    compactDisplay: 'short',
  }).format(amount)
}

export const formatPowerConsumption = (
  powerConsumption: number,
  decimals = 2,
  language: SupportedLanguage = 'nl'
) => {
  const languageCode =
    language === 'nl' ? 'nl-BE' : language === 'en' ? 'en-GB' : 'fr-BE'

  const units = ['kWh', 'MWh', 'GWh', 'TWh']
  let unitIndex = 0

  while (powerConsumption >= 1000 && unitIndex < units.length - 1) {
    powerConsumption /= 1000
    unitIndex++
  }

  const formattedPowerConsumption = new Intl.NumberFormat(languageCode, {
    style: 'decimal',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
    compactDisplay: 'short',
  }).format(powerConsumption)

  return `${formattedPowerConsumption} ${units[unitIndex]}`
}
