import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { EmployeeRoutes } from '../../../routing/routes'
import { breakpoints } from '../../../theme/layout/breakpoints'
import { useAuthentication } from '../../authentication/hooks/useAuthentication'
import { useIntercom } from '../../intercom/hooks/useIntercom'
import { useChangeLanguage } from '../../translations/hooks/useChangeLanguage'
import intercomLogo from '../assets/intercom_full.svg'
import { ButtonQuaternary } from '../button/ButtonQuaternary'
import { DropdownMenu } from '../general/DropdownMenu'
import { Spinner } from '../general/Spinner'
import {
  BodyMediumMedium,
  BodyMediumMediumCss,
  BodyMediumRegularCss,
} from '../typography'

import { NavigationLogo } from './NavigationLogo'
import { WizardItem } from './WizardItem'

import type { DropdownMenuGroup } from '../general/DropdownMenu'

export type WizardStep = {
  key: string
  order: number
}

export type CurrentStep = {
  key: string
  order: number
  subStep?: {
    step: number
    of: number
  }
  loading?: boolean
  completed?: boolean
}

type WizardProps = {
  steps: WizardStep[]
  currentStep: CurrentStep
  onGoBack?: () => void
  loading?: boolean
  showSaved?: boolean
  canCancel?: boolean
  canLogout?: boolean
  showIntercom?: boolean
  showLanguage?: boolean
  logoIsLink?: boolean
}

export const Wizard = ({
  steps,
  currentStep,
  onGoBack,
  loading,
  showSaved,
  canCancel = true,
  canLogout = false,
  showIntercom = true,
  showLanguage,
  logoIsLink = true,
}: WizardProps) => {
  // -- Hooks --
  const isDesktop = useMediaQuery(breakpoints.desktop)
  const { t, i18n } = useTranslation()
  const { show } = useIntercom()
  const navigate = useNavigate()
  const { changeLanguage } = useChangeLanguage()
  const { logout } = useAuthentication()

  // -- Vars --
  const isProduction = import.meta.env.VITE_ENV === 'production'
  const sortedSteps = steps.sort((stepA, stepB) => stepA.order - stepB.order)

  const menuGroups: DropdownMenuGroup[] = [
    {
      key: 'profile',
      items: [
        {
          name: 'Nederlands',
          onClick: () => changeLanguage('nl'),
          active: i18n.language === 'nl',
        },
        {
          name: 'English',
          onClick: () => changeLanguage('en'),
          active: i18n.language === 'en',
        },
        {
          name: 'Français',
          onClick: () => changeLanguage('fr'),
          active: i18n.language === 'fr',
        },
        isProduction
          ? undefined
          : {
              name: 'Debug mode',
              onClick: () => changeLanguage('cimode'),
              active: i18n.language === 'cimode',
            },
      ].filter(Boolean) as DropdownMenuGroup['items'],
    },
  ]

  const languages: Record<string, string> = {
    nl: 'Nederlands',
    en: 'English',
    fr: 'Français',
    cimode: 'Debug mode',
  }

  // -- render --
  if (isDesktop) {
    return (
      <StWizardContainer>
        <StNavigationContent>
          {onGoBack ? (
            <StNavButton onClick={onGoBack}>
              <FontAwesomeIcon icon={['fasr', 'arrow-left']} />
              {t('navigation.back')}
            </StNavButton>
          ) : (
            <NavigationLogo isLink={logoIsLink} />
          )}

          <StNavWizardContainer>
            {sortedSteps.map((step) => (
              <WizardItem
                key={step.key}
                step={step}
                currentStep={currentStep}
              />
            ))}
          </StNavWizardContainer>

          <StNavigationActionRow>
            {showSaved ? (
              <StSavedContainer>
                <FontAwesomeIcon icon={['fass', 'check']} />
                <BodyMediumMedium>{t('navigation.saved')}</BodyMediumMedium>
              </StSavedContainer>
            ) : loading ? (
              <StLoadingContainer>
                <Spinner />
                <StLoadingText>{t('navigation.loading')}</StLoadingText>
              </StLoadingContainer>
            ) : null}
            {canCancel && (
              <StCloseButton
                border={false}
                onClick={() => navigate(EmployeeRoutes.Root)}
              >
                <FontAwesomeIcon icon={['fasr', 'xmark-large']} />
              </StCloseButton>
            )}
            {canLogout && (
              <StCloseButton border={false} onClick={() => logout()}>
                <FontAwesomeIcon icon={['fasr', 'arrow-right-from-bracket']} />
              </StCloseButton>
            )}

            {showLanguage && (
              <DropdownMenu groups={menuGroups}>
                <StTrigger>
                  <StFlex>
                    <FontAwesomeIcon icon={['fasr', 'globe']} size="lg" />

                    <BodyMediumMedium>
                      {languages[i18n.language]}
                    </BodyMediumMedium>
                    <FontAwesomeIcon
                      icon={['fasr', 'chevron-down']}
                      size="lg"
                    />
                  </StFlex>
                </StTrigger>
              </DropdownMenu>
            )}
          </StNavigationActionRow>
        </StNavigationContent>
      </StWizardContainer>
    )
  }

  return (
    <StWizardContainer>
      <StNavigationContent>
        <StNavigationRow>
          {onGoBack && (
            <StNavButton onClick={onGoBack}>
              <FontAwesomeIcon icon={['fasr', 'arrow-left']} />
            </StNavButton>
          )}

          {loading ? (
            <StLoadingContainer>
              <Spinner />
              <StLoadingText>{t('navigation.loading')}</StLoadingText>
            </StLoadingContainer>
          ) : (
            <WizardItem
              step={
                steps.find((step) => step.key === currentStep.key) || steps[0]
              }
              currentStep={currentStep}
            />
          )}
        </StNavigationRow>
        <StNavigationActionRow>
          {showIntercom && (
            <StIntercomTrigger border={false} onClick={show}>
              <img src={intercomLogo} alt="Intercom" />
            </StIntercomTrigger>
          )}

          {showLanguage && (
            <DropdownMenu groups={menuGroups}>
              <StTrigger>
                <StFlex>
                  <FontAwesomeIcon icon={['fasr', 'globe']} size="lg" />

                  <BodyMediumMedium>
                    {i18n.language.toLocaleUpperCase()}
                  </BodyMediumMedium>
                  <FontAwesomeIcon icon={['fasr', 'chevron-down']} size="lg" />
                </StFlex>
              </StTrigger>
            </DropdownMenu>
          )}

          {canCancel ? (
            <StCloseButton
              border={false}
              onClick={() => navigate(EmployeeRoutes.Root)}
            >
              <FontAwesomeIcon icon={['fasr', 'xmark-large']} />
            </StCloseButton>
          ) : null}
          {canLogout ? (
            <StCloseButton border={false} onClick={() => logout()}>
              <FontAwesomeIcon icon={['fasr', 'arrow-right-from-bracket']} />
            </StCloseButton>
          ) : null}
        </StNavigationActionRow>
      </StNavigationContent>
    </StWizardContainer>
  )
}

export const StWizardContainer = styled.div`
  position: sticky;
  top: 0;

  height: calc(var(--navigation-height) + var(--inset-top, 0px));
  background-color: ${({ theme }) => theme.components.menu.default.bg};
  width: 100%;

  border: 1px solid ${({ theme }) => theme.components.menu.default.border};

  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space6};

  z-index: 500;
`

const StNavigationContent = styled.div`
  height: 100%;
  width: 100%;
  max-width: ${({ theme }) => theme.UI.MaxWidthPx.navigation};

  margin: 0 auto;

  display: flex;
  justify-content: space-between;
  align-items: center;

  align-self: center;

  padding-top: var(--inset-top, 0px);

  @media ${breakpoints.desktop} {
    display: grid;
    grid-template-columns: 150px auto 150px;
    column-gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
    align-items: center;
  }
`

const StNavWizardContainer = styled.div`
  background-color: ${({ theme }) => theme.components.navwizard.bg};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space3};
  padding-right: ${({ theme }) => theme.UI.SpacingPx.Space6};
  border-radius: 100px;
  margin: 0 auto;

  display: flex;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

const StNavigationRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};
`

const StNavigationActionRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
  margin-right: -${({ theme }) => theme.UI.SpacingPx.Space3};

  @media ${breakpoints.desktop} {
    margin-right: 0;
  }
`

const StNavButton = styled.button`
  ${BodyMediumMediumCss}
  color: ${({ theme }) => theme.components.menu.wizard['item-text']};

  height: 100%;

  background-color: transparent;
  border: none;

  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};

  padding: ${({ theme }) => theme.UI.SpacingPx.Space4} 0;
  cursor: pointer;
`

const StLoadingContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StLoadingText = styled(BodyMediumMedium)`
  @media ${breakpoints.desktop} {
    ${BodyMediumRegularCss}
    color: ${({ theme }) => theme.components.menu.wizard['item-text']};
  }
`

const StSavedContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};

  color: ${({ theme }) => theme.components.menu.wizard['item-text']};
`

const StIntercomTrigger = styled(ButtonQuaternary)`
  display: grid;
  place-items: center;

  border: none;
  background-color: ${({ theme }) => theme.theme.colors['primary-1']};
  color: ${({ theme }) => theme.theme.colors.black};
  border-radius: 50%;

  width: ${({ theme }) => theme.UI.SpacingPx.Space10};
  height: ${({ theme }) => theme.UI.SpacingPx.Space10};
  aspect-ratio: 1 / 1;
  padding: 0;
`

const StCloseButton = styled(ButtonQuaternary)`
  display: grid;
  place-items: center;

  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.components.menu.wizard['item-icon']};
  border-radius: 50%;

  width: ${({ theme }) => theme.UI.SpacingPx.Space10};
  height: ${({ theme }) => theme.UI.SpacingPx.Space10};
  aspect-ratio: 1 / 1;
  padding: 0;
`

const StTrigger = styled.button`
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;

  justify-self: flex-end;

  display: grid;
  place-items: center;

  color: ${({ theme }) => theme.components.menu.wizard['item-icon']};
`

const StFlex = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`
