import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'

import { EmployerRoutes } from '../../../../routing/routes'
import { ButtonTertiary } from '../../../components/button/ButtonTertiary'
import { H4 } from '../../../components/typography'
import { DataTable } from '../../../dataTable/components/DataTable'
import { useDataTable } from '../../../dataTable/hooks/useDataTable'
import { useAnomaliesColumns } from '../../usage/components/anomalies/AnomaliesTableConfig'

import type { AnomalyWithRelations } from '../../usage/types/types'

export const RecentAnomaliesDataTable = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { tableData } = useDataTable<AnomalyWithRelations>()
  const columns = useAnomaliesColumns(true)

  const handleViewAllClick = () => {
    navigate(EmployerRoutes.Usage + '?tab=anomalies')
  }

  // Don't render anything if there's no data
  if (!tableData || !tableData.data || tableData.data.length === 0) {
    return null
  }

  return (
    <StContainer>
      <StHeader>
        <H4>{t('employer.home.recent_anomalies.title')}</H4>
        <ButtonTertiary
          onClick={handleViewAllClick}
          compact
          icon={['fasr', 'arrow-right']}
          size="sm"
        >
          {t('employer.home.all-anomalies')}
        </ButtonTertiary>
      </StHeader>
      <DataTable<AnomalyWithRelations>
        columns={columns}
        emptyTitle={t('employer.anomalies.table.empty-title')}
        emptyDescription={t('employer.anomalies.table.empty-description')}
        emptyFiltersTitle={t('employer.anomalies.table.empty-title')}
        emptyFiltersDescription={t(
          'employer.anomalies.table.empty-description'
        )}
        onRowClick={(anomaly: AnomalyWithRelations) => {
          const blossomId = anomaly.member?.user?.blossomId
          navigate(`${EmployerRoutes.Employees}/${blossomId}?tab=usage`)
        }}
      />
    </StContainer>
  )
}

const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};
`

const StHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
