import { subDays } from 'date-fns'
import { Navigate } from 'react-router-dom'
import { styled } from 'styled-components'

import { EmployerRoutes } from '../../../../routing/routes'
import { Urls } from '../../../api/urls'
import { ContentContainer } from '../../../components/page-layout'
import { SortKeys } from '../../../dataTable/components/DataTableHeader'
import {
  DataTableProvider,
  PaginationType,
} from '../../../dataTable/providers/DataTableProvider'
import { usePermissions } from '../../hooks/usePermissions'
import { ActionRequiredDataTable } from '../components/ActionRequiredDataTable'
import { EmployerHomeStatistics } from '../components/EmployerHomeStatistics'
import { RecentAnomaliesDataTable } from '../components/RecentAnomaliesDataTable'

import type React from 'react'

type DataTableConfig = {
  url: string
  defaultSort: string
  defaultSortDirection: SortKeys
  amount: number
  initialFilterValues?: Record<string, unknown>
}

const tableConfigs: Record<string, DataTableConfig> = {
  actionRequired: {
    url: Urls.fetchEmployees,
    defaultSort: 'hcpStatus.status',
    defaultSortDirection: SortKeys.ASCENDING,
    amount: 5,
    initialFilterValues: { warning: true },
  },
  recentAnomalies: {
    url: Urls.employerChargingAnomalies,
    defaultSort: 'timestamp',
    defaultSortDirection: SortKeys.DESCENDING,
    amount: 5,
    initialFilterValues: {
      dateFrom: subDays(new Date(), 30),
      dateTo: new Date(),
    },
  },
}

const DataTableWrapper = ({
  config,
  children,
}: {
  config: DataTableConfig
  children: React.ReactNode
}) => (
  <DataTableProvider paginationType={PaginationType.None} {...config}>
    {children}
  </DataTableProvider>
)

export const EmployerHomeScreen = () => {
  const { hasPermission } = usePermissions()
  const hasAnyRequiredPermission =
    hasPermission('MANAGE_EMPLOYEES') || hasPermission('VIEW_USAGE_DATA')

  if (!hasAnyRequiredPermission) {
    return <Navigate to={EmployerRoutes.Documents} replace />
  }

  return (
    <ContentContainer>
      <StPageContainerContainer>
        {hasPermission('VIEW_USAGE_DATA') && <EmployerHomeStatistics />}

        {hasPermission('VIEW_USAGE_DATA') && (
          <DataTableWrapper config={tableConfigs.recentAnomalies}>
            <RecentAnomaliesDataTable />
          </DataTableWrapper>
        )}

        {hasPermission('MANAGE_EMPLOYEES') && (
          <DataTableWrapper config={tableConfigs.actionRequired}>
            <ActionRequiredDataTable />
          </DataTableWrapper>
        )}
      </StPageContainerContainer>
    </ContentContainer>
  )
}

const StPageContainerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space10};

  padding-bottom: 70px;
`
