import {
  endOfDay,
  endOfMonth,
  format,
  isEqual,
  isSameMonth,
  startOfDay,
  startOfMonth,
} from 'date-fns'

import type { Locale } from 'date-fns'
import type { DateRange } from 'types'

export function formatDateRange(
  dateRange: DateRange,
  dateFnsLocale: { locale: Locale },
  formatString?: string
) {
  if (!dateRange.start || !dateRange.end) {
    return undefined
  }

  if (
    isEqual(startOfDay(dateRange.start), startOfMonth(dateRange.start)) &&
    (isEqual(endOfDay(dateRange.end), endOfMonth(dateRange.end)) ||
      isEqual(endOfDay(dateRange.end), endOfDay(new Date()))) &&
    isSameMonth(dateRange.start, dateRange.end)
  ) {
    return format(dateRange.start, 'MMMM yyyy', dateFnsLocale)
  }

  return `${format(
    dateRange.start,
    formatString ?? 'MMM dd',
    dateFnsLocale
  )} - ${format(dateRange.end, formatString ?? 'MMM dd', dateFnsLocale)}`
}
