import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { styled } from 'styled-components'

import { ContentContainer } from '../../../components/page-layout'
import { Tabs } from '../../../components/Tabs/Tabs'
import { H4 } from '../../../components/typography'

import { AnomaliesTabContent } from './AnomaliesTabContent'
import { UsageTabContent } from './UsageTabContent'

export const UsageDataTable = () => {
  const { t } = useTranslation()
  const [searchParameters, setSearchParameters] = useSearchParams()
  const [activeTab, setActiveTab] = useState(
    searchParameters.get('tab') ?? 'usage'
  )

  const handleTabChange = (tab: string) => {
    setSearchParameters((previous) => {
      previous.set('tab', tab)
      return previous
    })
    setActiveTab(tab)
  }

  const tabItems = [
    {
      title: t('employer.usage.tabs.usage'),
      value: 'usage',
      content: (
        <StTabContent>
          <UsageTabContent />
        </StTabContent>
      ),
    },
    {
      title: t('employer.usage.tabs.anomalies'),
      value: 'anomalies',
      content: (
        <StTabContent>
          <AnomaliesTabContent />
        </StTabContent>
      ),
    },
  ]

  return (
    <ContentContainer>
      <StContainer>
        <StTitle>{t('employer.chargingSessions.title')}</StTitle>
        <StTabsWrapper>
          <Tabs
            defaultValue="usage"
            items={tabItems}
            value={activeTab}
            setValue={handleTabChange}
          />
        </StTabsWrapper>
      </StContainer>
    </ContentContainer>
  )
}

const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};
  padding-bottom: 70px;
`

const StTabsWrapper = styled.div`
  width: 100%;

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.UI.SpacingPx.Space6};
  }
`

const StTabContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};
  width: 100%;
`

const StTitle = styled(H4)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

export const StHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

  & > * {
    flex: 1 0 0;
  }
`

export const StStatistics = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

  > * {
    flex: 1 0 0;
  }
`
