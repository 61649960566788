import { Capacitor } from '@capacitor/core'
import { SplashScreen } from '@capacitor/splash-screen'
import { StatusBar } from '@capacitor/status-bar'
import { createContext, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'

import { translationsLoadedAtom } from '../../core/recoil/atoms'

import type { PropsWithChildren } from 'react'

type SplashScreenContext = {
  show: boolean
  appIsReady: boolean
  animationFinished: boolean
  shouldHideSplashScreen: boolean
  setShow: (show: boolean) => void
  setAppIsReady: (appIsReady: boolean) => void
  setAnimationFinished: (animationFinished: boolean) => void
  hideSplashScreen: () => void
  translationsAreLoaded: boolean
}

export const splashScreenContext = createContext<SplashScreenContext | null>(
  null
)

export const SplashScreenProvider = ({ children }: PropsWithChildren) => {
  // -- State --
  const [show, setShow] = useState(false)
  const [appIsReady, setAppIsReady] = useState(false)
  const [animationFinished, setAnimationFinished] = useState(false)
  const translationsAreLoaded = useRecoilValue(translationsLoadedAtom)

  // -- Data --
  const shouldHideSplashScreen =
    (!show && animationFinished) || !Capacitor.isNativePlatform()

  // -- Methods --
  const hideSplashScreen = () => {
    setShow(false)
    setAppIsReady(true)
  }

  // -- Effects --
  // Always instantly hide the native splash screen
  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      setShow(true)
      setTimeout(() => {
        SplashScreen.hide({ fadeOutDuration: 0 }).then(() => {
          Capacitor.getPlatform() === 'android' &&
            StatusBar.setOverlaysWebView({ overlay: true })
        })
      }, 200)
    }
  }, [])

  return (
    <splashScreenContext.Provider
      value={{
        show,
        appIsReady,
        animationFinished,
        shouldHideSplashScreen,
        setShow,
        setAppIsReady,
        setAnimationFinished,
        hideSplashScreen,
        translationsAreLoaded,
      }}
    >
      {children}
    </splashScreenContext.Provider>
  )
}
