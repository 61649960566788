// This creates a deck.gl overlay for the mapbox map

import { MapboxOverlay } from '@deck.gl/mapbox'
import { useControl } from 'react-map-gl'

import type { DeckProps } from 'deck.gl'

// We use this approach over the deckgl React wrapper because we need access to the mapbox ref
export function DeckGLOverlay(props: DeckProps) {
  const overlay = useControl<MapboxOverlay>(() => new MapboxOverlay(props))
  overlay.setProps(props)

  return null
}
