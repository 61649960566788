import { RemoveScroll } from 'react-remove-scroll'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../../theme/layout/breakpoints'
import { SkeletonContentLoader } from '../../../components/SkeletonContentLoader'

export function HemsScreenSkeleton() {
  const isDesktop = useMediaQuery(breakpoints.desktop)

  return (
    <RemoveScroll>
      {isDesktop ? <HemsScreenDesktopSkeleton /> : <HemsScreenMobileSkeleton />}
    </RemoveScroll>
  )
}

function HemsScreenMobileSkeleton() {
  return (
    <SkeletonContentLoader viewBox="0 0 342 720">
      <rect width="100%" height="142" rx="8" />
      <rect y="166" width="45.32%" height="24" rx="8" />
      <rect y="200" width="100%" height="309" rx="8" />
      <rect y="533" width="45.32%" height="24" rx="8" />
      <rect x="82.46%" y="533" width="17.54%" height="24" rx="8" />
      <rect y="567" width="100%" height="318" rx="8" />
    </SkeletonContentLoader>
  )
}

function HemsScreenDesktopSkeleton() {
  return (
    <StGrid>
      <StColumn>
        <SkeletonContentLoader viewBox="0 0 600 176">
          <rect x="0" width="25.83%" height="24" rx="8" />
          <rect x="0" y="34" width="100%" height="142" rx="8" />
        </SkeletonContentLoader>
        <SkeletonContentLoader viewBox="0 0 600 394">
          <rect x="0" width="25.83%" height="24" rx="8" />
          <rect x="0" y="34" width="100%" height="360" rx="8" />
        </SkeletonContentLoader>
      </StColumn>
      <StColumn>
        <SkeletonContentLoader viewBox="0 0 600 394">
          <rect x="0" width="25.83%" height="24" rx="8" />
          <rect x="0" y="34" width="100%" height="360" rx="8" />
        </SkeletonContentLoader>
        <SkeletonContentLoader viewBox="0 0 600 176">
          <rect x="0" width="25.83%" height="24" rx="8" />
          <rect x="0" y="34" width="100%" height="142" rx="8" />
        </SkeletonContentLoader>
      </StColumn>
    </StGrid>
  )
}

const StGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: ${({ theme }) => theme.UI.SpacingPx.Space6};
  column-gap: ${({ theme }) => theme.UI.SpacingPx.Space10};

  overflow-y: hidden;

  padding: ${({ theme }) => theme.UI.SpacingPx.Space10} 0;
`

const StColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space10};
`
