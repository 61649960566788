import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { H4 } from '../../../../components/typography'
import { DataTable } from '../../../../dataTable/components/DataTable'
import { useDataTable } from '../../../../dataTable/hooks/useDataTable'
import { Filters } from '../../../../employee/onboarding/components/Filters'
import {
  useAnomaliesColumns,
  useAnomaliesFilters,
} from '../../../usage/components/anomalies/AnomaliesTableConfig'

import type { AnomalyWithRelations } from '../../../usage/types/types'

type EmployeeDetailAnomaliesDataTableProps = {
  onRowClick?: (anomaly: AnomalyWithRelations) => void
}

export const EmployeeDetailAnomaliesDataTable = ({
  onRowClick,
}: EmployeeDetailAnomaliesDataTableProps) => {
  const { t } = useTranslation()
  const { filterValues, setFilterValues, clearFilters } =
    useDataTable<AnomalyWithRelations>()

  // We don't need the employee column since we're in employee context
  const columns = useAnomaliesColumns(false)
  const filters = useAnomaliesFilters(false)

  return (
    <StContainer>
      <StHeader>
        <H4>{t('admin.employee-detail.anomalies.title')}</H4>
        <Filters
          key="employee-anomalies-filters"
          filters={filters}
          filterValues={filterValues}
          onFiltersChange={(filterKey, value) =>
            setFilterValues((oldValues) => ({
              ...oldValues,
              [filterKey]: value,
            }))
          }
          onClearFilters={() => {
            clearFilters()
          }}
          loading={false}
          align="right"
        />
      </StHeader>
      <DataTable<AnomalyWithRelations>
        columns={columns}
        emptyTitle={t('admin.employee-detail.anomalies.empty-title')}
        emptyDescription={t(
          'admin.employee-detail.anomalies.empty-description'
        )}
        emptyFiltersTitle={t(
          'admin.employee-detail.anomalies.empty-filters-title'
        )}
        emptyFiltersDescription={t(
          'admin.employee-detail.anomalies.empty-filters-description'
        )}
        onRowClick={onRowClick}
      />
    </StContainer>
  )
}

const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space10};

  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space10};
  padding-bottom: 20px;
`

const StHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
